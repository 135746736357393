import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const EsDialogae = () => {
    const { search } = useLocation();
    const { i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <div className='content-wrapper'>
            <div className='tab-logo'>
                <img src='/images/logo-accom.svg' alt='' />
            </div>
            <h2 className='blue-text'>
                ¿Qué es Dialogæ, la plataforma para hacer voluntariado?
            </h2>
            <br />
            <p>
                <span className='blue-text'>
                    Dialogæ es mucho más que una aplicación gratuita;
                </span>
                crea una red de voluntarios que crea lazos entre la gente joven
                y los más mayores.{' '}
                <span className='blue-text'>
                    Conecta a los dos grupos que más sufren soledad no deseada
                </span>
                para regalarles la oportunidad de encontrarse. La aplicación te
                permite conectar con personas que comparten tus ideas,{' '}
                <span className='blue-text'>
                    agendar una llamada de 30 minutos y crear una conexión
                    utilizando sólo tu móvil.
                </span>
                .
            </p>
            <p>
                El nombre Dialogæ no es una coincidencia.{' '}
                <span className='blue-text'>
                    Un diálogo es una forma única de comunicación centrada en
                    escuchar y compartir.
                </span>
                El sonido ‘æ’ al final proviene del latín y enfatiza la conexión
                entre sonidos. Así como en la antigüedad el latín conectaba los
                países de Europa, mi objetivo es conectar a voluntarios y
                personas afines en España y en toda la Unión Europea en el
                futuro.
            </p>
            <p>
                Con cada detalle, busco construir una conexión. Esta filosofía
                se refleja en el nombre y el logo, donde dos generaciones se dan
                la mano.
            </p>
        </div>
    );
};

export default EsDialogae;
