import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Step1 from '../../../assets/images/step-1-img.svg';
import Step2 from '../../../assets/images/step-2-img.svg';
import Step4 from '../../../assets/images/step-4-img.svg';
import Step5 from '../../../assets/images/step-5-img.svg';

import { register } from '../../../api/adapters/auth';
import { CommonContext } from '../../../context/CommonState';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import {
    getCookie,
    removeCookie,
    setCookie,
} from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import StepFive from '../../components/account-setup-component/StepFive';
import StepFour from '../../components/account-setup-component/StepFour';
import StepOne from '../../components/account-setup-component/StepOne';
import StepTwo from '../../components/account-setup-component/StepTwo';
import StepsIndicator from '../../components/account-setup-component/StepsIndicator';
import LoadingButton from '../../components/mini-components/LoadingButton';

function CreateAccount() {
    const { space, setSpace, userData, setUserData } =
        useContext(CommonContext);
    const [step, setStep] = useState(1);
    const scrollRef = useRef(null);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    if (
        !location.state?.firstName ||
        !location.state?.middleName ||
        !location.state?.email ||
        !location.state?.password ||
        !location.state?.confirmPassword
    ) {
        navigate('/sign-up', { replace: true });
    }

    const [formErrors, setFormErrors] = useState({
        1: { accountType: '', country: '', city: '' },
        2: { gender: '', dob: '', residency: '', university: '' },
        3: { email: '' },
        4: { interestItems: '' },
    });

    const [previousFormData, setPreviousFormData] = useState({});

    useEffect(() => {
        setUserData((existingUserData) => ({
            ...existingUserData,
            firstName: location.state?.firstName,
            middleName: location.state?.middleName,
            email: location.state?.email,
            password: location.state?.password,
            confirmPassword: location.state?.confirmPassword,
        }));
    }, []);

    // const [userData, setUserData] = useState({
    //     accountType: '',
    //     country: '',
    //     city: '',
    //     firstName: location.state?.firstName,
    //     middleName: location.state?.middleName,
    //     surname: location.state?.lastName,
    //     gender: '',
    //     dob: '',
    //     residency: '',
    //     university: '',
    //     email: location.state?.email,
    //     bio: '',
    //     interestItems: [],
    //     contactNumber: '',
    //     password: location.state?.password,
    //     confirmPassword: location.state?.confirmPassword,
    //     profile: '',
    // });

    const { mutate: mutateRegister, isLoading: isMutating } = useMutation({
        mutationFn: (data) => register(i18n.language?.split('-')[0], data),
        onSuccess: (data) => {
            notify('success', data.message);

            navigate('/request-sent', { replace: true });
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const fieldValidators = {
        // Step 1 Validators
        accountType: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[1];

            if (!tempUserData?.accountType)
                errors.accountType = t(
                    'account_setup.error_select_account_type'
                );
            else if (
                tempUserData?.accountType !== 'R' &&
                tempUserData?.accountType !== 'S'
            )
                errors.accountType = t(
                    'account_setup.error_invalid_account_type'
                );
            else errors.accountType = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 1: errors }));
        },
        country: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[1];

            if (!tempUserData?.country)
                errors.country = t('account_setup.error_select_country');
            else errors.country = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 1: errors }));
        },
        city: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[1];

            if (!tempUserData?.city)
                errors.city = t('account_setup.error_select_city');
            else errors.city = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 1: errors }));
        },

        // Step 2 Validators
        gender: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[2];

            if (!['M', 'F', 'O'].includes(tempUserData?.gender))
                errors.gender = t('account_setup.error_select_gender');
            else errors.gender = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 2: errors }));
        },
        dob: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[2];

            // Calculate date 17 years ago from today to restrict the user to be at least 17 years old
            const maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() - 18);

            if (!tempUserData?.dob)
                errors.dob = t('account_setup.error_select_dob');
            else if (new Date(tempUserData?.dob) > maxDate)
                errors.dob = t('account_setup.error_age_must_be_18');
            else errors.dob = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 2: errors }));
        },
        residency: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[2];

            if (!tempUserData?.residency)
                errors.residency = t('account_setup.error_select_residency');
            else errors.residency = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 2: errors }));
        },
        university: () => {
            let tempUserData = getCookie('__temp_user_data');

            const errors = formErrors[2];

            if (!tempUserData?.university)
                errors.university = t('account_setup.error_select_university');
            else errors.university = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 2: errors }));
        },
        // Step 3 Validators - Not Required

        // Step 4 Validators
        interestItems: () => {
            const errors = formErrors[4];

            if (userData.interestItems.length < 5)
                errors.interestItems = t(
                    'account_setup.error_select_5_interests'
                );
            else if (userData.interestItems.length > 12)
                errors.interestItems = t(
                    'account_setup.error_select_only_12_interests'
                );
            else errors.interestItems = '';

            setFormErrors((oldErrors) => ({ ...oldErrors, 4: errors }));
        },
    };

    const isStepOneValid = () => {
        fieldValidators.accountType();
        fieldValidators.country();
        fieldValidators.city();

        return new Set(Object.values(formErrors[1])).size === 1;
    };

    const isStepTwoValid = () => {
        fieldValidators.gender();
        fieldValidators.dob();

        let tempUserData = getCookie('__temp_user_data');

        if (tempUserData?.accountType === 'R') fieldValidators.residency();
        else fieldValidators.university();

        return new Set(Object.values(formErrors[2])).size === 1;
    };

    const isStepFiveValid = () => {
        fieldValidators.interestItems();

        return (
            userData.interestItems.length >= 5 &&
            userData.interestItems.length <= 12
        );
    };

    const handleNextBtn = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        // Validate Current step's data
        let isValid = false;

        if (step === 1) isValid = isStepOneValid();
        else if (step === 2) isValid = isStepTwoValid();
        else if (step === 3) isValid = true;
        else if (step === 4) isValid = isStepFiveValid();

        if (!isValid) {
            if (step === 4 && formErrors[4].interestItems) {
                notify('error', formErrors[4].interestItems);
            }
            return;
        }

        setSpace(step === 2 ? true : false);
        if (step < 4) return setStep(step + 1);

        setPreviousFormData((prevData) => ({
            ...prevData,
            [step]: userData,
        }));

        const formData = new FormData();

        formData.append('eAccountType', userData.accountType);
        formData.append('sResidency', userData.residency);
        formData.append('sEmail', userData.email);
        formData.append('sFirstName', userData.firstName.trim());
        formData.append('sMiddleName', userData.middleName.trim());
        formData.append('sLastName', userData.surname.trim());
        formData.append('eGender', userData.gender);
        formData.append(
            'dDob',
            moment(new Date(userData.dob)).format('YYYY-MM-DD')
        );
        formData.append('iCity', userData.city._id);
        formData.append('iCountry', userData.country._id);
        formData.append('sBio', userData.bio.trim());
        formData.append('sContactNumber', userData.contactNumber);
        formData.append('iUniversity', userData.university._id);
        formData.append('iResidency', userData.residency._id);
        formData.append('sPassword', userData.password);
        formData.append('sConfirmPassword', userData.confirmPassword);
        formData.append('uploadfile', userData.profile);

        userData.interestItems.forEach((element, index) => {
            formData.append(`aInterests[${index}]`, element);
        });

        mutateRegister(formData);
    };

    const handleBackBtn = (e) => {
        window.scrollTo(0, 0);

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [step]: {
                accountType: '',
                country: '',
                city: '',
                gender: '',
                dob: '',
                residency: '',
                university: '',
                interestItems: '',
            },
        }));

        setUserData((prevData) => ({
            ...prevData,
            ...previousFormData[step - 1],
        }));

        setStep(step - 1);
    };

    const updateUserDate = (key, value) => {
        const tempUserData = getCookie('__temp_user_data');

        setCookie(
            '__temp_user_data',
            JSON.stringify({ ...tempUserData, [key]: value })
        );

        setUserData((existingUserData) => ({
            ...existingUserData,
            [key]: value,
        }));
    };

    useEffect(() => {
        removeCookie('__temp_user_data');
    }, []);

    useEffect(() => {
        document.title = formatPageTitle(t('account_setup.create_account'));
    }, [i18n.language]);

    useEffect(() => {
        setUserData((existingUserData) => ({
            ...existingUserData,
            contactNumber: location?.state?.mobile || '',
        }));
    }, [location.state]);

    return (
        <div ref={scrollRef} className='account-setup'>
            <div className='left-screen'>
                <img
                    className='left-screen-thumbnail'
                    src={
                        (step === 1 && Step1) ||
                        (step === 2 && Step2) ||
                        (step === 3 && Step4) ||
                        (step === 4 && Step5)
                    }
                    alt=''
                />
            </div>
            <div className='right-screen'>
                <StepsIndicator step={step} />

                <div
                    className={`account-setup-wrapper ${
                        space ? 'padding-top-120' : ''
                    } ${step === 4 ? 'max-width-cat' : ''}`}
                >
                    {step === 1 ? (
                        <StepOne
                            updateUserDate={updateUserDate}
                            formErrors={formErrors[1]}
                            fieldValidators={fieldValidators}
                        />
                    ) : null}
                    {step === 2 ? (
                        <StepTwo
                            updateUserDate={updateUserDate}
                            formErrors={formErrors[2]}
                            fieldValidators={fieldValidators}
                            registrationData={userData}
                        />
                    ) : null}
                    {step === 3 ? (
                        <StepFour updateUserDate={updateUserDate} />
                    ) : null}
                    {step === 4 ? (
                        <StepFive
                            updateUserDate={updateUserDate}
                            formErrors={formErrors[4]}
                        />
                    ) : null}

                    <div className='action-btn create-account'>
                        <button
                            onClick={handleNextBtn}
                            className='primary-btn w-100'
                            disabled={isMutating}
                        >
                            {isMutating ? (
                                <LoadingButton color='White' />
                            ) : (
                                t('account_setup.btn_next')
                            )}
                        </button>

                        {step === 2 || step === 3 || step === 4 ? (
                            <button
                                onClick={
                                    // scrollRef.current.scrollTo({
                                    //     top: 0,
                                    //     behavior: 'smooth',
                                    // });
                                    // setStep(step - 1);
                                    handleBackBtn
                                }
                                className='grey-btn primary-btn'
                            >
                                {t('back')}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateAccount;
