import React, { useEffect, useRef } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

function FancyBox(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const delegate = props.delegate || '[data-fancybox]';
        const options = {
            ...(props.options || {}),
            Toolbar: { enabled: false },
        };

        NativeFancybox.bind(container, delegate, options);

        return () => {
            NativeFancybox.unbind(container);
            NativeFancybox.close();
        };
    });

    return (
        <div className='fancy-box-wrapper' ref={containerRef}>
            {props.children}
        </div>
    );
}

export default FancyBox;
