import React, { useEffect, useState } from 'react';
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    formatTalkedHours,
    formatTime,
} from '../../../utils/helpers/format.helpers';
import Loader from '../common-component/Loader';

function HoursProgress(props) {
    const {
        currentTarget,
        isLoadingCurrentTarget,
        setCompletedPercentage,
        completedPercentage,
        totalTime,
    } = props;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [remainingHours, setRemainingHours] = useState('');

    const calculatePercentageAndRemainingTime = (target, completedMinutes) => {
        const totalMinutes = Math.floor(completedMinutes / 60);
        const remainingMinutes = target - totalMinutes;

        // Ensure remaining time is non-negative
        const adjustedRemainingMinutes = Math.max(remainingMinutes, 0);

        const remainingTime = formatTalkedHours(
            i18n.language.split('-')[0],
            adjustedRemainingMinutes * 60
        );
        // Ensure percentage does not exceed 100
        const percentage = Math.min((totalMinutes / target) * 100, 100);

        return { percentage, remainingTime };
    };

    useEffect(() => {
        const redeemableTimeSeconds =
            currentTarget?.nTotalCallSeconds -
            currentTarget?.nLastRedemptionSeconds;
        if (!isLoadingCurrentTarget) {
            const { percentage, remainingTime } =
                calculatePercentageAndRemainingTime(
                    currentTarget?.iCurrentRewardTarget?.nMinutes,
                    redeemableTimeSeconds
                );

            setCompletedPercentage(percentage);
            setRemainingHours(remainingTime);
        }
    }, [currentTarget, isLoadingCurrentTarget]);

    if (isLoadingCurrentTarget) return <Loader innerLoader={true} />;

    return (
        <div className='no-target'>
            <h3>
                {t('rewards.target')}{' '}
                {formatTime(currentTarget?.iCurrentRewardTarget?.nMinutes, t)}
            </h3>
            <div className='hours-progress'>
                <CircularProgressbarWithChildren
                    value={completedPercentage}
                    strokeWidth={6}
                    styles={buildStyles({
                        pathColor: '#0080FF',
                        trailColor: '#CCE6FF',
                    })}
                >
                    <p>{t('rewards.remaining')}</p>
                    <h1>{remainingHours}</h1>
                </CircularProgressbarWithChildren>
            </div>
            <button
                className='primary-btn w-100'
                onClick={(e) => {
                    e.preventDefault();
                    navigate('manage-target');
                }}
            >
                {t('rewards.manage_btn')}
            </button>

            <p>
                {t('rewards.total_hours')} : {totalTime}
            </p>
        </div>
    );
}

export default HoursProgress;
