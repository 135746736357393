import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { claimReward } from '../../../api/adapters/rewards';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { formatTime } from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../ModalPopUp';

function Congratulations(props) {
    const [modalShow, setModalShow] = useState(false);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { hours, id, setCompletedPercentage } = props;

    const [errors, setErrors] = useState({});

    const { mutate: redeemReward, isLoading: isLoadingGiftCode } = useMutation({
        mutationFn: () => claimReward(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/rewards/accomplished', {
                state: {
                    giftCodeData: {
                        dExpiryDate: response?.data?.dExpiryDate,
                        sDescription: response?.data?.sDescription,
                        sRewardPic: response?.data?.sRewardPic,
                        id: response?.data?._id,
                        iRewardMinutes: response?.data?.iReward?.nMinutes,
                    },
                },
            });
            setCompletedPercentage(0);
        },
        onError: (error) => {
            if (error.status === 403) {
                setModalShow(true);
            } else handleApiError(error, t, navigate, setErrors);
        },
    });

    return (
        <>
            <div className='congratulations'>
                <img src='/images/right-big-icon.svg' alt='' />
                <p className='lg'>
                    <span className='f-600'>
                        {t('rewards.congratulations')}
                    </span>{' '}
                    {t('rewards.you_have')}{' '}
                    <span className='f-600'>{formatTime(hours, t)}</span>{' '}
                    {t('rewards.already')}
                </p>
                <div className='action'>
                    <button
                        onClick={() => {
                            redeemReward();
                        }}
                        className='primary-btn '
                    >
                        {t('rewards.claim')}
                    </button>

                    {/* <Link
                                        to={'/rewards/manage-target'}
                                        className='primary-btn outline-btn '
                                    >
                                        {t('rewards.increase')}
                                    </Link> */}
                </div>
            </div>

            <ModalPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>{t('rewards.sorry')}</h3>

                        <p className='sm'>{t('rewards.you_cannot')}</p>
                    </>
                }
            />
        </>
    );
}

export default Congratulations;
