import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function Footer() {
    const { t } = useTranslation();

    return (
        <div className='footer'>
            <p className='sm'>
                @{t('footer_links.copyrights')} {new Date().getFullYear()}
            </p>
            <div className='footer-nav'>
                <ul>
                    <li>
                        <NavLink to='/privacy-policy'>
                            {t('footer_links.privacy_policy')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/legal-notice'>
                            {t('footer_links.terms_conditions')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/cookies'>
                            {t('footer_links.cookies_title')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/faqs'>{t('footer_links.faqs')}</NavLink>
                    </li>
                    <li>
                        <NavLink to='/about-us'>
                            {t('footer_links.about_us')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/contact-us'>
                            {t('footer_links.contact_us')}
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;
