import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const EngVolunteering = () => {
    const { search } = useLocation();
    const { i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <div className='content-wrapper'>
            <h2 className='blue-text'>
                Volunteering in Tenerife opportunities for students and seniors
            </h2>
            <br />
            <p>
                I invite everyone who feels lonely or wants to make a change to
                try Dialogæ.{' '}
                <span className='blue-text'>
                    It's simple and straightforward
                </span>{' '}
                —by talking and connecting with people, you become active in
                volunteering and can use it to enhance your university
                experience.{' '}
                <span className='blue-text'>
                    The European Credit Transfer System acknowledges Dialogæ
                    volunteering activities and lets you exchange them for
                    credits at your University
                </span>
                .
            </p>
            <p>
                Now, my mission is to bring Dialogæ to as many people as
                possible and{' '}
                <span className='blue-text'>
                    connect the most lonely demographics – university students
                    and seniors
                </span>
                . It's an opportunity for volunteers in Tenerife to{' '}
                <span className='blue-text'>
                    be the first ones to experience this revolutionary concept
                </span>
                .
            </p>
            <p>
                Do you want to make an impact? Brighten someone's day or the
                whole year? Give yourself and the other person the best gift –
                connection.
                <span className='blue-text'>
                    Register to be among the pioneers of Dialogæ!
                </span>
            </p>
            <div className='volunteers-logo'>
                <div>
                    <img src='/images/ayuntamiento.png' alt='' />
                </div>
                <div>
                    <img src='/images/logo-accom.svg' alt='' />
                </div>
            </div>
        </div>
    );
};

export default EngVolunteering;
