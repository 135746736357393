import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonContext } from '../../../context/CommonState';
import FormErrorLabel from '../mini-components/FormErrorLabel';
import TextArea from '../mini-components/TextArea';
import AccountTitle from './AccountTitle';

function StepFour(props) {
    const { updateUserDate } = props;

    const { t } = useTranslation();

    const [characterCount, setCharacterCount] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);

    const [bio, setBio] = useState('');
    const [imageError, setImageError] = useState('');
    const { userData } = useContext(CommonContext);

    const getImage = (e) => {
        const file = e.target.files[0];

        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            return setImageError('Only JPEG & PNG images are supported');
        } else {
            setImageError('');
        }

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setSelectedImage(e.target.result);

                updateUserDate('profile', file);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleInputChange = (e) => {
        if (e.target.value.length > 100) return;

        setCharacterCount(e.target.value.length);
        setBio(e.target.value);

        updateUserDate('bio', e.target.value);
    };

    useEffect(() => {
        if (userData?.profile) {
            const fileData = userData.profile;
            const blob = new Blob([fileData], { type: fileData.type });
            const dataUrl = URL.createObjectURL(blob);
            setSelectedImage(dataUrl);
        }
        if (userData?.bio) {
            setCharacterCount(userData.bio.length);
        }
    }, [userData]);

    return (
        <>
            <AccountTitle
                title={t('auth.profile_setup')}
                desc={t('auth.enter_details')}
            />
            <div className='uploader'>
                <div className='upload-input'>
                    <img
                        src={`${
                            selectedImage ||
                            '/images/icons/profile-uploader-ic.svg'
                        }`}
                        alt=''
                    />
                    <input type='file' onChange={getImage} accept='image/*' />
                </div>
                <p>{t('auth.upload_profile_text')}</p>
                {imageError ? <FormErrorLabel message={imageError} /> : null}
            </div>
            <TextArea
                placeholder={t('field_name.bio_placeholder')}
                label={t('field_name.bio')}
                charactersCount={characterCount}
                handleOnChange={handleInputChange}
                value={userData?.bio ? userData?.bio : bio}
            />
        </>
    );
}

export default StepFour;
