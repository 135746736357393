import {
    CallingState,
    SfuModels,
    SpeakerLayout,
    StreamCall,
    StreamTheme,
    StreamVideo,
    StreamVideoClient,
    useCall,
    useCallStateHooks,
} from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    checkNextAvailability,
    conversationBooster,
    endCall,
    extendCurrentCall,
} from '../../../api/adapters/call';
import UnmuteMic from '../../../assets/images/Unmute-Mic.svg';
import UnmuteVideo from '../../../assets/images/Unmute-video.svg';
import CallEnd from '../../../assets/images/call-end.svg';
import MuteMic from '../../../assets/images/mute-mic.svg';
import MuteVideo from '../../../assets/images/mute-video.svg';
import { updateUser } from '../../../redux/actions/user';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import { getCallToken } from '../../../utils/helpers/cookies.helpers';
import {
    getFullName,
    getUserFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Loader from '../../components/common-component/Loader';
import CallToaster from '../../components/mini-components/CallToaster';
import LoadingButton from '../../components/mini-components/LoadingButton';
import RulesContent from '../../components/speak-now/RulesContent';

const ALERT_BEFORE = 2 * 60 * 1000; //2 minutes

// TODO: Change the API Key
const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY; // the API key can be found in the "Credentials" section

const VideoCall = () => {
    const [client, setClient] = useState();
    const [call, setCall] = useState();
    const [participants, setParticipants] = useState([]);
    const [hasCallStarted, setHasCallStarted] = useState(false);
    const [showRules, setShowRules] = useState(true);
    const [showExtendCall, setShowExtendCall] = useState(false);
    const [extendCall, setExtendCall] = useState(false);
    const [callData, setCallData] = useState();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();
    const { oCallData } = location.state || {};
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const totalCallSeconds = user.totalCallSeconds;

    const [startTime, setStartTime] = useState(null);
    const [seconds, setSeconds] = useState(0);
    const intervalRef = useRef(null);
    const extendCallRef = useRef(extendCall);

    useEffect(() => {
        if (oCallData) {
            setCallData(oCallData);
            navigate('.', { state: undefined, replace: true });
        }
    }, [oCallData]);

    const { mutate: mutateEndCall } = useMutation({
        mutationFn: () => endCall(i18n.language.split('-')[0], callData?.id),
        onSuccess: (response) => {
            if (response.data.nDuration) {
                const user = {
                    lastCall: {
                        id: response.data.id,
                        name: getFullName(callData.client.oName),
                        image: callData.client.sProfilePicUrl,
                        seconds: response.data.nDuration,
                    },
                    totalCalls: response.data.nTotalCalls,
                    totalCallSeconds:
                        totalCallSeconds + response.data.nDuration,
                };
                dispatch(updateUser(user));
            }
            // navigate('.', { state: undefined, replace: true });
        },
        onError: (error) => handleApiError(error, t, navigate),
    });

    const { mutate: mutateCheckAvailability } = useMutation({
        mutationFn: () =>
            checkNextAvailability(i18n.language.split('-')[0], callData?.id),
        onSuccess: () => {
            notify('info', t('call.call_end_alert'));
            setTimeout(() => {
                setShowExtendCall(true);
            }, 3000);
        },
        onSettled: () => {
            const endTime = new Date(callData.oSelectedSlot.dEndTime).getTime();
            const currentTime = new Date().getTime();
            const timeRemaining = endTime - currentTime;
            setTimeout(() => {
                if (!extendCallRef.current) {
                    if (call)
                        call.leave().catch((err) => {
                            console.error(`Failed to leave the call`, err);
                        });
                    setCall(undefined);
                    if (user.accountType === 'R') {
                        navigate('/');
                    } else navigate('/appointments/schedule');
                }
            }, timeRemaining);
        },
    });

    const { mutate: mutateExtend, isLoading: isExtending } = useMutation({
        mutationFn: () =>
            extendCurrentCall(i18n.language.split('-')[0], callData?.id),
        onSuccess: (response) => {
            setExtendCall(true);

            const endTime = new Date(response.data.dEndTime).getTime();
            const currentTime = new Date().getTime();
            const timeRemaining = endTime - currentTime;
            const alertTime = timeRemaining - ALERT_BEFORE;

            setTimeout(() => {
                setExtendCall(false);
                mutateCheckAvailability();
            }, alertTime);
        },
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => setShowExtendCall(false),
    });

    useEffect(() => {
        extendCallRef.current = extendCall;
    }, [extendCall]);

    const {
        data: conversationPopUp,
        isLoading: isLoadingCurrentTarget,
        error: targetError,
    } = useQuery({
        queryKey: [
            'conversation-popup',
            i18n.language.split('-')[0],
            callData?.id,
        ],
        queryFn: () =>
            conversationBooster(i18n.language.split('-')[0], callData?.id).then(
                (res) => res.data
            ),
        enabled: user?.accountType === 'S' && !!callData?.id,
        // disabled: !callData?.id,
    });

    const [conversationText, setConversationText] = useState('');
    const [isCheckToaster, setIsCheckToaster] = useState(true);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        let intervalId;
        if (isCheckToaster) {
            intervalId = setInterval(() => {
                if (index < conversationPopUp?.length) {
                    setConversationText(conversationPopUp[index]);
                    setIndex((prevIndex) => prevIndex + 1); // Increment index
                } else {
                    clearInterval(intervalId); // Clear the interval if we've gone through all items
                    setConversationText('');
                }
            }, 6 * 60 * 1000); // 6 minutes
        } else {
            clearInterval(intervalId); // Clear the interval when isCheckToaster is false
        }

        return () => clearInterval(intervalId); // Cleanup function to clear interval
    }, [isCheckToaster, conversationPopUp, index]); // Include index in dependencies array

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowRules(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!user.id) return;

        // set up the user object
        const oUser = {
            id: user.id,
            name: getUserFullName(user),
            image:
                user.profilePicUrl ||
                'https://getstream.io/random_svg/?' +
                    new URLSearchParams({
                        id: getUserFullName(user),
                        name: getUserFullName(user),
                    }),
        };

        document.title = formatPageTitle(t('call.video_call'));

        const myClient = new StreamVideoClient({
            apiKey,
            user: oUser,
            token: getCallToken(),
            options: {
                logger: (logLevel, msg, extra) => {
                    if (logLevel === 'error') console.log(msg);
                },
            },
        });
        setClient(myClient);

        myClient?.on('call.ended', (event) => {
            notify(
                'error',
                t('call.call_ended_by', {
                    name: getFullName(oCallData.client.oName),
                })
            );
            navigate(user.accountType === 'S' ? '/appointments/schedule' : '/');
            return;
        });
        setHasCallStarted(false);

        return () => {
            myClient.off('call.ended');
            myClient.disconnectUser();
            setClient(undefined);
        };
    }, [user]);

    useEffect(() => {
        if (!client || !callData) return;
        const myCall = client.call('1on1', callData.id);

        myCall.join().catch((err) => {
            console.error(`Failed to join the call`, err);
        });

        setCall(myCall);

        return () => {
            if (callData?.id) mutateEndCall();
            if (call)
                call.leave().catch((err) => {
                    console.error(`Failed to leave the call`, err);
                });
            setCall(undefined);
        };
    }, [client]);

    let alertTime;

    useEffect(() => {
        if (!client || !call) return;

        const endTime = new Date(callData?.oSelectedSlot?.dEndTime).getTime();
        const currentTime = new Date().getTime();
        const timeRemaining = endTime - currentTime;
        alertTime = timeRemaining - ALERT_BEFORE;
        if (timeRemaining <= 0) {
            call.leave().catch((err) => {
                console.error(`Failed to leave the call`, err);
            });
            setCall(undefined);
            navigate(-1);
            return;
        }

        if (alertTime > 0) {
            setTimeout(() => {
                if (participants.length) {
                    notify('info', t('call.call_end_alert'));
                }
                if (!extendCall) {
                    mutateCheckAvailability();
                }
            }, alertTime);
        }
    }, [client, call, alertTime]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
            if (callData?.id) mutateEndCall();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!participants?.length) return;
        if (participants?.length >= 2 && !hasCallStarted) {
            setHasCallStarted(true);
        }
    }, [participants]);

    useEffect(() => {
        if (hasCallStarted) {
            setStartTime(Date.now());
            intervalRef.current = setInterval(() => {
                setSeconds(Math.floor((Date.now() - startTime) / 1000));
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
            setStartTime(null);
            setSeconds(0);
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [hasCallStarted, startTime]);

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (showRules)
        return (
            <div className='rules'>
                <BreadcrumbAction
                    lastPage={t('call.video_call')}
                    currentPage={t('speak_now.rules')}
                />
                <RulesContent />
            </div>
        );

    const handleDoubleClick = () => {
        setIsFullScreen(!isFullScreen);
    };
    return (
        <>
            <div className='demo-call'>
                <BreadcrumbAction lastPage={t('call.video_call')} />
                <div
                    className={`content-wrapper video-call-wrapper text-center ${
                        isFullScreen ? 'full-screen' : ''
                    }`}
                >
                    {!client || !call ? (
                        <Loader innerLoader={true} />
                    ) : (
                        <StreamVideo client={client}>
                            <StreamTheme>
                                <div
                                    className='call-wrapper'
                                    onDoubleClick={handleDoubleClick}
                                >
                                    <div className='boosterChat'>
                                        {participants?.length >= 2 ? (
                                            <div className='timer'>
                                                <p className='sm'>
                                                    {' '}
                                                    {formatTime(minutes)}:
                                                    {formatTime(
                                                        remainingSeconds
                                                    )}
                                                </p>
                                            </div>
                                        ) : null}

                                        {user?.accountType === 'S' &&
                                        participants?.length >= 2 ? (
                                            <CallToaster
                                                conversationText={
                                                    conversationText
                                                }
                                                setIsCheckToaster={
                                                    setIsCheckToaster
                                                }
                                                isCheckToaster={isCheckToaster}
                                            />
                                        ) : null}
                                    </div>
                                    {participants?.length < 2 && (
                                        <div className='header'>
                                            <h1>
                                                {getFullName(
                                                    callData.client.oName
                                                )}
                                            </h1>
                                            <h3>{t('call.ringing')}</h3>
                                        </div>
                                    )}
                                    <StreamCall call={call}>
                                        <SpeakerLayout />

                                        <WrappedCallUI
                                            setCall={setCall}
                                            setParticipants={setParticipants}
                                            navigate={navigate}
                                            t={t}
                                            client={client}
                                        />
                                    </StreamCall>
                                </div>
                            </StreamTheme>
                        </StreamVideo>
                    )}
                </div>
            </div>

            {/* Extend Call POPUP  */}
            <ModalPopUp
                show={showExtendCall}
                onHide={() => setShowExtendCall(false)}
                isCloseIcon={false}
                isBackDrop={true}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {t('popup_content.continue')}
                        </h3>

                        <p className='sm'>{t('popup_content.continue_desc')}</p>

                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                disabled={isExtending}
                                onClick={mutateExtend}
                            >
                                {t('chat.yes')}
                            </button>
                            <button
                                className='simple-btn'
                                onClick={() => setShowExtendCall(false)}
                            >
                                {isExtending ? <LoadingButton /> : t('chat.no')}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
};

const WrappedCallUI = ({ setCall, setParticipants, navigate, t, client }) => {
    const call = useCall();

    const {
        useCallCallingState,
        useLocalParticipant,
        useParticipants,
        useCameraState,
        useMicrophoneState,
    } = useCallStateHooks();
    const callingState = useCallCallingState();
    const localParticipant = useLocalParticipant();
    const participants = useParticipants();

    const {
        hasBrowserPermission: hasCameraPermission,
        devices: cameraDevices = [],
    } = useCameraState();

    const {
        hasBrowserPermission: hasMicrophonePermission,
        devices: microphoneDevices = [],
    } = useMicrophoneState();

    useEffect(() => {
        if (!participants.length) return;
        setParticipants(participants);
    }, [participants]);

    const isVideoMute = !localParticipant?.publishedTracks.includes(
        SfuModels.TrackType.VIDEO
    );
    const isAudioMute = !localParticipant?.publishedTracks.includes(
        SfuModels.TrackType.AUDIO
    );

    if (callingState !== CallingState.JOINED) {
        return <Loader innerLoader={true} />;
    }

    const handleVideo = async () => {
        try {
            if (!cameraDevices.length)
                return notify('error', t('call.no_camera'));
            if (!hasCameraPermission)
                return notify('error', t('call.camera_permission'));
            await call.camera.toggle();
        } catch (error) {
            console.log(error);
        }
    };

    const handleAudio = async () => {
        try {
            if (!microphoneDevices.length)
                return notify('error', t('call.no_microphone'));
            if (!hasMicrophonePermission)
                return notify('error', t('call.microphone_permission'));
            await call.microphone.toggle();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='video-controls'>
            <button onClick={handleVideo}>
                {isVideoMute ? (
                    <img src={MuteVideo} alt='' />
                ) : (
                    <img src={UnmuteVideo} alt='' />
                )}
            </button>
            <button
                className='end-call'
                onClick={async () => {
                    client.off('call.ended');
                    client.disconnectUser();
                    await call.reject();
                    await call.leave();
                    await call.endCall();
                    setCall(undefined);
                    navigate(-1);
                }}
            >
                <img src={CallEnd} alt='' />
            </button>

            <button onClick={handleAudio}>
                {isAudioMute ? (
                    <img src={UnmuteMic} alt='' />
                ) : (
                    <img src={MuteMic} alt='' />
                )}
            </button>
        </div>
    );
};

export default VideoCall;
