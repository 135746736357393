/* eslint-disable jsx-a11y/alt-text */
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { getConversations } from '../../../api/adapters/chat';
import { updateMultipleNotification } from '../../../api/adapters/notification';
import { CommonContext } from '../../../context/CommonState';
import { deleteMessageNotification } from '../../../redux/actions/notification';
import {
    formatPageTitle,
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { getToken, getUserId } from '../../../utils/helpers/cookies.helpers';
import CertificateCard from '../../components/chat/CertificateCard';
import ChatBox from '../../components/chat/ChatBox';
import ChatHistory from '../../components/chat/ChatHistory';
import ChatTabs from '../../components/chat/ChatTabs';
import Welcome from '../../components/chat/Welcome';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';

function Chat() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {
        user,
        chaHistory,
        isMobile,
        mobileLayout,
        setMobileLayout,
        mobileLayoutUser,
        certificate,
        setCertificate,
    } = useContext(CommonContext);
    const location = useLocation();
    const dispatch = useDispatch();
    const notification = useSelector((state) => state.notification);

    const { conversationId } = location.state || {};

    const [selectedConversation, setSelectedConversation] = useState(null);
    const [conversations, setConversations] = useState(null);
    const [socket, setSocket] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const {
        data: conversationData,
        refetch: refetchConversation,
        error: conversationError,
    } = useQuery({
        queryKey: ['conversations', i18n.language?.split('-')[0]],
        queryFn: () =>
            getConversations(i18n.language?.split('-')[0]).then((res) => {
                return res.data;
            }),
    });

    useEffect(() => {
        if (conversationError) handleQueryError(conversationError, navigate);
    }, [conversationError]);

    useEffect(() => {
        if (conversationData) {
            conversationData.sort(compareByTimestamp);
            setConversations(conversationData);
            if (conversationId) {
                setSelectedConversation(conversationId);
                navigate('.', { state: undefined, replace: true });
            }
        }
    }, [conversationData, conversationId]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BACK_END_URL, {
            auth: { authorization: getToken() },
        });

        setSocket(socket);
    }, []);

    useEffect(() => {
        if (socket) {
            socket.connect();
            socket.on('message-notification', onMessage);
            socket.on('message-read', onMessageRead);

            socket.emit('join-room', getUserId());
        }
        return () => {
            if (socket) {
                socket.disconnect();
                socket.off('message-notification', onMessage);
            }
        };
    }, [socket]);

    useEffect(() => {
        if (!isMobile) setMobileLayout(true);
    }, []);

    useEffect(() => {
        document.title = formatPageTitle(t('chat.chat'));
    }, [i18n.language]);

    const updateNotification = useCallback((type) => {
        mutateUpdateNotification(type);
    }, []);

    const { mutate: mutateUpdateNotification } = useMutation({
        mutationFn: (type) =>
            updateMultipleNotification(i18n.language.split('-')[0], type),
        onSuccess: (response, data) => {
            dispatch(deleteMessageNotification());
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const aAppointment = notification.filter((item) =>
        ['TEXT', 'IMAGE', 'AUDIO', 'VIDEO'].includes(item.sNotificationType)
    );
    useEffect(() => {
        if (aAppointment.length > 0) {
            updateNotification({ type: 'MESSAGE' });
        }
    }, [updateNotification]);

    const onMessageRead = (data) => {
        setConversations((prevConversations) => {
            const newConversations = prevConversations;
            const indexToReplace = newConversations.findIndex(
                (conversation) => conversation._id === data._id
            );

            const oUpdatedData = newConversations[indexToReplace];
            oUpdatedData.nUnreadMessages = data.nUnreadMessages;
            oUpdatedData.sLastText = data.sLastText;
            oUpdatedData.dLastMessageTime = data.dLastMessageTime;
            oUpdatedData.iLastConnectedUser = data.iLastConnectedUser;
            oUpdatedData.bIsCleared = data.bIsCleared;
            oUpdatedData.bIsBlocked = data.bIsBlocked;
            oUpdatedData.eMessageType = data.eMessageType;
            oUpdatedData.aClearedUsers = data.aClearedUsers;

            newConversations[indexToReplace] = oUpdatedData;
            newConversations.sort(compareByTimestamp);

            return [...newConversations];
        });
    };

    const onMessage = (data) => {
        setConversations((prevConversations) => {
            const newConversations = prevConversations;
            const indexToReplace = newConversations.findIndex(
                (conversation) =>
                    conversation._id === data.oChatMessage.iConversation
            );
            if (indexToReplace === -1) {
                refetchConversation();
                return prevConversations;
            }

            const oUpdatedData = newConversations[indexToReplace];

            setSelectedConversation((prevSelectedConversation) => {
                if (
                    prevSelectedConversation === data.oChatMessage.iConversation
                ) {
                    oUpdatedData.nUnreadMessages = 0;
                    socket.emit('read-message', prevSelectedConversation);
                } else oUpdatedData.nUnreadMessages = data.nUnreadMessages;
                return prevSelectedConversation;
            });

            oUpdatedData.eMessageType = data.oChatMessage.eMessageType;
            oUpdatedData.bIsBlocked = data.oChatMessage.bIsBlocked;
            oUpdatedData.dLastMessageTime = data.oChatMessage.dCreatedAt;
            oUpdatedData.iLastConnectedUser = data.oChatMessage.iSender;
            oUpdatedData.sLastText =
                data.oChatMessage.eMessageType === 'AUDIO' ||
                data?.oChatMessage?.sContent === 'IMAGE'
                    ? ''
                    : data.oChatMessage.sContent;
            if (oUpdatedData.bIsCleared) oUpdatedData.bIsCleared = false;

            newConversations[indexToReplace] = oUpdatedData;
            newConversations.sort(compareByTimestamp);

            return [...newConversations];
        });
    };

    function compareByTimestamp(a, b) {
        const timestampA = new Date(a.dLastMessageTime).getTime();
        const timestampB = new Date(b.dLastMessageTime).getTime();
        return timestampB - timestampA;
    }

    return (
        <>
            <div className='chat-page'>
                <>
                    <BreadcrumbAction lastPage={t('chat.chat')} />
                    <div className='content-wrapper'>
                        <div className='chat-wrapper'>
                            {!mobileLayoutUser && (
                                <div className='chat-users'>
                                    <ChatTabs
                                        conversations={conversations}
                                        setSelectedConversation={
                                            setSelectedConversation
                                        }
                                        setConversations={setConversations}
                                        selectedConversation={
                                            selectedConversation
                                        }
                                    />
                                </div>
                            )}
                            {mobileLayout && (
                                <div className='chat-section'>
                                    {!certificate && !selectedConversation ? (
                                        <Welcome />
                                    ) : null}
                                    {!chaHistory && selectedConversation ? (
                                        <ChatBox
                                            selectedConversation={
                                                selectedConversation
                                            }
                                            setConversations={setConversations}
                                            socket={socket}
                                            setSelectedConversation={
                                                setSelectedConversation
                                            }
                                        />
                                    ) : null}
                                    {chaHistory ? (
                                        <ChatHistory
                                            selectedConversation={
                                                selectedConversation
                                            }
                                        />
                                    ) : null}
                                    {certificate ? <CertificateCard /> : null}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default Chat;
