import PropTypes from 'prop-types';
import React, { useId, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import EyeClose from '../../../assets/images/EyeClose.svg';
import EyeOpen from '../../../assets/images/EyeOpen.svg';

function InputField(props) {
    const {
        label,
        type,
        placeholder,
        errorMsg,
        handleOnChange,
        value,
        name,
        disabled,
        autoComplete,
    } = props;

    const [icon, setIcon] = useState(true);

    const inputRef = useRef(null);
    const idInput = useId();

    const handlePass = (event) => {
        event.preventDefault();

        inputRef.current.type = icon ? 'text' : 'password';

        setIcon((prev) => !prev);
    };

    return (
        <Form.Group controlId={idInput}>
            {label ? <Form.Label>{label}</Form.Label> : ''}

            <div className='input-wrapper'>
                <Form.Control
                    type={type}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    value={value}
                    name={name}
                    ref={inputRef}
                    className={`${errorMsg ? 'error-input' : ''}`}
                    disabled={disabled}
                    autoComplete={autoComplete}
                />

                {type === 'password' && (
                    <span
                        className='simple-btn pass-btn c-pointer'
                        onClick={handlePass}
                    >
                        <img src={icon ? EyeOpen : EyeClose} alt='' />
                    </span>
                )}
            </div>

            {errorMsg ? (
                <Form.Text className=' text-danger'>{errorMsg}</Form.Text>
            ) : (
                ''
            )}
        </Form.Group>
    );
}

InputField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    errorMsg: PropTypes.string,
    handleOnChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
};

export default InputField;
