import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getConversationCallHistory } from '../../../api/adapters/call';
import { CommonContext } from '../../../context/CommonState';
import { handleQueryError } from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import {
    formatTimeShort,
    getRelativeDateString,
    getTimeFromDate,
} from '../../../utils/helpers/format.helpers';

function ChatHistory(props) {
    const { selectedConversation } = props;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { user, setChaHistory } = useContext(CommonContext);

    const [callHistory, setCallHistory] = useState([]);

    const { data: callHistoryData, error: callHistoryError } = useQuery({
        queryKey: [
            'conversationCallHistory',
            selectedConversation,
            i18n.language?.split('-')[0],
        ],
        queryFn: () =>
            getConversationCallHistory(
                i18n.language?.split('-')[0],
                selectedConversation
            ).then((res) => res.data),
    });

    useEffect(() => {
        if (callHistoryError) handleQueryError(callHistoryError, navigate);
    }, [callHistoryError]);

    useEffect(() => {
        if (callHistoryData) {
            callHistoryData.sort(compareByTimestamp);
            setCallHistory(callHistoryData);
        }
    }, [callHistoryData]);

    function compareByTimestamp(a, b) {
        const timestampA = new Date(a.dCreatedAt).getTime();
        const timestampB = new Date(b.dCreatedAt).getTime();
        return timestampB - timestampA;
    }

    return (
        <div className='chat-box chat-history'>
            <div className='chat-header'>
                <div className='history-action'>
                    <button
                        className='simple-btn'
                        onClick={() => setChaHistory(false)}
                    >
                        <img src='/images/back-arrow.svg' alt='' />
                    </button>
                    <p className='sm'>
                        {t('chat.call_history')}:{' '}
                        <span className='blue-text'>{user?.userName}</span>
                    </p>
                </div>
            </div>
            <div className='chat-history-wrapper'>
                <ul className='history-items'>
                    {callHistory.map((call, index) => {
                        return (
                            <li key={index}>
                                <div className='history'>
                                    <p className=''>
                                        {getRelativeDateString(
                                            call.dCreatedAt,
                                            t,
                                            i18n.language.split('-')[0]
                                        )}
                                    </p>
                                    <img
                                        src={
                                            !call?.nCallDuration &&
                                            call?.iCaller !== getUserId()
                                                ? '/images/miss-call.svg'
                                                : call?.iCaller === getUserId()
                                                ? '/images/outgoing.svg'
                                                : '/images/history-ic1.svg'
                                        }
                                        alt=''
                                    />
                                </div>
                                <div className='history'>
                                    <span className='time-ic'>
                                        <img
                                            src='/images/time-ic2.svg'
                                            alt=''
                                        />
                                        <p className='sm grey-text'>
                                            {getTimeFromDate(call.dCreatedAt)}
                                        </p>
                                    </span>
                                    <p className='sm grey-text'>
                                        {formatTimeShort(
                                            call?.nCallDuration,
                                            t
                                        )}
                                    </p>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default ChatHistory;
