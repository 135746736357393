import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CallToaster(props) {
    const { t } = useTranslation();
    const { conversationText, setIsCheckToaster, isCheckToaster } = props;

    const [showChatBooster, setShowChatBooster] = useState(false);
    const [isCheckBooster, setIsCheckBooster] = useState(false);

    useEffect(() => {
        if (conversationText && !showChatBooster) {
            setShowChatBooster(true);

            const timer = setTimeout(() => {
                setShowChatBooster(false);
            }, 30 * 1000); // 30 seconds

            return () => clearTimeout(timer); // Clear the timer when the component unmounts
        } else {
            setShowChatBooster(false);
        }
    }, [isCheckToaster, conversationText]);

    return (
        <div className='call-toaster'>
            {isCheckToaster &&
                !isCheckBooster &&
                conversationText &&
                !showChatBooster && (
                    <div className='call-chat-booster'>
                        <>
                            <p>{t('suggestion')}</p>
                            <p className='sm'>{conversationText}</p>
                        </>
                    </div>
                )}

            {isCheckBooster && (
                <div className='call-chat-booster'>
                    <>
                        <label style={{ color: 'white' }}>
                            {t('want_suggestion')}
                        </label>
                        <div className='toaster-checkbox'>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type={'checkbox'}
                                    onChange={() => {
                                        // setIsCheckToaster(true);
                                        isCheckToaster
                                            ? setIsCheckToaster(false)
                                            : setIsCheckToaster(true);
                                        setIsCheckBooster(false);
                                    }}
                                    id={`default-checkbox`}
                                    label={t('yes_or_no')}
                                    checked={isCheckToaster}
                                />
                            </Form>
                        </div>
                    </>
                </div>
            )}

            <div className='icon text-end'>
                <button
                    className='simple-btn'
                    onClick={() => {
                        // setIsCheckToaster(true);
                        isCheckBooster
                            ? setIsCheckBooster(false)
                            : setIsCheckBooster(true);
                        setShowChatBooster(false);
                    }}
                >
                    {isCheckToaster && (
                        <img src='/images/icon-call.svg' alt='' />
                    )}
                    {!isCheckToaster && (
                        <img src='/images/isCheck_ic.svg' alt='' />
                    )}
                </button>
            </div>
        </div>
    );
}

export default CallToaster;
