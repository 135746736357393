import React, { useContext, useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { CommonContext } from '../../../context/CommonState';

function VoiceRecorder(props) {
    const { setSelectedAudio } = props;
    const { inputWidth } = useContext(CommonContext);
    const [disableRecording, setDisableRecording] = useState(false);
    const recorderControls = useAudioRecorder(
        {
            noiseSuppression: true,
            echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
    );

    const addAudioElement = (blob) => {
        setSelectedAudio(blob);

        if (!disableRecording && blob !== null) {
            const url = URL.createObjectURL(blob);
            const audio = document.createElement('audio');
            audio.src = url;
            audio.controls = true;
            setDisableRecording(false);
        }
    };

    const handle = () => {
        setDisableRecording(false);
        recorderControls.isRecording
            ? recorderControls.stopRecording()
            : recorderControls.startRecording();
    };

    useEffect(() => {
        const canvas = document.querySelector(
            '.audio-recorder-visualizer  canvas'
        );
        if (canvas) {
            canvas.width = 600;
            canvas.height = 20;
            canvas.style.width = '100%';
        }
    }, []);
    const handleClearAudio = () => {
        if (recorderControls?.isRecording) {
            recorderControls.stopRecording();
        }
        setSelectedAudio(null);
        setDisableRecording(true); // Disable recording
    };

    return (
        <div
            className='voiceRecorder'
            style={{
                width: recorderControls.isRecording && `${inputWidth}px`,
                backgroundColor: recorderControls.isRecording && '#0080ff',
            }}
        >
            {recorderControls?.isRecording && (
                <>
                    <button onClick={handleClearAudio}>
                        <img src='/images/sound-delete.svg' alt='' />
                    </button>
                </>
            )}{' '}
            <AudioRecorder
                onRecordingComplete={(blob) => {
                    if (!disableRecording) {
                        // Check if recording is disabled
                        addAudioElement(blob);
                    }
                }}
                recorderControls={recorderControls}
                // downloadOnSavePress={true}
                // downloadFileExtension="mp3"
                showVisualizer={true}
            />
            <button className='voiceRecorder__recordButton' onClick={handle}>
                {recorderControls.isRecording ? (
                    <img src='/images/sent-voice.svg' alt='' />
                ) : (
                    // ''
                    <img src='/images/record_ic.svg' alt='' />
                )}
            </button>
        </div>
    );
}

export default VoiceRecorder;
