import apiClient from '../client/api-client';

export const getCities = async (language, countryId) => {
    const url = `user/country/${countryId}/city/list`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const getUniversities = async (language, cityId) => {
    const url = `user/city/${cityId}/university/list`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const getResidencies = async (language, cityId) => {
    const url = `user/city/${cityId}/residency/list`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};
