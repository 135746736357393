import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendOtp } from '../../../api/adapters/auth';
import { formatPageTitle } from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import {
    isValidEmail,
    isValidName,
} from '../../../utils/validators/common.validators';
import ModalPopUp from '../../components/ModalPopUp';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';
import InputField from '../../components/mini-components/InputField';
import LangSelector from '../../components/mini-components/LangSelector';
import LoadingButton from '../../components/mini-components/LoadingButton';
import LegalNotice from '../cms-pages/LegalNotice';

function SignUp() {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState({
        firstName: location.state?.firstName || '',
        middleName: location.state?.middleName || '',
        lastName: location.state?.lastName || '',
        email: location.state?.email || '',
        password: location.state?.password || '',
        confirmPassword: location.state?.confirmPassword || '',
    });
    const [isChecked, setIsChecked] = useState(false);

    const [formErrors, setFormErrors] = useState({});

    const { mutate: mutateSendOtp, isLoading: isMutating } = useMutation({
        mutationFn: (data) => sendOtp(i18n.language?.split('-')[0], data),
        onSuccess: (response) => {
            notify('success', response.message);

            navigate('/otp-verification', {
                state: { ...userData },
            });
        },
        onError: (error) => {
            if (error.status === 422) {
                const errObj = formErrors;
                error.message?.response?.data?.data?.errors?.forEach((err) => {
                    errObj[err.path] = err.msg;
                });

                setFormErrors({ ...errObj });
            } else {
                notify(
                    'error',
                    error.message || t('common.errors.something_went_wrong')
                );
            }
        },
    });

    const handleInputChange = (e) => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            [e.target.name]: e.target.value,
        }));

        if (e.target.name === 'password') {
            const passwordErrors = validatePassword(e.target.value);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                ...passwordErrors,
                // password: Object.values(passwordErrors).join(', '),
                // password: Object.values(passwordErrors)
                //     .filter((error) => error !== '')
                //     .join(', '),
                password: Object.values(passwordErrors)
                    .filter((error) => error !== '')
                    .map((error, index, array) => (
                        <React.Fragment key={index}>
                            {error}
                            {index < array.length - 1 && <br />}
                        </React.Fragment>
                    )),
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = formErrors;

        // Validate First Name
        if (!userData.firstName.trim())
            errors.firstName = t('sign_up.error_enter_first_name');
        else if (!isValidName(userData.firstName))
            errors.firstName = t('sign_up.error_invalid_first_name');
        else errors.firstName = '';

        // Validate Middle Name
        if (!userData.middleName.trim())
            errors.middleName = t('sign_up.error_enter_surname') + ' 1';
        else if (!isValidName(userData.middleName))
            errors.middleName = t('sign_up.error_invalid_surname') + ' 1';
        else errors.middleName = '';

        // Validate Surname
        if (userData.lastName.trim() && !isValidName(userData.lastName))
            errors.lastName = t('sign_up.error_invalid_surname') + ' 2';
        else errors.lastName = '';

        // Validate Email
        if (!userData.email.trim())
            errors.sEmail = t('sign_up.error_enter_email');
        else if (!isValidEmail(userData.email.trim()))
            errors.sEmail = t('sign_up.error_invalid_email');
        else errors.sEmail = '';

        // Validate password
        // if (!userData.password.trim())
        //     errors.password = t('sign_up.error_enter_password');
        // else if (!isValidPassword(userData.password.trim()))
        //     errors.password = t('sign_up.error_invalid_password');
        // else errors.password = '';

        if (!userData.password.trim()) {
            errors.password = t('sign_up.error_enter_password');
        } else {
            const passwordErrors = validatePassword(userData.password.trim());
            if (Object.values(passwordErrors).some((error) => error !== '')) {
                errors.password = Object.values(passwordErrors)
                    .filter((error) => error !== '')
                    .join(', ');
            } else {
                errors.password = '';
            }
        }

        // Validate confirm password
        if (!userData.confirmPassword.trim())
            errors.confirmPassword = t('sign_up.error_enter_confirm_password');
        // else if (!isValidPassword(userData.confirmPassword.trim()))
        //     errors.confirmPassword = t('sign_up.error_invalid_password');
        else if (userData.confirmPassword.trim() !== userData.password.trim())
            errors.confirmPassword = t('sign_up.error_passwords_do_not_match');
        else errors.confirmPassword = '';

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;
        if (!isChecked) return notify('error', t('sign_up.error_accept_terms'));
        mutateSendOtp({ sEmail: userData.email });
    };

    const validatePassword = (password) => {
        const errors = {};

        if (password.length < 8) {
            errors.passwordLength = t('sign_up.error_password_length');
        } else errors.passwordLength = '';

        if (!/(?=.*[A-Z])/.test(password)) {
            errors.passwordUppercase = t('sign_up.error_password_uppercase');
        } else errors.passwordUppercase = '';

        if (!/(?=.*[a-z])/.test(password)) {
            errors.passwordLowercase = t('sign_up.error_password_lowercase');
        } else errors.passwordLowercase = '';

        if (!/(?=.*[0-9])/.test(password)) {
            errors.passwordDigit = t('sign_up.error_password_digit');
        } else errors.passwordDigit = '';

        if (!/(?=.*[!@#$%^&*?:{}'"<>.;,_\-()[_+/\]])/.test(password)) {
            errors.passwordSpecialChar = t(
                'sign_up.error_password_special_char'
            );
        } else errors.passwordSpecialChar = '';

        return errors;
    };

    useEffect(() => {
        document.title = formatPageTitle(t('account_setup.sign_up_btn'));
    }, [i18n.language]);

    const handleLegalPolicy = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        <>
            <div className='account-setup'>
                <LeftTextAnimation />

                <div className='right-screen'>
                    <div className='language-selector'>
                        <div className='lang-wrapper'>
                            <LangSelector i18n={i18n} />
                        </div>

                        <div className='account-setup-wrapper'>
                            <h1 className='text-center'>
                                {t('account_setup.create')}
                            </h1>

                            <Form
                                className='login-form'
                                onSubmit={handleSubmit}
                            >
                                <p className='text-center'>
                                    {t('account_setup.fill_up')}
                                </p>

                                <div className='row form-space'>
                                    <div className='col-12'>
                                        <InputField
                                            label={t('field_name.first_name')}
                                            placeholder={t(
                                                'field_name.first_name_placeholder'
                                            )}
                                            type='text'
                                            name='firstName'
                                            value={userData.firstName}
                                            handleOnChange={handleInputChange}
                                            errorMsg={formErrors.firstName}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <InputField
                                            label={
                                                t('field_name.surname') + ' 1*'
                                            }
                                            placeholder={
                                                t(
                                                    'field_name.surname_placeholder'
                                                ) + ' 1'
                                            }
                                            type='text'
                                            name='middleName'
                                            value={userData.middleName}
                                            handleOnChange={handleInputChange}
                                            errorMsg={formErrors.middleName}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <InputField
                                            label={
                                                t('field_name.surname') + ' 2'
                                            }
                                            placeholder={
                                                t(
                                                    'field_name.surname_placeholder'
                                                ) + ' 2'
                                            }
                                            type='text'
                                            name='lastName'
                                            value={userData.lastName}
                                            handleOnChange={handleInputChange}
                                            errorMsg={formErrors.lastName}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <InputField
                                            label={t('field_name.email_id')}
                                            placeholder={t(
                                                'field_name.email_id_placeholder'
                                            )}
                                            type='email'
                                            name='email'
                                            value={userData.email}
                                            handleOnChange={handleInputChange}
                                            errorMsg={formErrors.sEmail}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <InputField
                                            label={t('field_name.password')}
                                            placeholder={t(
                                                'field_name.password_placeholder'
                                            )}
                                            type='password'
                                            name='password'
                                            value={userData.password}
                                            handleOnChange={handleInputChange}
                                            // onBlur={() =>
                                            //     validatePassword(
                                            //         userData.password
                                            //     )
                                            // }
                                            errorMsg={formErrors.password}
                                            autoComplete='new-password'
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <InputField
                                            label={t(
                                                'field_name.confirm_password'
                                            )}
                                            placeholder={t(
                                                'field_name.confirm_password_placeholder'
                                            )}
                                            type='password'
                                            name='confirmPassword'
                                            value={userData.confirmPassword}
                                            handleOnChange={handleInputChange}
                                            errorMsg={
                                                formErrors.confirmPassword
                                            }
                                            autoComplete='new-password'
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/* <Form.Check // prettier-ignore
                                    type={'checkbox'}
                                    id={`login-checkbox`}
                                      className='check-box'
                                    label={t('account_setup.remember')}
                                /> */}
                                    <Form.Check // prettier-ignore
                                        type={'checkbox'}
                                        id={`login-checkbox`}
                                        checked={isChecked}
                                        onChange={handleLegalPolicy}
                                        label={
                                            <>
                                                {t('i_accept')}{' '}
                                                <Link
                                                    to='/legal-notice'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {t(
                                                        'footer_links.terms_conditions'
                                                    )}
                                                </Link>
                                                ,{' '}
                                                <Link
                                                    to={'/privacy-policy'}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {t(
                                                        'footer_links.privacy_policy'
                                                    )}
                                                </Link>
                                                ,{' '}
                                                <Link
                                                    to='/cookies'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {t(
                                                        'footer_links.cookies_title'
                                                    )}
                                                </Link>
                                            </>
                                        }
                                        className='check-box'
                                    />

                                    <div className='action-btn'>
                                        <button
                                            className='primary-btn w-100'
                                            disabled={isMutating}
                                        >
                                            {isMutating ? (
                                                <LoadingButton color='White' />
                                            ) : (
                                                t('account_setup.sign_in_btn')
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                            <div className='form-footer'>
                                <p className='sm'>
                                    {t('account_setup.already')}{' '}
                                    <Link to='/sign-in'>
                                        {t('account_setup.sign_in_btn')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopUp
                show={showModal}
                className={'legal-modal'}
                onHide={() => setShowModal(false)}
                modalcontent={
                    <div className='cms-page'>
                        <LegalNotice />
                    </div>
                }
            />
        </>
    );
}

export default SignUp;
