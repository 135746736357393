import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import RulesContent from '../../components/speak-now/RulesContent';

function Rules() {
    const { t } = useTranslation();

    return (
        <div className='rules'>
            <BreadcrumbAction
                lastPage={t('speak_now.speak_now')}
                currentPage={t('speak_now.rules')}
                lastPageLink='/speak-now'
            />
            <RulesContent />
        </div>
    );
}

export default Rules;
