import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserFullName } from '../../../utils/helpers/format.helpers';

function Welcome() {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);

    return (
        <div className='grey-bg welcome-screen'>
            <img className='chat-logo' src='/images/chat-logo.svg' alt='' />
            <div className='welcome-wrapper'>
                <div className='dp'>
                    <img
                        src={
                            user.profilePicUrl ||
                            '/images/profile-image-placeholder.svg'
                        }
                        alt=''
                    />
                </div>
                <h3>
                    {t('chat.welcome')} <br />
                    <span>{getUserFullName(user)}</span>
                </h3>
            </div>
        </div>
    );
}

export default Welcome;
