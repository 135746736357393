import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Select, { components } from 'react-select';
const CustomDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <img className='arrow' src='/images/icons/dropdown.svg' alt='' />
    </components.DropdownIndicator>
);

function DropDownSelector(props) {
    const {
        dropDownLabel,
        placeholder,
        options,
        isDisabled,
        handleOnChange,
        customClass,
        whenOpen = null,
        value,
        time,
        status,
        defaultValue,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleInputChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'input-blur') setIsOpen(true);
    };

    const handleMenuOpen = () => {
        setIsOpen(true);
        whenOpen && whenOpen();
    };

    const handleMenuClose = () => setIsOpen(false);

    const restProps = { value: isDisabled ? null : undefined };

    if (value && !isDisabled) {
        const selectedOption = options?.find(
            (option) => option.value === value.selectedValue
        );
        restProps.value = selectedOption || null;
    }

    return (
        <div className={`custom-dropdown ${customClass ? customClass : ''}`}>
            {dropDownLabel ? <label>{dropDownLabel}</label> : ''}
            <Select
                options={options || time}
                defaultValue={defaultValue}
                // value={time ? time.find((item) => item.value === value) : null}
                // value={time ? time[0] : null}
                placeholder={placeholder}
                onInputChange={handleInputChange}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                onChange={(selectedOption) => handleOnChange(selectedOption)}
                blurInputOnSelect={true}
                menuIsOpen={isOpen}
                isClearable={false}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                isDisabled={
                    isDisabled ||
                    // targetTime < 0 ||
                    status === 'REJECTED' ||
                    status === 'CANCELED'
                }
                {...restProps}
            />
        </div>
    );
}

DropDownSelector.propTypes = {
    dropDownLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool,
    handleOnChange: PropTypes.func.isRequired,
    value: PropTypes.object,
};

export default DropDownSelector;
