import apiClient from '../client/api-client';

export const getDialoguer = async (language) => {
    const url = 'user/home/dialoguer/get';

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const rejectDialoguer = async (language, id) => {
    const url = `user/home/${id}/reject`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const clearRejectedDialoguers = async (language) => {
    const url = 'user/home/remove-rejected';

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const scheduleCall = async (language, id, data, timezone) => {
    const url = `user/home/${id}/call/schedule`;

    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language, timezone },
    });
};

export const getRequestAppointment = async (language, data) => {
    const url = 'user/appointment/request/list';

    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language },
    });
};

export const verifyRequestAppointment = async (language, data) => {
    const url = `user/appointment/request/${data.id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
        data,
        headers: { 'Accept-Language': language },
    });
};

export const getScheduledAppointment = async (language, data) => {
    const url = 'user/appointment/schedule/list';

    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language },
    });
};

export const viewScheduleCall = async (language, id) => {
    const url = `user/appointment/${id}/schedule/view`;
    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const viewRequestCall = async (language, id) => {
    const url = `user/appointment/${id}/request/view`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const createCall = async (language, id) => {
    const url = `/user/appointment/${id}/call/create`;

    return apiClient({
        url,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const getRescheduleAppointment = async (language, id) => {
    const url = `user/appointment/${id}/reschedule/view`;
    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const reScheduleAppointment = async (language, id, data, timezone) => {
    const url = `user/appointment/${id}/reschedule/update`;
    return apiClient({
        url,
        method: 'PATCH',
        data,
        headers: { 'Accept-Language': language, timezone },
    });
};

export const cancelAppointment = async (language, id) => {
    const url = `user/appointment/${id}/schedule/cancel`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const rateCall = async (language, id, data) => {
    const url = `user/call/${id}/rate`;

    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language },
    });
};

export const getSingleDialoguer = async (language, id) => {
    const url = `user/home/dialoguer/${id}/get`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const getScheduledList = async (language, timezone) => {
    const url = 'user/home/appointment/schedule/list';

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language, timezone },
    });
};
