import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRelativeDateString } from '../../../utils/helpers/format.helpers';
import ChatMessage from './ChatMessage';

const ConversationOfDate = (props) => {
    const { messagesOfDate, date, userId } = props;

    const { i18n, t } = useTranslation();

    const dayMessages = messagesOfDate?.map((element) => {
        return (
            <ChatMessage
                element={element}
                userId={userId}
                key={element.dCreatedAt + element.sText}
            />
        );
    });

    return (
        <>
            <p className='date-wise sm'>
                {getRelativeDateString(date, t, i18n.language.split('-')[0])}
            </p>
            {[...dayMessages]}
        </>
    );
};

ConversationOfDate.propTypes = {
    messagesOfDate: PropTypes.arrayOf(PropTypes.object).isRequired,
    date: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
};

export default ConversationOfDate;
