import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const InterestHeader = (props) => {
    const { selectedItemsCount, handleClear } = props;

    const { t } = useTranslation();
    return (
        <div className='interest-header'>
            <p
                style={{
                    color: selectedItemsCount === 12 ? 'red' : '',
                    fontWeight: selectedItemsCount === 12 ? '600' : '',
                }}
                className='lg'
            >
                {selectedItemsCount}/12
            </p>

            <button
                className='simple-btn error'
                onClick={handleClear}
                disabled={selectedItemsCount === 0}
            >
                {t('auth.clear')}
            </button>
        </div>
    );
};

InterestHeader.propTypes = { selectedItemsCount: PropTypes.number.isRequired };

export default InterestHeader;
