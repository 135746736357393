import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
function BreadcrumbAction(props) {
    const {
        lastPage,
        currentPage,
        btnTitle,
        url,
        lastPageLink,
        onClick,
        backBtn = null,
        handleBack,
        user,
    } = props;

    const isDisabled = user?.totalCallSeconds < 3600;

    return (
        <div className='bread-crumb'>
            <Breadcrumb>
                <Breadcrumb.Item linkAs='div'>
                    <Link to={lastPageLink}> {lastPage}</Link>
                </Breadcrumb.Item>
                {currentPage && (
                    <Breadcrumb.Item active>{currentPage}</Breadcrumb.Item>
                )}
            </Breadcrumb>
            <div className='bread-action'>
                {backBtn ? (
                    <button
                        className='primary-btn outline-btn'
                        onClick={() => handleBack()}
                    >
                        {backBtn}
                    </button>
                ) : (
                    ''
                )}
                {btnTitle && (
                    <>
                        <Link
                            onClick={!isDisabled ? onClick : undefined}
                            to={!isDisabled ? url : undefined}
                            className={`primary-btn ${
                                isDisabled ? 'disable-btn' : ''
                            }`}
                        >
                            {btnTitle}
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
}

export default BreadcrumbAction;
