import apiClient from '../client/api-client';

export const getUserProfile = (language) => {
    const url = 'user/profile';

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const updateProfile = (language, data) => {
    const url = 'user/profile/update';

    return apiClient({
        url,
        data,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const toggleTalkedHoursVisibility = (language) => {
    const url = 'user/profile/talked-hours-visibility/toggle';

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const toolTips = (data) => {
    const url = '/user/profile/tooltips/disable';
    return apiClient({
        url,
        method: 'PATCH',
    });
};
