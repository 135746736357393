import PropTypes from 'prop-types';
import React from 'react';

const FormErrorLabel = (props) => {
    const { message } = props;
    return <div className='form-text text-danger'>{message}</div>;
};

FormErrorLabel.propTypes = {
    message: PropTypes.string,
};

export default FormErrorLabel;
