import { toast } from 'react-toastify';

import Error from '../../assets/images/error.svg';
import Info from '../../assets/images/info.svg';
import Success from '../../assets/images/success-ic.svg';
import Warn from '../../assets/images/warn-ic.svg';

/**
 * @function
 * Custom close icon component for the toast notifications.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.closeToast - Function to trigger toast closure.
 *
 * @returns {JSX.Element} - Rendered component.
 *
 * @example
 * Usage within toast configuration.
 * closeButton: <CustomCloseIcon />
 */
function CustomCloseIcon({ closeToast }) {
    return (
        <div onClick={closeToast} className='custom-close-icon'>
            <img src='/images/icons/close-icon-toaster.svg' alt='' />
        </div>
    );
}

/**
 * @function
 * Determine the icon and className based on notification type.
 *
 * @param {('error' | 'info' | 'warn' | 'success')} type - Type of the notification.
 * @returns {Object} - Object containing the icon and className.
 *
 * @example
 * getIconAndClassName('error');  // Returns { icon: ErrorIcon, className: 'error-bg' }
 */
const getIconAndClassName = (type) => {
    switch (type) {
        case 'error':
            return { icon: Error, className: 'error-bg' };
        case 'info':
            return { icon: Info, className: 'info-bg' };
        case 'warn':
            return { icon: Warn, className: 'warn-bg' };
        case 'success':
            return { icon: Success, className: 'success-bg' };
        default:
            return { icon: null, className: '' };
    }
};

/**
 * @function
 * Display a toast notification with a given type and message.
 *
 * @param {('error' | 'info' | 'warn' | 'success')} type - Type of the notification.
 * @param {string} message - Message content for the toast.
 *
 * @example
 * notify('error', 'An error occurred.');
 */
export const notify = (type, message) => {
    const { icon, className } = getIconAndClassName(type);
    toast.clearWaitingQueue();

    toast(message, {
        position: 'top-right',
        autoClose: type === 'error' ? 8000 : 1500,
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: <CustomCloseIcon />,
        theme: 'light',
        icon: <img src={icon} alt={className} />,
        className,
        toastId: message,
    });
};
