import React, { useContext, useEffect, useState } from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from 'react-router-dom';
import { CommonContext } from '../context/CommonState';
import CookiesPopUp from '../jsx/cookies-poup/CookiesPopUp';
import PageNotFound from '../jsx/pages/PageNotFound';
import CreateAccount from '../jsx/pages/account-setup/CreateAccount';
import ForgotPassword from '../jsx/pages/account-setup/ForgotPassword';
import OtpVerification from '../jsx/pages/account-setup/OtpVerification';
import RequestAccept from '../jsx/pages/account-setup/RequestAccept';
import RequestSent from '../jsx/pages/account-setup/RequestSent';
import ResetPassword from '../jsx/pages/account-setup/ResetPassword';
import SignIn from '../jsx/pages/account-setup/SignIn';
import SignUp from '../jsx/pages/account-setup/SignUp';
import Appointments from '../jsx/pages/appointments';
import AppointmentView from '../jsx/pages/appointments/AppointmentView';
import RescheduleCall from '../jsx/pages/appointments/RescheduleCall';
import RescheduleRequest from '../jsx/pages/appointments/RescheduleRequest';
import VideoCall from '../jsx/pages/appointments/VideoCall';
import CustomCalendar from '../jsx/pages/availability/CustomCalendar';
import EditAvailability from '../jsx/pages/availability/EditAvailability';
import SetAvailability from '../jsx/pages/availability/SetAvailability';
import Chat from '../jsx/pages/chat';
import Certificate from '../jsx/pages/chat/Certificate';
import AboutUs from '../jsx/pages/cms-pages/AboutUs';
import ContactUs from '../jsx/pages/cms-pages/ContactUs';
import CookiesPage from '../jsx/pages/cms-pages/CookiesPage';
import Faq from '../jsx/pages/cms-pages/Faq';
import GlobalAbout from '../jsx/pages/cms-pages/GlobalAbout';
import LegalNotice from '../jsx/pages/cms-pages/LegalNotice';
import PrivacyPolicy from '../jsx/pages/cms-pages/PrivacyPolicy';
import PublicHeader from '../jsx/pages/cms-pages/public/PublicHeader';
import Dashboard from '../jsx/pages/dashboard';
import Home from '../jsx/pages/home/Home';
import ScheduleCall from '../jsx/pages/home/ScheduleCall';
import SeniorHome from '../jsx/pages/home/SeniorHome';
import EditProfile from '../jsx/pages/profile-management/EditProfile';
import Profile from '../jsx/pages/profile-management/Profile';
import Rewards from '../jsx/pages/rewards';
import Accomplished from '../jsx/pages/rewards/Accomplished';
import ManageTarget from '../jsx/pages/rewards/ManageTarget';
import Rules from '../jsx/pages/speak-now/Rules';
import SpeakNow from '../jsx/pages/speak-now/SpeakNow';
import SpinVideoCall from '../jsx/pages/speak-now/SpinVideoCall';
import ScrollToTop from '../utils/ScrollToTop';
import { getUserId } from '../utils/helpers/cookies.helpers';
const ProtectedComponentWrapper = ({ component }) => {
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();

    const allowedPaths = ['/privacy-policy', '/legal-notice', '/about-us'];

    return getUserId() ||
        (searchParams.get('device') === 'mobile' &&
            allowedPaths.includes(pathname)) ? (
        component
    ) : (
        <Navigate to='/sign-in' replace />
    );
};

const AuthComponentWrapper = ({ component }) => {
    const { isCookiesWarnPopup } = useContext(CommonContext);

    return (
        <>
            {getUserId() ? <Navigate to='/' replace /> : component}
            {isCookiesWarnPopup && <CookiesPopUp />}
        </>
    );
};

function DialogaeRoutes() {
    const { pathname } = useLocation();

    const [isUser, setIsUser] = useState(getUserId());

    useEffect(() => {
        setIsUser(getUserId());
    }, [pathname]);

    return (
        <>
            <ScrollToTop />
            <Routes>
                {/* ****************************** */}
                {/* *** Open Routes - Non-Auth *** */}
                {/* ****************************** */}
                {/* <Route
                path='/login'
                element={<AuthComponentWrapper component={<Login />} />}
            /> */}
                <Route
                    path='/sign-in'
                    element={<AuthComponentWrapper component={<SignIn />} />}
                />
                <Route
                    path='/forgot-password'
                    element={
                        <AuthComponentWrapper component={<ForgotPassword />} />
                    }
                />
                <Route
                    path='/reset-password/:token'
                    element={
                        <AuthComponentWrapper component={<ResetPassword />} />
                    }
                />
                <Route
                    path='/sign-up'
                    element={<AuthComponentWrapper component={<SignUp />} />}
                />
                <Route
                    path='/otp-verification'
                    element={
                        <AuthComponentWrapper component={<OtpVerification />} />
                    }
                />
                <Route
                    path='/create-account'
                    element={
                        <AuthComponentWrapper component={<CreateAccount />} />
                    }
                />
                <Route
                    path='/request-sent'
                    element={
                        <AuthComponentWrapper component={<RequestSent />} />
                    }
                />
                {/* ***************************************** */}
                {/* *** Restricted Routes - Auth Required *** */}
                {/* ***************************************** */}
                <Route
                    path='/request-accept'
                    element={
                        <ProtectedComponentWrapper
                            component={<RequestAccept />}
                        />
                    }
                />
                <Route
                    path='/'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Home />} />}
                        />
                    }
                />

                <Route
                    path='/schedule-call/:id'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<ScheduleCall />} />}
                        />
                    }
                />

                {/* <Route
                path='/availability'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Availability />} />}
                    />
                }
            /> */}
                <Route
                    path='/availability'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<CustomCalendar />} />
                            }
                        />
                    }
                />
                <Route
                    path='/appointments/'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Appointments />} />}
                        />
                    }
                />
                <Route
                    path='/appointments/:status'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Appointments />} />}
                        />
                    }
                />
                <Route
                    path='/appointments/:status/:id/view'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<AppointmentView />} />
                            }
                        />
                    }
                />

                <Route
                    path='/appointments/:id/reschedule'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<RescheduleCall />} />
                            }
                        />
                    }
                />

                <Route
                    path='/appointments/:id/reschedule-request'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<RescheduleRequest />} />
                            }
                        />
                    }
                />
                <Route
                    path='/call'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<VideoCall />} />}
                        />
                    }
                />
                <Route
                    path='/availability/set'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<SetAvailability />} />
                            }
                        />
                    }
                />
                <Route
                    path='/availability/edit'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<EditAvailability />} />
                            }
                        />
                    }
                />
                <Route
                    path='/profile'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Profile />} />}
                        />
                    }
                />
                <Route
                    path='/profile/edit'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<EditProfile />} />}
                        />
                    }
                />
                <Route
                    path='/chat'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Chat />} />}
                        />
                    }
                />
                <Route
                    path='/chat/certificate'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Certificate />} />}
                        />
                    }
                />
                <Route
                    path='/speak-now'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<SpeakNow />} />}
                        />
                    }
                />
                <Route
                    path='/dialog-now-call'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<SpinVideoCall />} />}
                        />
                    }
                />
                <Route
                    path='/senior-home'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<SeniorHome />} />}
                        />
                    }
                />
                <Route
                    path='/speak-now/rules'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Rules />} />}
                        />
                    }
                />
                <Route
                    path='/rewards'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Rewards />} />}
                        />
                    }
                />
                <Route
                    path='/rewards/manage-target'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<ManageTarget />} />}
                        />
                    }
                />
                <Route
                    path='/rewards/accomplished'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Accomplished />} />}
                        />
                    }
                />

                {/* <Route
                path='/video-call-demo'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<VideoCallDemo />} />}
                    />
                }
            /> */}
                <Route
                    path='/privacy-policy'
                    element={
                        isUser ? (
                            <ProtectedComponentWrapper
                                component={
                                    <Dashboard screen={<PrivacyPolicy />} />
                                }
                            />
                        ) : (
                            <PublicHeader>
                                <PrivacyPolicy />
                            </PublicHeader>
                        )
                    }
                />
                <Route
                    path='/legal-notice'
                    element={
                        isUser ? (
                            <ProtectedComponentWrapper
                                component={
                                    <Dashboard screen={<LegalNotice />} />
                                }
                            />
                        ) : (
                            <PublicHeader>
                                <LegalNotice />
                            </PublicHeader>
                        )
                    }
                />
                <Route
                    path='/cookies'
                    element={
                        isUser ? (
                            <ProtectedComponentWrapper
                                component={
                                    <Dashboard screen={<CookiesPage />} />
                                }
                            />
                        ) : (
                            <PublicHeader>
                                <CookiesPage />
                            </PublicHeader>
                        )
                    }
                />
                <Route
                    path='/about-us'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<AboutUs />} />}
                        />
                    }
                />
                <Route path='/about-us2' element={<GlobalAbout />} />
                <Route
                    path='/faqs'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<Faq />} />}
                        />
                    }
                />
                <Route
                    path='/contact-us'
                    element={
                        <ProtectedComponentWrapper
                            component={<Dashboard screen={<ContactUs />} />}
                        />
                    }
                />
                <Route
                    path='/calendar'
                    element={
                        <ProtectedComponentWrapper
                            component={
                                <Dashboard screen={<CustomCalendar />} />
                            }
                        />
                    }
                />
                {/* ************************************ */}
                {/* *** Public Routes - Error Routes *** */}
                {/* ************************************ */}
                <Route path='/404' element={<PageNotFound />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </>
    );
}

export default DialogaeRoutes;
