import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createCall } from '../../../api/adapters/appointment';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import {
    getFullName,
    getTimeFromDate,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';

function CallCard(props) {
    const { oUser, slot, conversationId, callId } = props;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});

    const { mutate: mutateCreateCall, isLoading } = useMutation({
        mutationFn: (id) => createCall(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/call', { state: { oCallData: response.data } });
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const handleCreateCall = async (callId) => {
        mutateCreateCall(callId);
    };

    const handleViewSchedule = () => {
        navigate(`/appointments/schedule/${callId}/view`, {
            state: { bViewFromHome: true },
        });
    };

    return (
        <div className='call-card '>
            <div className='dpWrapper' onClick={() => handleViewSchedule()}>
                <img
                    src={
                        oUser?.sProfilePicUrl
                            ? oUser?.sProfilePicUrl
                            : '/images/square_im.svg'
                    }
                    alt=''
                />
            </div>
            <h3
                style={{ cursor: 'pointer' }}
                onClick={() => handleViewSchedule()}
            >
                {getFullName(oUser?.oName)}
            </h3>
            <p className='location'>
                <img src='/images/location-ic.svg' alt='' />{' '}
                <span>
                    {oUser?.oCity}, {oUser?.oCountry}
                </span>
            </p>
            <p className='time'>
                <img src='/images/time-ic.svg' alt='' />{' '}
                {t('appointments.time')}:{' '}
                <span>
                    {getTimeFromDate(slot?.dStartTime)} -{' '}
                    {getTimeFromDate(slot?.dEndTime)}
                </span>
            </p>
            <div className='active-btn '>
                <button
                    className='primary-btn outline-btn chat_btn'
                    onClick={() => {
                        navigate('/chat', {
                            state: {
                                conversationId: conversationId,
                            },
                        });
                    }}
                >
                    {t('chat_btn')}
                </button>
                <button
                    className={`primary-btn  call_btn ${
                        moment(slot?.dStartTime)
                            .subtract(2, 'minutes')
                            .isAfter(moment()) ||
                        moment(slot?.dEndTime).isBefore(moment())
                            ? 'disable-btn'
                            : 'green-btn'
                    }`}
                    // disabled={
                    //     moment(slot?.dStartTime)
                    //         .subtract(2, 'minutes')
                    //         .isAfter(moment()) ||
                    //     moment(slot?.dEndTime).isBefore(moment()) ||
                    //     isLoading
                    // }
                    // onClick={() => handleCreateCall(callId)}
                    onClick={(event) => {
                        if (
                            moment(slot?.dStartTime)
                                .subtract(2, 'minutes')
                                .isAfter(moment()) ||
                            moment(slot?.dEndTime).isBefore(moment()) ||
                            isLoading
                        ) {
                            event.preventDefault();
                            // Display the notification here
                            notify('error', t('call_btn_disabled'));
                            // alert('Button is currently disabled.');
                        } else {
                            handleCreateCall(callId);
                        }
                    }}
                >
                    {t('call_btn')}
                </button>
            </div>
        </div>
    );
}

export default CallCard;
