import React from 'react';
import { useParams } from 'react-router-dom';
import RequestCall from './RequestCall';
import ScheduleCall from './ScheduleCall';

const AppointmentView = () => {
    const { status } = useParams();

    return status === 'schedule' ? <ScheduleCall /> : <RequestCall />;
};

export default AppointmentView;
