import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/actions/user';

function HomeInstructions({
    swipeArrow,

    setIntroNext,
    setAvailabilityInstr,
    setDisplayTooltip,
    introStep,
}) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleSkip = () => {
        setDisplayTooltip(false);
        setAvailabilityInstr(false);
        dispatch(updateUser({ bDisplayTooltips: false }));
    };
    return (
        <div
            style={
                introStep === 6 ||
                introStep === 7 ||
                introStep === 8 ||
                introStep === 9
                    ? { background: 'transparent' }
                    : null
            }
            className='home-instr tutorial-screen'
        >
            {swipeArrow && (
                <img
                    className='swipe-arrow'
                    src='/images/Swipe-arrow.svg'
                    alt=''
                />
            )}
            <div className='instr-action'>
                <button
                    className='primary-btn outline-btn-white'
                    onClick={() => handleSkip()}
                >
                    {t('instruction.skip')}
                </button>
                <button className='primary-btn' onClick={() => setIntroNext()}>
                    {t('instruction.next')}
                </button>
            </div>
        </div>
    );
}

export default HomeInstructions;
