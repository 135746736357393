import PropTypes from 'prop-types';
import React from 'react';

function Categories(props) {
    const { title, interestItems, handleSelect, selectedItems, isSelectable } =
        props;

    return (
        <div className={`categories ${!isSelectable ? 'not-selectable' : ''}`}>
            <p className='categories-name'>{title}</p>

            <div className='wrapper-categories'>
                {interestItems?.map((item, i) => {
                    return (
                        <div className='cat-item' key={item._id || i}>
                            {isSelectable && (
                                <input
                                    type='checkbox'
                                    name={item._id}
                                    onChange={handleSelect}
                                    checked={selectedItems.includes(item._id)}
                                    disabled={
                                        !selectedItems.includes(item._id) &&
                                        selectedItems.length === 12
                                    }
                                />
                            )}

                            <div className='item'>
                                {item.sIconUrl && (
                                    <img
                                        className='interest-card-img'
                                        src={item.sIconUrl || ''}
                                        alt=''
                                    />
                                )}
                                <span>{item.sTitle || ''}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

Categories.propTypes = {
    title: PropTypes.string.isRequired,
    interestItems: PropTypes.array.isRequired,
    isSelectable: PropTypes.bool,
    handleSelect: PropTypes.func,
    selectedItems: PropTypes.array,
};

Categories.defaultProps = {
    isSelectable: true,
    handleSelect: () => {},
    selectedItems: [],
};

export default Categories;
