import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCertificate } from '../../../api/adapters/certificate';
import { CommonContext } from '../../../context/CommonState';
import {
    formatDateRange,
    formatTalkedHours,
} from '../../../utils/helpers/format.helpers';

function CertificateCard() {
    const {
        user: userFromProp,
        setChaHistory,
        setMobileLayout,
        isMobile,
        setMobileLayoutUser,
    } = useContext(CommonContext);

    const { t, i18n } = useTranslation();

    const user = useSelector((state) => state.user);

    const { data: viewCertificate } = useQuery({
        queryKey: ['view-certificate'],
        queryFn: () =>
            getCertificate(i18n.language.split('-')[0], user.id).then(
                (res) => res.data
            ),
    });

    return (
        <div className='grey-bg'>
            <div className='certificate-content'>
                {isMobile && (
                    <button
                        className='simple-btn'
                        onClick={() => {
                            setMobileLayoutUser(false);
                            setMobileLayout(false);
                        }}
                    >
                        <img src='/images/back-arrow.svg' alt='' />
                    </button>
                )}
                <img src='/images/certificate-image.svg' alt='' />
                <p className='xsm'>{t('chat.issuance_number')}: N1005</p>
                <h3>
                    {t('chat.from')}{' '}
                    {formatDateRange(
                        viewCertificate?.fromDate,
                        i18n.language.split('-')[0]
                    )}{' '}
                    {t('chat.to')}{' '}
                    {formatDateRange(
                        viewCertificate?.toDate,
                        i18n.language.split('-')[0]
                    )}
                </h3>
                <p className='lg'>
                    <span className='f-600'>
                        {user.firstName +
                            (user.middleName ? ' ' + user.middleName : '') +
                            (user.lastName ? ' ' + user.lastName : '')}
                    </span>{' '}
                    {t('chat.has_successfully')}{' '}
                    {formatTalkedHours(
                        i18n.language.split('-')[0],
                        user?.totalCallSeconds
                    )}{' '}
                    ({user?.totalCalls} {t('calls')}) {t('chat.volunteering')}{' '}
                    <span className='f-600'>
                        {formatDateRange(
                            viewCertificate?.fromDate,
                            i18n.language.split('-')[0]
                        )}{' '}
                        -{' '}
                        {formatDateRange(
                            viewCertificate?.toDate,
                            i18n.language.split('-')[0]
                        )}
                    </span>
                    .
                </p>
            </div>
            <div className='ct-social-ic'>
                <Link to='/chat/certificate'>
                    <img src='/images/ct-ic1.svg' alt='' />
                </Link>
                {/* <a href='/#'>
                    <img src='/images/ct-ic2.svg' alt='' />
                </a>
                <a href='/#'>
                    <img src='/images/ct-ic3.svg' alt='' />
                </a>
                <a href='/#'>
                    <img src='/images/ct-ic4.svg' alt='' />
                </a> */}
            </div>
        </div>
    );
}

export default CertificateCard;
