import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getResidencies, getUniversities } from '../../../api/adapters/city';
import DateSelector from '../mini-components/DateSelector';
import DropDownSelector from '../mini-components/DropDownSelector';
import FormErrorLabel from '../mini-components/FormErrorLabel';
import AccountTitle from './AccountTitle';

function StepTwo(props) {
    const { updateUserDate, formErrors, fieldValidators, registrationData } =
        props;

    const { t, i18n } = useTranslation();

    const genderOptions = [
        { value: 'M', label: t('male') },
        { value: 'F', label: t('female') },
        { value: 'O', label: t('other') },
    ];

    const {
        data: universities,
        isLoading: isUniversitiesLoading,
        isError: isUniversitiesError,
        error: universitiesError,
    } = useQuery({
        queryKey: [
            'universities',
            i18n.language?.split('-')[0],
            registrationData?.city._id,
        ],
        queryFn: () =>
            getUniversities(
                i18n.language?.split('-')[0],
                registrationData?.city._id
            ).then((res) => res.data),

        enabled: registrationData?.accountType === 'S',
        placeholderData: [],
    });

    const {
        data: residencies,
        isLoading: isResidenciesLoading,
        isError: isResidenciesError,
        error: residenciesError,
    } = useQuery({
        queryKey: [
            'residencies',
            i18n.language?.split('-')[0],
            registrationData?.city._id,
        ],
        queryFn: () =>
            getResidencies(
                i18n.language?.split('-')[0],
                registrationData?.city._id
            ).then((res) => res.data),

        enabled: registrationData?.accountType === 'R',
        placeholderData: [],
    });

    const handleGenderChange = (selectedOption) => {
        // setUserData((oldData) => ({
        //     ...oldData,
        //     gender: selectedOption.value,
        // }));

        updateUserDate('gender', selectedOption.value);
        fieldValidators.gender();
    };

    const handleDateChange = (date) => {
        // setUserData((oldData) => ({ ...oldData, dob: date }));

        updateUserDate('dob', date);
        fieldValidators.dob();
    };

    // const handleInputChange = (e) => {
    //     e.preventDefault();

    //     const key = e.target.name;
    //     const value = e.target.value;

    //     setUserData((oldData) => ({ ...oldData, [key]: value }));

    //     updateUserDate(key, value);
    //     fieldValidators[key]();
    // };

    const handleUniversityChange = (selectedOption) => {
        // updateUserDate('university', selectedOption.value);
        updateUserDate('university', {
            sTitle: selectedOption.label,
            _id: selectedOption.value,
        });
        fieldValidators.university();
    };

    const handleResidencyChange = (selectedOption) => {
        // updateUserDate('residency', selectedOption.value);
        updateUserDate('residency', {
            sTitle: selectedOption.label,
            _id: selectedOption.value,
        });
        fieldValidators.residency();
    };

    return (
        <>
            <AccountTitle
                title={t('account_setup.title_profile_setup')}
                desc={t('account_setup.description_profile_setup')}
            />
            <div className='row form-space'>
                <div className='col-12'>
                    <DropDownSelector
                        dropDownLabel={`${t('account_setup.title_gender')}*`}
                        placeholder={
                            registrationData?.gender
                                ? registrationData?.gender === 'M'
                                    ? t('male')
                                    : registrationData?.gender === 'F'
                                    ? t('female')
                                    : t('other')
                                : `${t('account_setup.placeholder_gender')}*`
                        }
                        options={genderOptions}
                        handleOnChange={handleGenderChange}
                    />
                    {formErrors.gender ? (
                        <FormErrorLabel message={formErrors.gender} />
                    ) : null}
                </div>
                <div className='col-12'>
                    <DateSelector
                        label={`${t('account_setup.title_dob')}*`}
                        startYear={1900}
                        endYear={new Date().getFullYear() + 1}
                        handleDateChange={handleDateChange}
                        placeholder={
                            registrationData?.dob
                                ? moment(registrationData?.dob)
                                      .locale(i18n.language?.split('-')[0])
                                      .format('DD MMMM YYYY')
                                : t('account_setup.placeholder_dob')
                        }
                    />
                    {formErrors.dob ? (
                        <FormErrorLabel message={formErrors.dob} />
                    ) : null}
                </div>

                <div className='col-12'>
                    {registrationData?.accountType === 'S' ? (
                        <DropDownSelector
                            dropDownLabel={`${t(
                                'account_setup.title_university'
                            )}*`}
                            placeholder={
                                registrationData?.university?.sTitle
                                    ? registrationData?.university.sTitle
                                    : `${t(
                                          'account_setup.placeholder_university'
                                      )}`
                            }
                            options={
                                // universities?.map((university) => ({
                                //     label: university.sTitle,
                                //     value: university._id,
                                // })) || []
                                universities?.map((university) => {
                                    return {
                                        label: university?.sTitle,
                                        value: university?._id,
                                    };
                                }) || []
                            }
                            isDisabled={
                                isUniversitiesLoading || isUniversitiesError
                            }
                            handleOnChange={handleUniversityChange}
                        />
                    ) : (
                        <DropDownSelector
                            dropDownLabel={`${t(
                                'account_setup.title_residency'
                            )}*`}
                            placeholder={
                                registrationData?.residency?.sTitle
                                    ? registrationData?.residency.sTitle
                                    : `${t(
                                          'account_setup.placeholder_residency'
                                      )}`
                            }
                            options={
                                // residencies?.map((residency) => ({
                                //     label: residency.sTitle,
                                //     value: residency._id,
                                // })) || []
                                residencies?.map((residency) => {
                                    return {
                                        label: residency.sTitle,
                                        value: residency._id,
                                    };
                                }) || []
                            }
                            isDisabled={isResidenciesLoading}
                            handleOnChange={handleResidencyChange}
                        />
                    )}
                    {isUniversitiesError ||
                    isResidenciesError ||
                    formErrors.university ||
                    formErrors.residency ? (
                        <FormErrorLabel
                            message={
                                universitiesError?.message ||
                                residenciesError?.message ||
                                formErrors.university ||
                                formErrors.residency
                            }
                        />
                    ) : null}
                </div>

                <div className='col-12'>
                    <div className='error-msg grey-error'>
                        <img src='/images/icons/error-ic.svg' alt='' />
                        <p className='error'>
                            {t('account_setup.warning_wont_be_able_to_edit')}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StepTwo;
