import React from 'react';
import { Link } from 'react-router-dom';

const PublicHeader = ({ children }) => {
    return (
        <div className='cms-page public-cms'>
            <div className='public-header'>
                <div className='container'>
                    <Link to='/'>
                        <div className='logo'>
                            <img src='/images/icons/sidebar-logo.svg' alt='' />
                        </div>
                    </Link>
                </div>
            </div>
            <div className='container'>{children}</div>
        </div>
    );
};

export default PublicHeader;
