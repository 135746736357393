import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const EngDialogae = () => {
    const { search } = useLocation();
    const { i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <div className='content-wrapper'>
            <div className='tab-logo'>
                <img src='/images/logo-accom.svg' alt='' />
            </div>
            <h2 className='blue-text'>
                What is a Dialogæ volunteering and connection platform?
            </h2>
            <br />
            <p>
                <span className='blue-text'>
                    Dialogæ is more than just a free app
                </span>
                , it creates a volunteering network that builds bridges between
                younger and older generations.{' '}
                <span className='blue-text'>
                    It connects the two most lonely demographics to give them
                    the gift of communication
                </span>
                . The platform lets you match like-minded people,{' '}
                <span className='blue-text'>
                    schedule a 30-minute call, and spark a connection just from
                    your phone
                </span>
                .
            </p>
            <p>
                The name Dialogæ isn't coincidental.{' '}
                <span className='blue-text'>
                    Dialogue is a unique form of communication that relies on
                    listening and sharing
                </span>
                . The sound ‘æ' at the end comes from Latin and emphasizes the
                connection between sounds. Just like back in the day when the
                Latin language connected countries across Europe, I aim to
                connect volunteers and people alike across Spain and the entire
                EU in the future.
            </p>
            <p>
                With every detail, I try to embed a connection. You can see that
                through the name and logo, representing two generations holding
                hands.
            </p>
        </div>
    );
};

export default EngDialogae;
