import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTimeFromDate } from '../../../utils/helpers/format.helpers';
import FancyBox from '../mini-components/FancyBox';
import VoiceNote from './VoiceNote';

const ChatMessage = (props) => {
    const { t } = useTranslation();
    const { element, userId } = props;

    const renderMessage = () => {
        if (element.bIsBlocked) {
            return (
                <div className='sensitive-msg'>
                    {userId === element.iSender ? (
                        <img src='/images/sensitive-ic.svg' alt='' />
                    ) : null}

                    <p className='sm'>{t('chat.sensitive_message')}</p>

                    {userId === element.iRecipient ? (
                        <img src='/images/sensitive-ic.svg' alt='' />
                    ) : null}
                </div>
            );
        } else if (element.eMessageType === 'IMAGE') {
            return (
                <div className='media-msg'>
                    <FancyBox options={{ Carousel: { infinite: false } }}>
                        <a data-fancybox='chat-image' href={element.sContent}>
                            <img src={element.sContent} alt='' />
                        </a>
                    </FancyBox>
                </div>
            );
        } else if (element.eMessageType === 'AUDIO') {
            return (
                <div>
                    <VoiceNote voiceData={element.sContent} />
                </div>
            );
        } else {
            return <p className='sm'>{element.sContent}</p>;
        }
    };

    return (
        <div
            key={element.dCreatedAt + element.sContent}
            className={`user-msg ${
                userId === element.iSender
                    ? 'user-1' // Logged User
                    : 'user-2' // Recipient
            }`}
        >
            {renderMessage()}
            <p className='xsm text-end '>
                {getTimeFromDate(element.dCreatedAt)}
            </p>
        </div>
    );
};

ChatMessage.propTypes = {
    element: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};

export default ChatMessage;
