import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormErrorLabel from './FormErrorLabel';

function MobileInput(props) {
    const { handleMobileChange, country, value, errorMessage } = props;

    const { t } = useTranslation();

    return (
        <div className='mobile-input'>
            <PhoneInput
                country={country}
                enableSearch={true}
                placeholder={t('mobile_placeholder')}
                disableSearchIcon={true}
                inputProps={{
                    name: t('mobile_placeholder'),
                    required: true,
                }}
                onChange={handleMobileChange}
                value={value}
            />
            {errorMessage ? (
                <div className='text-center'>
                    <FormErrorLabel message={errorMessage} />{' '}
                </div>
            ) : null}
        </div>
    );
}

MobileInput.propTypes = {
    country: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    handleMobileChange: PropTypes.func,
    value: PropTypes.string,
};

export default MobileInput;
