import { useQuery } from '@tanstack/react-query';
import moment, { locale } from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import { getCertificate } from '../../../api/adapters/certificate';
import { CommonContext } from '../../../context/CommonState';
import {
    formatDateRange,
    formatTalkedHours,
} from '../../../utils/helpers/format.helpers';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import QRCodeGenerator from './QrCode';
function Certificate() {
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.user);

    const { data: viewCertificate } = useQuery({
        queryKey: ['view-certificate'],
        queryFn: () =>
            getCertificate(i18n.language.split('-')[0]).then((res) => res.data),
    });
    const { setCertificate } = useContext(CommonContext);
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
        setCertificate(false);
    };

    const options = {
        // default is `save`
        // method: 'open',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            orientation: 'landscape',
        },

        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true,
            },
        },
    };

    const getTargetElement = () => document.querySelector('.content-wrapper');

    const downloadPdf = () => generatePDF(getTargetElement, options);

    return (
        <div className='certificate'>
            <BreadcrumbAction
                backBtn={t('back')}
                btnTitle={t('download')}
                lastPage={t('chat.call_history')}
                currentPage={t('certificate')}
                handleBack={handleBack}
                onClick={downloadPdf}
                user={user}
            />
            <div className='content-wrapper'>
                <div className='certificate-wrapper'>
                    <div className='certificate-logo'>
                        <img src='/images/certificate-logo.svg' alt='' />
                    </div>
                    <div className='cert-heading'>
                        <h3>{t('certificateData.title')}</h3>
                    </div>
                    <div className='cert-details'>
                        <p className='first-para'>
                            {/* Mr. Mahesh Daswani, as CEO of DIALOGæ assures and
                            certifies that according to the data base of the
                            company DIALOGæ: */}
                            {t('certificateData.firstPara')} :
                        </p>
                        <p>
                            <span className='f-500'>
                                {user.firstName +
                                    (user.middleName
                                        ? ' ' + user.middleName
                                        : '') +
                                    (user.lastName ? ' ' + user.lastName : '')}
                            </span>{' '}
                            {t('certificateData.is_a')}{' '}
                            {/* {user.accountType === 'S'
                                ? 'Student'
                                : 'Resident of'}{' '} */}
                            {t('certificateData.student_in')}{' '}
                            <span className='acadmy-name'>
                                {user.accountType === 'S'
                                    ? user.university
                                    : user.residency}
                            </span>{' '}
                            {t('certificateData.volunteered')}{' '}
                            <span className='f-500'>
                                {formatTalkedHours(
                                    'en',
                                    user?.totalCallSeconds
                                )}
                            </span>{' '}
                            {t('certificateData.period_spanning')}{' '}
                            <span className='f-500'>
                                {/* {moment(viewCertificate?.fromDate)
                                    .locale('en')
                                    .format('Do MMM')}{' '}
                                to{' '}
                                {moment(viewCertificate?.toDate)
                                    .locale('en')
                                    .format('Do MMM')} */}
                                {formatDateRange(
                                    viewCertificate?.fromDate,
                                    i18n.language.split('-')[0]
                                )}{' '}
                                {t('certificateData.to')}{' '}
                                {formatDateRange(
                                    viewCertificate?.toDate,
                                    i18n.language.split('-')[0]
                                )}
                            </span>
                            .
                        </p>
                        <p>
                            {/* For the record and take the appropriate effects
                            where appropriate, I issue this certificate in Santa
                            Cruz de Tenerife on June 5, 2023 */}
                            {t('certificateData.record_take_appreciate')}{' '}
                            {moment(new Date())
                                .locale(i18n.language.split('-')[0])
                                .format('MMMM DD, YYYY')}
                        </p>
                    </div>
                    <div className='signature-cert'>
                        <div className='qr'>
                            {/* <img src='/images/qr-code.svg' alt='' /> */}
                            <QRCodeGenerator
                                t={t}
                                talkedHour={formatTalkedHours(
                                    locale,
                                    user?.totalCallSeconds
                                )}
                                name={
                                    user.firstName +
                                    (user.middleName
                                        ? ' ' + user.middleName
                                        : '') +
                                    (user.lastName ? ' ' + user.lastName : '')
                                }
                                date={
                                    moment(viewCertificate?.fromDate)
                                        // .locale('en')
                                        .format('Do MMM') +
                                    ' - ' +
                                    moment(viewCertificate?.toDate)
                                        // .locale('en')
                                        .format('Do MMM')
                                }
                            />
                            <p className='sm'>
                                {t('chat.issuance_number')}: N1005
                            </p>
                        </div>
                        <div className='signature'>
                            <img src='/images/signature-logo.svg' alt='' />
                            <p className='sm'>Mahesh Daswani Khiani</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Certificate;
