import { UPDATE_USER } from '../constants/redux-events';

export const initialUserState = {
    id: '',
    firstName: '',
    middleName: '',
    lastName: '',
    accountType: '',
    email: '',
    contactNumber: '',
    gender: '',
    dob: '',
    bio: '',
    profilePicUrl: '',
    interests: [],
    university: '',
    residency: '',
    city: '',
    country: '',
    iCurrentRewardTarget: '',
    lastCall: {
        id: '',
        name: '',
        image: '',
        seconds: '',
    },
    isTalkedHoursVisible: false,
    totalCallSeconds: 0,
    totalCalls: 0,
};

const user = (state = initialUserState, { type, payload }) => {
    return type === UPDATE_USER ? { ...state, ...payload } : state;
};

export default user;
