import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';

function TextArea(props) {
    const {
        label,
        placeholder,
        value,
        charactersCount,
        handleOnChange,
        errorMessage = '',
        chatIcon = false,
        name,
        count = true,
        maxCharacter,
    } = props;
    return (
        <div className='text-area'>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
                <div className='text-area-label'>
                    <Form.Label>
                        {chatIcon && (
                            <img src='/images/chat.svg' alt='chat icon' />
                        )}
                        {label}
                    </Form.Label>
                    {count ? (
                        <p
                            className={`word-counter ${
                                (
                                    maxCharacter
                                        ? charactersCount === 350
                                        : charactersCount === 100
                                )
                                    ? 'error'
                                    : ''
                            }`}
                        >
                            <span>{charactersCount || 0}</span>/
                            <span>{maxCharacter || 100}</span>
                        </p>
                    ) : null}
                </div>
                <Form.Control
                    as='textarea'
                    value={value}
                    placeholder={placeholder}
                    style={{ minHeight: '84px' }}
                    rows={3}
                    onChange={handleOnChange}
                    name={name}
                />
            </Form.Group>
            {errorMessage ? <FormErrorLabel message={errorMessage} /> : null}
        </div>
    );
}

TextArea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    charactersCount: PropTypes.number,
    handleOnChange: PropTypes.func,
    chatIcon: PropTypes.bool,
    errorMessage: PropTypes.string,
    name: PropTypes.string,
    count: PropTypes.bool,
};

export default TextArea;
