import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    cancelAppointment,
    viewScheduleCall,
} from '../../../api/adapters/appointment';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import {
    formatTalkedHours,
    getDateStringWithDay,
    getFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Categories from '../../components/common-component/Categories';
import TitleHeader from '../../components/common-component/TitleHeader';

function RescheduleRequest(props) {
    const [modalShow, setModalShow] = useState(false);
    const { t, i18n } = useTranslation();
    const { interestHeading = false } = props;

    const navigate = useNavigate();
    const { id } = useParams();
    const [reScheduleCallDetails, setReScheduleCallDetails] = useState({
        interestMatched: [],
        city: '',
        country: '',
        scheduleDate: '',
        matchedSlots: '',
        name: '',
        bio: '',
        chatMessage: '',
        profilePic: '',
        appointmentId: '',
        prevSlots: [],
        rescheduleCount: '',
    });
    const [formErrors, setFormErrors] = useState({});

    const {
        data: viewschedulecall,
        refetch: refetchReScheduledAppointments,
        isFetching,
    } = useQuery({
        queryKey: ['view-appointment-schedule-detail', id],
        queryFn: () =>
            viewScheduleCall(i18n.language.split('-')[0], id).then((res) => {
                setReScheduleCallDetails({
                    interestMatched: res?.data?.aInterestMatched,
                    city: res?.data?.sCity,
                    country: res?.data?.sCountry,
                    scheduleDate: moment(res?.data?.matchedSlots?.dStartTime)
                        .startOf('day')
                        .format(),
                    matchedSlots: res?.data?.matchedSlots,
                    name: res?.data?.oName,
                    bio: res?.data?.sBio,
                    chatMessage: res?.data?.sMessage,
                    profilePic: res?.data?.sProfilePicUrl,
                    appointmentId: res?.data?._id,
                    prevSlots: res?.data?.aPreviousSelectedSlots,
                    rescheduleCount: res?.data?.nRescheduleCount,
                    totalCallSeconds: res?.data?.nTotalCallSeconds,
                });

                return res.data;
            }),
        // enabled: false,
    });

    const { mutate: mutateCancelAppointment } = useMutation({
        mutationFn: ({ id }) =>
            cancelAppointment(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            refetchReScheduledAppointments();
            setModalShow(false);
            navigate(-1);
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const matchedSlot = reScheduleCallDetails?.matchedSlots;

    const timeSlots = matchedSlot
        ? [
              {
                  value: matchedSlot?._id || '',
                  label:
                      `${moment(matchedSlot?.dStartTime).format('HH:mm')}` +
                      '-' +
                      `${moment(matchedSlot?.dEndTime).format('HH:mm')}`,
              },
          ]
        : [];

    const prevTimeSlots = (reScheduleCallDetails?.prevSlots || [])
        ?.map((slot, i) => ({
            value: slot?._id || '',
            label:
                `${moment(slot?.dStartTime).format('HH:mm')}` +
                '-' +
                `${moment(slot?.dEndTime).format('HH:mm')}`,
        }))
        .flat();

    const handleBackBtn = () => {
        navigate('/appointments/schedule');
    };

    const handleReschedule = () => {
        navigate(`/appointments/${id}/reschedule`);
    };

    const handleClick = () => {
        mutateCancelAppointment({
            id: reScheduleCallDetails.appointmentId,
        });
    };

    return (
        <>
            <BreadcrumbAction
                lastPageLink='/appointments/schedule'
                lastPage={t('appointments.appointments')}
                currentPage={t('appointments.reschedule_request')}
            />
            <div className='schedule-call content-wrapper'>
                <TitleHeader
                    isBackBtn={handleBackBtn}
                    title={t('appointments.reschedule_request')}
                    rejectBtn={t('appointments.cancel')}
                    btn_title={t('appointments.reschedule')}
                    handleRejectClick={() => {
                        setModalShow(true);
                    }}
                    btnClick={handleReschedule}
                    disabled={moment(
                        reScheduleCallDetails?.matchedSlots?.dStartTime
                    )
                        .subtract(1, 'hour')
                        .isBefore(moment())}
                    rescheduleBtnDisabled={
                        reScheduleCallDetails?.rescheduleCount >= 2
                    }
                />
                {/* {isFetching ? (
                    <Loader innerLoader={true} />
                ) : ( */}
                <div className='schedule-call-data'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='user-dp'>
                                <img
                                    src={`${
                                        reScheduleCallDetails?.profilePic ||
                                        '/images/profile-image-placeholder.svg'
                                    }`}
                                    alt=''
                                />
                            </div>
                            <h2 className='name'>
                                {getFullName(reScheduleCallDetails?.name)}
                            </h2>
                            <ul className='location'>
                                <li>
                                    <img
                                        src='/images/location-ic.svg'
                                        alt='location'
                                    />
                                    <span>
                                        {reScheduleCallDetails?.city},{' '}
                                        {reScheduleCallDetails?.country}
                                    </span>
                                </li>

                                {reScheduleCallDetails?.totalCallSeconds !==
                                    undefined &&
                                reScheduleCallDetails?.totalCallSeconds !==
                                    null ? (
                                    <li>
                                        <img
                                            src='/images/tele-ic.svg'
                                            alt='tele'
                                        />
                                        <span>
                                            {t('talked_for')}{' '}
                                            {formatTalkedHours(
                                                i18n.language.split('-')[0],
                                                reScheduleCallDetails?.totalCallSeconds
                                            )}
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                            {reScheduleCallDetails?.bio && (
                                <div className='bio'>
                                    <p className='lg'>{t('home.bio')}</p>
                                    <p className='bio-content'>
                                        {reScheduleCallDetails?.bio}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='col-md-8'>
                            {interestHeading && (
                                <p className='lg interest-heading'>Interests</p>
                            )}
                            {reScheduleCallDetails?.interestMatched?.length ? (
                                <div className='matched'>
                                    <Categories
                                        // conversation={[...Array(4).keys()]}
                                        interestItems={reScheduleCallDetails?.interestMatched?.map(
                                            (acc, cur) => {
                                                return acc;
                                            },
                                            []
                                        )}
                                        isSelectable={false}
                                        title={t('home.matched_interests')}
                                    />
                                </div>
                            ) : null}
                            <div className='grey-bg'>
                                <h3 className='pt-24'>
                                    {t('home.appointment_details')}
                                </h3>
                                <div className='time-date'>
                                    <div className='date'>
                                        <img src='/images/date-ic.svg' alt='' />
                                        <div>
                                            <p className='small'>
                                                {t('appointments.date')}
                                            </p>
                                            <p className='blue-text'>
                                                {getDateStringWithDay(
                                                    reScheduleCallDetails?.scheduleDate,
                                                    i18n.language.split('-')[0]
                                                )}
                                            </p>
                                            <p
                                                style={{
                                                    textDecoration:
                                                        'line-through',
                                                }}
                                            >
                                                {reScheduleCallDetails?.prevSlots.map(
                                                    (item) => (
                                                        <div>
                                                            {getDateStringWithDay(
                                                                moment(
                                                                    item?.dStartTime
                                                                )
                                                                    .startOf(
                                                                        'day'
                                                                    )
                                                                    .format(),
                                                                i18n.language.split(
                                                                    '-'
                                                                )[0]
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='time'>
                                        <img src='/images/time-ic.svg' alt='' />
                                        <div>
                                            <p className='small'>
                                                {t('appointments.time')}
                                            </p>
                                            <p className='blue-text'>
                                                {timeSlots?.map(
                                                    (slot, index) => (
                                                        <div key={index}>
                                                            {slot.label}
                                                        </div>
                                                    )
                                                )}
                                            </p>
                                            <p
                                                style={{
                                                    textDecoration:
                                                        'line-through',
                                                }}
                                            >
                                                {prevTimeSlots?.map(
                                                    (slot, index) => (
                                                        <div key={index}>
                                                            {slot.label}
                                                        </div>
                                                    )
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {reScheduleCallDetails?.chatMessage && (
                                    <div className='chat-msg'>
                                        <img
                                            src='/images/chat-ic2.svg'
                                            alt=''
                                        />
                                        <div className='chat'>
                                            <span>
                                                {t('appointments.chat_message')}
                                            </span>
                                            <p>
                                                {
                                                    reScheduleCallDetails?.chatMessage
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}

                <ModalPopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modalcontent={
                        <>
                            <img src='/images/icons/warn-ic.svg' alt='' />

                            <h3 className='popup-title'>
                                {t('appointments.cancel_appointment')}
                            </h3>

                            <p className='sm'>{t('appointments.are_you')}</p>
                            <div className='error-msg edit-profile-error justify-content-start'>
                                <img
                                    style={{
                                        maxWidth: '16px',
                                        marginTop: '4px',
                                        lineHeight: '20px',
                                    }}
                                    src='/images/icons/error-ic.svg'
                                    alt='error-ic'
                                />
                                <p
                                    className='error small'
                                    style={{ fontSize: '14px' }}
                                >
                                    {t('appointments.if_you')}
                                </p>
                            </div>
                            <div className='popup-action'>
                                <button
                                    className='primary-btn'
                                    onClick={() => setModalShow(false)}
                                >
                                    {t('appointments.no_dont')}
                                </button>
                                <button
                                    className='simple-btn'
                                    onClick={() => {
                                        handleClick();
                                    }}
                                >
                                    {t('appointments.yes_cancel')}
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
}

export default RescheduleRequest;
