import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    clearRejectedDialoguers,
    getDialoguer,
    rejectDialoguer,
} from '../../../api/adapters/appointment';
import { getAvailability } from '../../../api/adapters/availability';
import {
    formatPageTitle,
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import Loader from '../../components/common-component/Loader';
import HomeAction from './HomeAction';
import Nodata from './Nodata';
import ScheduleCall from './ScheduleCall';
import ScheduleReject from './ScheduleReject';
import SeniorHome from './SeniorHome';

function Home() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const [hasUserSetAvailability, setHasUserSetAvailability] = useState(false);
    const [doesWantToSchedule, setDoesWantToSchedule] = useState(false);

    const {
        data: availabilityData,
        isLoading: isLoadingAvailabilityData,
        error: availabilityError,
    } = useQuery({
        queryKey: ['availabilities', getUserId(), i18n.language.split('-')[0]],
        queryFn: () =>
            getAvailability(
                i18n.language.split('-')[0],
                getUserId(),
                false,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
    });

    const {
        data: dialoguerData,
        isFetching: isFetchingDialoguerData,
        refetch: refetchDialoguerData,
        error: dialoguerDataError,
    } = useQuery({
        queryKey: ['dialoguer', i18n.language.split('-')[0]],
        queryFn: () =>
            getDialoguer(i18n.language.split('-')[0]).then(
                (res) => res.data?.oBestMatch || null
            ),
        enabled: false,
    });

    const {
        mutate: mutateClearRejectedDialoguers,
        isLoading: isMutatingClearRejectedDialoguers,
    } = useMutation({
        mutationFn: () => clearRejectedDialoguers(i18n.language.split('-')[0]),
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => refetchDialoguerData(),
    });

    const {
        mutate: mutateRejectDialoguer,
        isLoading: isMutatingRejectDialoguer,
    } = useMutation({
        mutationFn: (userId) =>
            rejectDialoguer(i18n.language.split('-')[0], userId),
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => refetchDialoguerData(),
    });

    const handleReject = (userId) => {
        mutateRejectDialoguer(userId);
    };

    const handleSchedule = () => {
        setDoesWantToSchedule(true);
    };

    useEffect(() => {
        if (availabilityError) handleQueryError(availabilityError, navigate);
    }, [availabilityError]);

    useEffect(() => {
        if (dialoguerDataError) handleQueryError(dialoguerDataError, navigate);
    }, [dialoguerDataError]);

    useEffect(() => {
        if (!isLoadingAvailabilityData && !availabilityData?.length) {
            setHasUserSetAvailability(false);
        } else {
            setHasUserSetAvailability(true);
        }
    }, [availabilityData, isLoadingAvailabilityData]);

    useEffect(() => {
        if (hasUserSetAvailability) refetchDialoguerData();
    }, [hasUserSetAvailability]);

    useEffect(() => {
        document.title = formatPageTitle(t('dashboard_nav.home'));
        refetchDialoguerData();
    }, [i18n.language]);

    const renderMainContent = () => {
        if (isLoadingAvailabilityData) {
            // Loading - Fetching Availability Data
            return (
                <div className='content-wrapper h-100'>
                    <Loader innerLoader={true} />
                </div>
            );
        } else if (!hasUserSetAvailability) {
            // The user has not set availability
            return (
                <HomeAction
                    title={t('home.please_set')}
                    desc={t('home.preferred_time')}
                    actionBtn={t('home.set_availability')}
                    actionUrl='/availability'
                />
            );
        } else if (user?.accountType === 'R') {
            return <SeniorHome />;
        } else if (isFetchingDialoguerData || isMutatingRejectDialoguer) {
            // Loading - Fetching Dialoguer Data
            return <Nodata />;
        } else if (dialoguerData && !doesWantToSchedule) {
            // Dialoguer Data is available
            return (
                <ScheduleReject
                    dialoguerData={dialoguerData}
                    handleReject={handleReject}
                    handleSchedule={handleSchedule}
                />
            );
        } else if (dialoguerData) {
            navigate(`/schedule-call/${dialoguerData._id}`, {
                state: { bFromCall: false },
            });
        } else {
            // No Dialoguer Data is available
            return (
                <HomeAction
                    title={t('home.searching')}
                    desc={t('home.you_have')}
                    actionBtn={t('home.revise')}
                    actionButtonClick={() => mutateClearRejectedDialoguers()}
                    isActionButtonDisabled={isMutatingClearRejectedDialoguers}
                />
            );
        }
    };

    return (
        <div className='home '>
            {renderMainContent()}

            {/* this component for reject user  */}
            {false && (
                <ScheduleReject
                    dialoguerData={dialoguerData}
                    matched={false}
                    bio={false}
                    interestHeading={true}
                />
            )}

            {/* THIS IS FOR  Call Schedule  */}
            {false && <ScheduleCall dialoguerData={dialoguerData} />}
        </div>
    );
}

export default Home;
