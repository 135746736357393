import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { signIn } from '../../../api/adapters/auth';
import { updateUser } from '../../../redux/actions/user';
import { initialUserState } from '../../../redux/reducers/user';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import {
    setCallToken,
    setToken,
    setUserId,
} from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import { isValidEmail } from '../../../utils/validators/common.validators';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';
import InputField from '../../components/mini-components/InputField';
import LangSelector from '../../components/mini-components/LangSelector';
import LoadingButton from '../../components/mini-components/LoadingButton';
const RightContent = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [authData, setAuthData] = useState({
        email: '',
        password: '',
        rememberMe: false,
    });
    const [formErrors, setFormErrors] = useState({ sEmail: '', sPassword: '' });

    const { mutate: mutateSignIn, isLoading: isMutating } = useMutation({
        mutationFn: (data) => signIn(i18n.language?.split('-')[0], data),
        onSuccess: (response) => {
            if (response.data?.reAuth) {
                notify('info', response.message);
                return navigate('/otp-verification', {
                    state: { email: response.data.reAuth, reAuth: true },
                });
            }
            if (response.data?.eProfileStatus === 0) {
                notify('info', response.message);
                navigate('/request-sent');
            } else {
                notify('success', response.message);

                const { oUserProfile, sCallToken, sToken } = response.data;

                setToken(sToken, authData.rememberMe);
                setCallToken(sCallToken, authData.rememberMe);
                setUserId(oUserProfile._id);

                dispatch(
                    updateUser({
                        id: oUserProfile._id,
                        firstName: oUserProfile.oName.sFirstName,
                        middleName: oUserProfile.oName.sMiddleName,
                        lastName: oUserProfile.oName.sLastName,
                        accountType: oUserProfile.eAccountType,
                        email: oUserProfile.sEmail,
                        contactNumber: oUserProfile.sContactNumber,
                        gender: oUserProfile.eGender,
                        dob: oUserProfile.dDob,
                        bio: oUserProfile.sBio,
                        profilePicUrl: oUserProfile.sProfilePicUrl,
                        interests: oUserProfile.aInterests,
                        university:
                            oUserProfile.oUniversity?.sTitle || undefined,
                        residency: oUserProfile.oResidency?.sTitle || undefined,
                        city: oUserProfile.oCity.sTitle,
                        country: oUserProfile.oCountry.sTitle,
                        iCurrentRewardTarget: oUserProfile.iCurrentRewardTarget,
                        isTalkedHoursVisible:
                            oUserProfile.bIsTalkedHoursVisible ?? false,
                        totalCallSeconds: oUserProfile.nTotalCallSeconds,
                        totalCalls: oUserProfile.nTotalCalls,
                        bDisplayTooltips: oUserProfile.bDisplayTooltips,
                    })
                );

                if (response.data.oUserProfile.bIsFirstVisit === true) {
                    navigate('/request-accept');
                } else navigate('/');
            }
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    useEffect(() => {
        dispatch(updateUser({ ...initialUserState }));
    }, []);

    const handleInputChange = (e) => {
        e.preventDefault();

        setAuthData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSignIn = (e) => {
        e.preventDefault();

        const errors = formErrors;

        if (!authData.email.trim())
            errors.sEmail = t('sign_up.error_enter_email');
        else if (!isValidEmail(authData?.email.trim()))
            errors.sEmail = t('sign_up.error_invalid_email');
        else errors.sEmail = '';

        if (!authData.password.trim())
            errors.sPassword = t('sign_up.error_enter_password');
        else {
            errors.sPassword = '';
        }

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;

        mutateSignIn({
            sEmail: authData.email,
            sPassword: authData.password,
            bRememberMe: authData.rememberMe,
        });
    };

    useEffect(() => {
        document.title = formatPageTitle(t('account_setup.sign_in'));
    }, [i18n.language]);

    return (
        <div className='right-screen'>
            <div className='language-selector'>
                <LangSelector i18n={i18n} />

                <div className='account-setup-wrapper'>
                    <h1 className='text-center'>
                        {t('account_setup.sign_in')}
                    </h1>
                    <Form className='login-form'>
                        <p className='text-center'>
                            {t('account_setup.enter_your')}
                        </p>
                        <div className='row form-space'>
                            <div className='col-12'>
                                <InputField
                                    label={t('field_name.email_id')}
                                    placeholder={t(
                                        'field_name.email_id_placeholder'
                                    )}
                                    type='email'
                                    name='email'
                                    handleOnChange={handleInputChange}
                                    value={authData.email}
                                    errorMsg={formErrors.sEmail}
                                />
                            </div>
                            <div className='col-12'>
                                <InputField
                                    label={t('field_name.password')}
                                    placeholder={t(
                                        'field_name.password_placeholder'
                                    )}
                                    type='password'
                                    name='password'
                                    handleOnChange={handleInputChange}
                                    value={authData.password}
                                    errorMsg={formErrors.sPassword}
                                />
                                <div className='text-end pt-2'>
                                    <p className='xsm'>
                                        <Link to='/forgot-password'>
                                            {t('account_setup.forgot_password')}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Form.Check
                                type={'checkbox'}
                                label={t('remind_password')}
                                className='check-box'
                                id='remindPasswordCheckbox'
                                htmlFor='remindPasswordCheckbox'
                                disabled={isMutating}
                                onChange={(e) =>
                                    setAuthData((prevData) => ({
                                        ...prevData,
                                        rememberMe: e.target.checked,
                                    }))
                                }
                            />

                            <div className='action-btn'>
                                <button
                                    className='primary-btn w-100'
                                    onClick={handleSignIn}
                                    disabled={isMutating}
                                >
                                    {isMutating ? (
                                        <LoadingButton color='White' />
                                    ) : (
                                        t('account_setup.sign_in_btn')
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                    <div className='form-footer'>
                        <p className='sm'>
                            {t('account_setup.have_an')}{' '}
                            <Link to='/sign-up'>
                                {t('account_setup.create_account')}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

function SignIn() {
    return (
        <div className='account-setup'>
            <LeftTextAnimation />

            <RightContent />
        </div>
    );
}

export default SignIn;
