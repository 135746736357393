import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { updateLanguage } from '../../../api/adapters/auth';
import { LANGUAGE_OPTIONS } from '../../../configs/common.config';

const CustomDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <img className='arrow' src='/images/icons/dropdown.svg' alt='' />
    </components.DropdownIndicator>
);

const CustomOption = ({ innerProps, value, label, data }, selectedOption) => (
    <div
        className={`${selectedOption.label === label && 'active'}`}
        {...innerProps}
    >
        <img src={data.icon} alt={label} className='custom-icon' />
        {label}
    </div>
);

const formatOptionLabel = ({ label, value, icon }, { isSelected }) => (
    <div
        className={`custom-option ${isSelected ? 'active' : ''}`}
        style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
    >
        <img src={icon} alt={label} className='custom-icon' />
        {value}
    </div>
);

function LangSelector(props) {
    const { i18n } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState();

    const changeLanguage = (lng) => i18n.changeLanguage(lng);

    const handleInputChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'input-blur') setIsOpen(true);
    };

    const handleMenuOpen = () => setIsOpen(true);
    const handleMenuClose = () => setIsOpen(false);

    const handleChange = (selectedOption) => {
        changeLanguage(selectedOption.value.toLowerCase());
    };

    const { mutate: mutateLanguage } = useMutation({
        mutationFn: () => updateLanguage(selectedOption.value.toLowerCase()),
    });

    useEffect(() => {
        setSelectedOption(
            LANGUAGE_OPTIONS.find(
                (languageOption) =>
                    languageOption.value.toLowerCase() ===
                    i18n.language?.split('-')[0]
            )
        );
    }, [i18n.language]);

    useEffect(() => {
        if (selectedOption) {
            mutateLanguage();
        }
    }, [selectedOption]);

    return (
        <div className='custom-dropdown language-selector'>
            <Select
                options={LANGUAGE_OPTIONS}
                value={selectedOption}
                onInputChange={handleInputChange}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                onChange={handleChange}
                blurInputOnSelect={true}
                menuIsOpen={isOpen}
                isSearchable={false}
                isClearable={false}
                formatOptionLabel={formatOptionLabel}
                components={{
                    DropdownIndicator: CustomDropdownIndicator,
                    Option: (test) => CustomOption(test, selectedOption),
                }}
            />
        </div>
    );
}

export default LangSelector;
