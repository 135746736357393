import QRCode from 'qrcode.react';
import React from 'react';

function QRCodeGenerator(props) {
    const { talkedHour, name, date, t } = props;

    return (
        <div>
            <QRCode
                id='qr-code'
                value={`${t('contact_us.name')}: ${name}\n${t(
                    'talked_hours'
                )}: ${talkedHour} \n${t('date_range')}: ${date}`}
            />
        </div>
    );
}

export default QRCodeGenerator;
