import React from 'react';

function AccountTitle(props) {
    const { title, desc } = props;

    return (
        <div className='account-title'>
            <h1 className='pb-0'>{title}</h1>
            <p>{desc}</p>
        </div>
    );
}

export default AccountTitle;
