import React from 'react';
import EsPrivacyPolicy from './PrivacyPolicyLangComponents/EsPrivacyPolicy';
function PrivacyPolicy(props) {
    return (
        <div className='cms-page'>
            <EsPrivacyPolicy />
        </div>
    );
}

export default PrivacyPolicy;
