import { useMutation } from '@tanstack/react-query';
import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { feedback } from '../../../api/adapters/feedback';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';
import InputField from '../../components/mini-components/InputField';
import MobileInput from '../../components/mini-components/MobileInput';
import TextArea from '../../components/mini-components/TextArea';
import LegalNotice from './LegalNotice';

function ContactUs() {
    const { t, i18n } = useTranslation();
    const phoneUtil = PhoneNumberUtil.getInstance();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const [feedbackData, setFeedbackData] = useState({
        country: {
            name: 'Spain',
            dialCode: '34',
            countryCode: 'es',
            format: '+.. ... ... ...',
        },
        contactNumber: '+34',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        contactNumber: '',
        message: '',
    });

    const user = useSelector((state) => state.user);

    const { mutate: mutateSendFeedback, isLoading: isMutating } = useMutation({
        mutationFn: (data) => feedback(i18n.language?.split('-')[0], data),
        onSuccess: (response) => {
            notify('success', response.message);
            setFeedbackData({
                country: {
                    name: 'Spain',
                    dialCode: '34',
                    countryCode: 'es',
                    format: '+.. ... ... ...',
                },
                contactNumber: '+34',
                message: '',
            });
            setIsChecked(false);
        },
        onError: (error) => handleApiError(error, t, setFormErrors, navigate),
    });

    const handleInputChange = (e) => {
        e.preventDefault();
        if (e.target.value.length > 350) return;

        setFeedbackData((prevFeedbackData) => ({
            ...prevFeedbackData,
            message: e.target.value,
        }));
    };

    const handleFeedbackContactNumber = (
        _value,
        country,
        _e,
        formattedValue
    ) => {
        setFeedbackData((prevFeedbackData) => ({
            ...prevFeedbackData,
            country,
            contactNumber: formattedValue,
        }));
    };

    const handleSubmit = () => {
        const errors = { ...formErrors };

        if (!feedbackData.message) {
            errors.message = t('contact_us.error_message_required');
        } else errors.message = '';

        if (
            feedbackData.contactNumber.replace(
                `+${feedbackData.country.dialCode}`,
                ''
            ).length
        ) {
            try {
                const number = phoneUtil.parseAndKeepRawInput(
                    feedbackData.contactNumber,
                    feedbackData.country.countryCode
                );

                if (!phoneUtil.isValidNumber(number)) {
                    errors.contactNumber = t(
                        'report_user.error_invalid_mobile_number'
                    );
                } else {
                    errors.contactNumber = '';
                }
            } catch (error) {
                errors.contactNumber = t(
                    'report_user.error_invalid_mobile_number'
                );
            }
        } else {
            errors.contactNumber = '';
        }

        setFormErrors({ ...errors });
        if (new Set(Object.values(errors)).size !== 1) return;
        if (!isChecked) return notify('error', t('sign_up.error_accept_terms'));

        const data = {
            sFeedback: feedbackData.message,
        };
        if (
            feedbackData.contactNumber.replace(
                `+${feedbackData.country.dialCode}`,
                ''
            ).length
        ) {
            data.sPhoneNumber = feedbackData.contactNumber;
            data.sCountryCode = feedbackData?.country?.countryCode;
        }

        mutateSendFeedback(data);
    };

    const handleLegalPolicy = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        <>
            <div className='cms-page contact-us'>
                <BreadcrumbAction lastPage={t('contact_us.contact')} />
                <div className='content-wrapper'>
                    <div className='grey-bg'>
                        <h3 className='blue-text'>{t('contact_us.share')}</h3>
                        <div className='row form-space'>
                            <div className='col-12'>
                                <InputField
                                    label={t('contact_us.name') + '*'}
                                    placeholder={t('contact_us.enter_your')}
                                    value={
                                        user.firstName +
                                        (user.middleName
                                            ? ' ' + user.middleName
                                            : '') +
                                        (user.lastName
                                            ? ' ' + user.lastName
                                            : '')
                                    }
                                    disabled={true}
                                />
                            </div>
                            <div className='col-12'>
                                <Form.Label>{t('mobile_label')}</Form.Label>
                                <MobileInput
                                    country={feedbackData?.country?.countryCode}
                                    value={feedbackData?.contactNumber}
                                    handleMobileChange={
                                        handleFeedbackContactNumber
                                    }
                                />
                                {formErrors.contactNumber ? (
                                    <FormErrorLabel
                                        message={formErrors.contactNumber}
                                    />
                                ) : null}
                            </div>
                            <div className='col-12'>
                                <TextArea
                                    label={t('contact_us.message') + '*'}
                                    placeholder={t('contact_us.enter_your_msg')}
                                    handleOnChange={handleInputChange}
                                    name='message'
                                    value={feedbackData.message}
                                    count={true}
                                    maxCharacter={350}
                                    charactersCount={
                                        feedbackData.message.length
                                    }
                                />
                                {formErrors.message ? (
                                    <FormErrorLabel
                                        message={formErrors.message}
                                    />
                                ) : null}
                            </div>

                            <div className='col-12'>
                                <Form.Check // prettier-ignore
                                    type={'checkbox'}
                                    id={`login-checkbox`}
                                    checked={isChecked}
                                    onChange={handleLegalPolicy}
                                    label={
                                        <>
                                            {t('i_accept')}{' '}
                                            <Link
                                                className='simple-btn blue-text'
                                                // onClick={() =>
                                                //     setShowModal(true)
                                                // }
                                                to={'/legal-notice'}
                                                target='_blank'
                                            >
                                                {' '}
                                                {t(
                                                    'footer_links.terms_conditions'
                                                )}
                                                ,{' '}
                                            </Link>
                                            <Link
                                                className='simple-btn blue-text'
                                                target='_blank'
                                                to={'/privacy-policy'}
                                            >
                                                {t(
                                                    'footer_links.privacy_policy'
                                                )}{' '}
                                            </Link>{' '}
                                            {t('and')}{' '}
                                            <Link
                                                to={'/cookies'}
                                                className='simple-btn blue-text'
                                                target='_blank'
                                            >
                                                {t(
                                                    'footer_links.cookies_title'
                                                )}
                                            </Link>
                                        </>
                                    }
                                    className='check-box'
                                />
                            </div>
                            <div className='col-12  '>
                                <button
                                    className='primary-btn'
                                    onClick={handleSubmit}
                                    disabled={isMutating}
                                >
                                    {isMutating ? (
                                        <Spinner
                                            as='span'
                                            animation='border'
                                            size='sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    ) : (
                                        t('contact_us.submit')
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='contact-details'>
                        <h3 className='blue-text'>{t('contact_us.connect')}</h3>
                        <div className='social-media'>
                            {/* <a
                                href='http://'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/facebook.svg' alt='' />
                            </a> */}
                            <Link
                                // href='http://'
                                to={
                                    'https://www.instagram.com/dialogae/?utm_source=ig_web_button_share_sheet'
                                }
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/instagram.svg' alt='' />
                            </Link>
                            <Link
                                to={
                                    'https://www.linkedin.com/company/dialogae?original_referer=https%3A%2F%2Fwww.google.com%2F'
                                }
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/linkedin.svg' alt='' />
                            </Link>
                            {/* <a
                                href='http://'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/twitter.svg' alt='' />
                            </a> */}
                        </div>
                        <div className='contact-info'>
                            <a
                                href='http://'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/call2.svg' alt='' />
                                <span>+34 649 645 87X</span>
                            </a>
                            <a
                                href='http://'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/contact.svg' alt='' />
                                <span>info@dialogae.com</span>
                            </a>
                            <a
                                href='http://'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/images/city.svg' alt='' />
                                <span>Santa Cruz de Tenerife, Canarias.</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <ModalPopUp
                show={showModal}
                className={'legal-modal'}
                onHide={() => setShowModal(false)}
                modalcontent={
                    <>
                        <LegalNotice />
                    </>
                }
            />
        </>
    );
}

export default ContactUs;
