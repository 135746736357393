import React from 'react';

function Nodata() {
    return (
        <div className='content-wrapper border-wrapper h-100'>
            <div className='full-wrapper'>
                <div className='full-logo-animated'>
                    <div className='animate-logo'>
                        <img
                            className='logo'
                            src='/images/icons/symbol-inner.svg'
                            alt=''
                        />
                        <img
                            className='rotate'
                            src='/images/icons/circle-rotate.svg'
                            alt=''
                        />
                    </div>
                    <img
                        className='logo-title'
                        src='/images/icons/logo-title.svg'
                        alt=''
                    />
                </div>
            </div>
        </div>
    );
}

export default Nodata;
