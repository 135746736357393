import apiClient from '../client/api-client';

export const getAvailability = async (
    language,
    userId,
    getPastAvailability = true,
    timezone = null
) => {
    const url = `user/availability/${userId}/get?getPast=${getPastAvailability}`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language, timezone },
    });
};

export const setAvailability = async (language, data, timezone) => {
    const url = 'user/availability/set';

    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language, timezone },
    });
};

export const deleteSlot = async (language, availabilityId, data) => {
    const url = `user/availability/${availabilityId}/delete-slot`;

    return apiClient({
        url,
        method: 'DELETE',
        data,
        headers: { 'Accept-Language': language },
    });
};

export const deleteAvailability = async (language, data, timezone) => {
    const url = `user/availability/delete`;

    return apiClient({
        url,
        method: 'DELETE',
        data,
        headers: { 'Accept-Language': language, timezone },
    });
};
