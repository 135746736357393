import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnglishAudio from '../../../assets/sounds/English_UK.mp3';
import EsAudio from '../../../assets/sounds/Es About.mp3';

const AudioPlayer = ({ src }) => {
    const { i18n } = useTranslation();
    const [audio] = useState(
        new Audio(i18n.language === 'es' ? EsAudio : EnglishAudio)
    );
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const progressBar = useRef(); // reference to the progress bar
    useEffect(() => {
        audio.addEventListener('loadedmetadata', () => {
            setDuration(audio.duration);
        });

        audio.addEventListener('timeupdate', () => {
            setCurrentTime(audio.currentTime);
        });

        return () => {
            audio.removeEventListener('loadedmetadata', () => {});
            audio.removeEventListener('timeupdate', () => {});
        };
    }, []);

    useEffect(() => {
        audio.volume = 0.9; // Set volume to 90%
    }, [audio]);

    const toggleAudio = () => {
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        return `${hours > 0 ? hours + ':' : ''}${minutes}:${
            seconds < 10 ? '0' : ''
        }${seconds}`;
    };

    const changeCurrentTime = (e) => {
        const newTime =
            (e.nativeEvent.offsetX / progressBar.current.offsetWidth) *
            duration;

        audio.currentTime = newTime;
        setCurrentTime(newTime);
    };

    return (
        <div className='audio-player'>
            <button onClick={toggleAudio} className='simple-btn'>
                {isPlaying ? (
                    <img src='/images/Pause.svg' alt='' />
                ) : (
                    <img src='/images/Play.svg' alt='' />
                )}
            </button>

            <div className='audio-wrapper'>
                <div
                    className='time-line'
                    ref={progressBar}
                    onClick={changeCurrentTime}
                >
                    <div
                        style={{
                            width: `${(currentTime / duration) * 100}%`,
                            height: '100%',
                        }}
                    />
                </div>
                <div className='time-duration'>
                    <div className='time-text'>{formatTime(currentTime)}</div>
                    <div className='time-text'>{formatTime(duration)}</div>
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;
