import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommonContext } from '../../context/CommonState';

const CookiesPopUp = ({ show, setModalShow }) => {
    const { setIsCookiesWarnPopup } = useContext(CommonContext);
    const { t } = useTranslation();
    const okHandler = () => {
        setModalShow(false);
    };
    return (
        <>
            <div className='cookies-warn-wrapper'>
                <div className='warn-header'>
                    <h5> {t('cookies_policy')}</h5>
                    <button onClick={() => setIsCookiesWarnPopup(false)}>
                        X
                    </button>
                </div>
                <p className='text-start'>
                    Esta web únicamente utiliza cookies propias con finalidad
                    técnica, no recaba ni cede datos de carácter personal de
                    usuarios sin su conocimiento. Para obtener más información
                    de nuestra política de cookies pulse{' '}
                    <Link
                        style={{ textDecoration: 'underline' }}
                        to={'/cookies'}
                    >
                        aquí
                    </Link>
                    .
                </p>
            </div>
        </>
    );
};

export default CookiesPopUp;
