import React from 'react';
import '../../../assets/scss/LoadingButton.scss';

const LoadingButton = ({ color }) => {
    return (
        <div className={`loading-dots ${color && 'white'}`}>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
        </div>
    );
};

export default LoadingButton;
