import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function RowAction(props) {
    const { t } = useTranslation();
    const {
        actionBtn = [
            {
                btnTitle: `${t('table_header.edit')}`,
                btnType: 'edit',
            },
        ],
        refetchRequestAppointments,
        status,
        counterEndTime,
        counterStartTime,
    } = props;
    const [counter, setCounter] = useState(null);

    useEffect(() => {
        const calculateInitialCounter = (currentTime, startInSeconds) => {
            const initialDifference = Math.max(startInSeconds - currentTime, 0);
            return Math.floor(initialDifference / 1000); // converting to seconds
        };

        const currentTime = moment();

        if (currentTime >= counterStartTime && currentTime <= counterEndTime) {
            // const startInSeconds = counterStartTime;

            const initialCounter = calculateInitialCounter(
                currentTime,
                counterEndTime
            );

            setCounter(initialCounter);

            const timer = setInterval(() => {
                setCounter((prevCounter) => Math.max(prevCounter - 1, 0));
            }, 1000);

            return () => clearInterval(timer);
        } else if (currentTime < counterStartTime) {
            const startInSeconds = counterStartTime.hours();

            const initialCounter = calculateInitialCounter(
                currentTime,
                startInSeconds
            );

            setCounter(initialCounter);

            const timer = setInterval(() => {
                setCounter((prevCounter) => Math.max(prevCounter - 1, 0));
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [counterStartTime, counterEndTime]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);

        const remainingSeconds = seconds % 60;

        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
            .toString()
            .padStart(2, '0')}`;
    };
    useEffect(() => {
        if (counter === 0) {
            refetchRequestAppointments();
        }
    }, [counter]);

    return (
        <>
            <div className='action-wrapper'>
                <div className='row-action'>
                    {actionBtn.map((item, index) => {
                        return (
                            <button
                                key={index}
                                to={item?.url}
                                disabled={
                                    item?.disabled || formatTime(counter) === 0
                                }
                                onClick={
                                    item.handleOnClick
                                    // ? item.handleOnClick
                                    // : item?.onClick
                                }
                                className={`row-action-btn  ${
                                    item?.disabled ? 'disable-action' : ''
                                }
                    ${
                        (item.btnType === 'edit' && 'edit') ||
                        (item.btnType === 'call' && 'call') ||
                        (item.btnType === 'cancel' && 'cancel') ||
                        (item.btnType === 'chat' && 'chat') ||
                        (item.btnType === 'rescheduleDisable' &&
                            'rescheduleDisable') ||
                        (item.btnType === 'reschedule' && 'reschedule') ||
                        (item.btnType === 'rejected' && 'cancel') ||
                        (item.btnType === 'pending' && 'chat') ||
                        (item.btnType === 'accept' ? 'chat  accept' : '')
                    }
                    `}
                            >
                                <img
                                    src={`${
                                        (item.btnType === 'edit' &&
                                            '/images/icons/edit.svg') ||
                                        (item.btnType === 'call' &&
                                            '/images/call-ic.svg') ||
                                        (item.btnType === 'chat' &&
                                            '/images/chat-ic.svg') ||
                                        (item.btnType === 'cancel' &&
                                            '/images/cancel-ic.svg') ||
                                        (item.btnType === 'rescheduleDisable' &&
                                            '/images/resheduleDisable.svg') ||
                                        (item.btnType === 'reschedule' &&
                                            '/images/reshedule-ic.svg') ||
                                        (item.btnType === 'accept' &&
                                            '/images/right-ic.svg')
                                    }`}
                                    alt=''
                                />
                                {item?.btnTitle}
                            </button>
                        );
                    })}
                    {status === 'PENDING' && counter > 0 ? (
                        <p className='time-left'>
                            {t('left')} {formatTime(counter)}
                        </p>
                    ) : null}
                </div>
                {/* <Link
                    to={
                        isReschedule
                            ? `/appointments/${id}/reschedule-request`
                            : activeKey === 'schedule'
                            ? `/appointments/schedule/${id}/view`
                            : activeKey === 'request'
                            ? `/appointments/request/${id}/view`
                            : ''
                    }
                >
                    <img src='/images/eye.svg' alt='' />
                </Link> */}
            </div>
        </>
    );
}

export default RowAction;
