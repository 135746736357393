import apiClient from '../client/api-client';

export const getDialogaeNowTime = async (language) => {
    const url = 'user/dialogae/time';

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};
