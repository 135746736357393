import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import EngAboutStory from './AboutLangComponents/EngAboutStory';
import EngDialogae from './AboutLangComponents/EngDialogae';
import EngVolunteering from './AboutLangComponents/EngVolunteering';
import EsAboutStory from './AboutLangComponents/EsAboutStory';
import EsDialogae from './AboutLangComponents/EsDialogae';
import EsVolunteering from './AboutLangComponents/EsVolunteering';
function AboutUs() {
    const { t, i18n } = useTranslation();

    const { search } = useLocation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);

    return (
        <div className='cms-page about-us'>
            <div className='custom-tabs'>
                <h3 className='blue-text tabs-heading'>
                    {t('footer_links.about_us')}
                </h3>

                <Tabs
                    defaultActiveKey='story_Why'
                    id='uncontrolled-tab-example'
                    className='tabs-wrapper'
                >
                    <Tab eventKey='story_Why' title={t('aboutTab.about')}>
                        {i18n.language === 'es' && <EsAboutStory />}
                        {i18n.language === 'en' && <EngAboutStory />}
                        {i18n.language === 'de' && <EngAboutStory />}
                    </Tab>
                    <Tab
                        eventKey='volunteering'
                        title={t('aboutTab.volunteering')}
                    >
                        {i18n.language === 'es' && <EsVolunteering />}
                        {i18n.language === 'en' && <EngVolunteering />}
                        {i18n.language === 'de' && <EngVolunteering />}
                    </Tab>
                    <Tab eventKey='dialogae' title='Dialogae'>
                        {i18n.language === 'es' && <EsDialogae />}
                        {i18n.language === 'en' && <EngDialogae />}
                        {i18n.language === 'de' && <EngDialogae />}
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default AboutUs;
