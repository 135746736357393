import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../../utils/helpers/notification.helpers';
import LoadingButton from '../mini-components/LoadingButton';

function TitleHeader(props) {
    // const button = useRef();

    const {
        title,
        btn_title,
        btnClick,
        rejectBtn,
        hideBtn = true,
        isBackBtn,
        handleRejectClick,
        btn2Url,
        isBtnDisabled = false,
        blueTitle = false,
        tutorial = false,
        disabled,
        rescheduleBtnDisabled = false,
        back_btn = true,
        bViewFromHome,
        handleRedirectChat,
        callDisabled,
        handleCreateCall,
    } = props;

    const navigate = useNavigate();

    const style = tutorial ? { zIndex: '5', position: 'relative' } : null;
    const { t } = useTranslation();

    return (
        <div className='title-header'>
            <button className='simple-btn back-arrow-btn' onClick={isBackBtn}>
                {back_btn ? (
                    <img
                        className={!blueTitle ? 'black-arrow' : ''}
                        src='/images/blue-back-arrow.svg'
                        alt=''
                    />
                ) : null}
                <h3 className={`${blueTitle && 'blue-text'}`}>{title}</h3>
            </button>
            <div className='action'>
                {!bViewFromHome ? (
                    rejectBtn && (
                        <button
                            className={`primary-btn red-outline ${
                                disabled ? 'disable-btn' : ''
                            }`}
                            onClick={handleRejectClick}
                            disabled={disabled}
                        >
                            {rejectBtn}
                        </button>
                    )
                ) : (
                    <button
                        className='primary-btn outline-btn chat_btn'
                        onClick={() => {
                            handleRedirectChat();
                        }}
                    >
                        {rejectBtn}
                    </button>
                )}
                {!bViewFromHome ? (
                    hideBtn && (
                        <button
                            className={`primary-btn ${
                                rescheduleBtnDisabled ? 'disable-btn' : ''
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                if (btn2Url) return navigate(btn2Url);
                                btnClick(e);
                            }}
                            disabled={isBtnDisabled || rescheduleBtnDisabled}
                            style={isBtnDisabled ? { ...style } : style}
                        >
                            {isBtnDisabled ? (
                                <LoadingButton color={true} />
                            ) : (
                                btn_title
                            )}
                        </button>
                    )
                ) : (
                    <button
                        className={`primary-btn  call_btn ${
                            callDisabled ? 'disable-btn' : 'green-btn'
                        }`}
                        // disabled={callDisabled}
                        onClick={(e) => {
                            if (callDisabled) {
                                notify('error', t('call_btn_disabled'));
                            } else {
                                handleCreateCall(e);
                            }
                        }}
                    >
                        {btn_title}
                    </button>
                )}
                {/* {hideBtn && (
                    <button
                        className={`primary-btn ${
                            rescheduleBtnDisabled ? 'disable-btn' : ''
                        }`}
                        onClick={(e) => {
                            e.preventDefault();
                            if (btn2Url) return navigate(btn2Url);
                            btnClick(e);
                        }}
                        disabled={isBtnDisabled || rescheduleBtnDisabled}
                        style={isBtnDisabled ? { ...style } : style}
                    >
                        {isBtnDisabled ? (
                            <LoadingButton color={true} />
                        ) : (
                            btn_title
                        )}
                    </button>
                )} */}
                {tutorial ? (
                    <div className='tutorial-screen'>
                        <div className='tutorial home-tut'>
                            <p className='lg'>{t('instruction.call_with')}</p>
                            <button className='primary-btn'>Next</button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

TitleHeader.propTypes = {
    title: PropTypes.string.isRequired,
    btn_title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    btnClick: PropTypes.func,
    isBtnDisabled: PropTypes.bool,
    rejectBtn: PropTypes.string,
    handleRejectClick: PropTypes.func,
    blueTitle: PropTypes.bool,
    btn2Url: PropTypes.string,
};

export default TitleHeader;
