import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function NoTarget() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='no-target'>
            <h3>{t('rewards.no_target')}</h3>
            <img src='/images/icons/logo-vector.svg' alt='' />
            <button
                className='primary-btn w-100'
                onClick={(e) => {
                    e.preventDefault();
                    navigate('manage-target');
                }}
            >
                {t('rewards.manage_btn')}
            </button>
        </div>
    );
}

export default NoTarget;
