import apiClient from '../client/api-client';

export const endCall = async (language, id) => {
    const url = `/user/call/${id}/end`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const acceptCall = async (language, id) => {
    const url = `/user/call/${id}/accept`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const rejectCall = async (language, id) => {
    const url = `/user/call/${id}/reject`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const getCallHistory = async (language) => {
    const url = `/user/call/history`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const getConversationCallHistory = async (language, id) => {
    const url = `/user/conversation/${id}/call/history`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const checkNextAvailability = async (language, id) => {
    const url = `/user/call/${id}/check-availability`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const extendCurrentCall = async (language, id) => {
    const url = `/user/call/${id}/extend`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const conversationBooster = async (language, id) => {
    const url = `/user/call/${id}/popup/get/list`;
    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const spinEndCall = async (language, id) => {
    const url = `/user/call/${id}/end/spin`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};
