import moment from 'moment';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Minus from '../../../assets/images/slot-minus-icon.svg';
import Plus from '../../../assets/images/slot-plus-icon.svg';

function TimeSelector(props) {
    const { t, i18n } = useTranslation();

    const {
        selectedSlots,
        setSelectedSlots,
        selectedDate,
        maxSlots,
        allSlots,
    } = props;

    const handleRemoveTimeSlots = (index) => {
        setSelectedSlots((prev) => {
            const newSelectedSlots = [...prev];
            newSelectedSlots.splice(index, 1);
            return [...newSelectedSlots];
        });
    };

    const handleAddTimeSlots = () => {
        setSelectedSlots((prev) => {
            const newSelectedSlots = [...prev];
            let lastSlotEndTime = moment(
                newSelectedSlots[newSelectedSlots.length - 1].dEndTime
            ).format('H:mm');
            let slotIndex = allSlots.findIndex(
                (slots) => slots.from === lastSlotEndTime
            );
            for (let i = slotIndex; ; i++) {
                if (!allSlots[i].isSelected) {
                    lastSlotEndTime = allSlots[i].from;
                    break;
                }
                if (i === allSlots.length - 1) i = 0;
                if (i === slotIndex - 1) break;
            }
            let [startHour, startMin] = lastSlotEndTime.split(':');
            if (
                selectedDate.setHours(startHour, startMin) <
                new Date().getTime()
            ) {
                const currentDate = new Date();
                currentDate.setMinutes(
                    currentDate.getMinutes() +
                        (30 - (currentDate.getMinutes() % 30))
                );
                lastSlotEndTime = moment(currentDate).format('H:mm');
                slotIndex = allSlots.findIndex(
                    (slots) => slots.from === lastSlotEndTime
                );
                for (let i = slotIndex; i < allSlots.length; i++) {
                    if (!allSlots[i].isSelected) {
                        lastSlotEndTime = allSlots[i].from;
                        break;
                    }
                }
                const [currentHour, currentMin] = lastSlotEndTime.split(':');
                startHour = currentHour;
                startMin = currentMin;
            }
            const newLastSlot = allSlots.find(
                (slots) => slots.from === lastSlotEndTime
            );
            const [endHour, endMin] = newLastSlot.to.split(':');

            newSelectedSlots.push({
                dStartTime: selectedDate.setHours(startHour, startMin),
                dEndTime: selectedDate.setHours(endHour, endMin),
            });
            return [...newSelectedSlots];
        });
    };

    const handleSlotSelect = (slot, index) => {
        const [startHour, startMin] = slot.from.split(':');
        const [endHour, endMin] = slot.to.split(':');
        if (selectedDate.setHours(startHour, startMin) >= new Date().getTime())
            setSelectedSlots((prev) => {
                const newSelectedSlots = [...prev];
                newSelectedSlots[index] = {
                    dStartTime: selectedDate.setHours(startHour, startMin),
                    dEndTime: selectedDate.setHours(endHour, endMin),
                };
                return [...newSelectedSlots];
            });
    };

    return (
        <div className='time-selector-wrapper'>
            <p className='sm'>{t('availability.select_time')}</p>
            {selectedSlots.map((item, index) => {
                return (
                    <div className='time-selector' key={index}>
                        <Dropdown>
                            <Dropdown.Toggle id='dropdown-basic'>
                                {moment(item.dStartTime).format('H:mm')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    {allSlots.map((slot, index2) => {
                                        const [startHour, startMin] =
                                            slot.from.split(':');
                                        return selectedDate?.setHours(
                                            startHour,
                                            startMin
                                        ) < new Date().getTime() ||
                                            slot.isSelected ? null : (
                                            <Dropdown.Item
                                                className={`${
                                                    moment(
                                                        item.dStartTime
                                                    ).format('H:mm') ===
                                                    slot.from
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                as={'li'}
                                                key={index2}
                                                onClick={(e) => {
                                                    handleSlotSelect(
                                                        slot,
                                                        index
                                                    );
                                                }}
                                            >
                                                {slot.from}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span>-</span>
                        <Dropdown>
                            <Dropdown.Toggle id='dropdown-basic' disabled>
                                {moment(item.dEndTime).format('H:mm')}
                            </Dropdown.Toggle>
                        </Dropdown>
                        {selectedSlots?.length > 1 ? (
                            <button
                                className='simple-btn'
                                onClick={(e) => {
                                    handleRemoveTimeSlots(index);
                                }}
                            >
                                <img src={Minus} alt='' />
                            </button>
                        ) : (
                            ''
                        )}
                        {selectedSlots.length - 1 === index &&
                        selectedSlots.length !== maxSlots ? (
                            <button
                                className='simple-btn'
                                onClick={(e) => {
                                    handleAddTimeSlots();
                                }}
                            >
                                <img src={Plus} alt='' />
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default TimeSelector;
