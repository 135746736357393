import {
    CallingState,
    SfuModels,
    SpeakerLayout,
    StreamCall,
    StreamTheme,
    StreamVideo,
    StreamVideoClient,
    useCall,
    useCallStateHooks,
} from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { spinEndCall } from '../../../api/adapters/call';
import UnmuteMic from '../../../assets/images/Unmute-Mic.svg';
import UnmuteVideo from '../../../assets/images/Unmute-video.svg';
import CallEnd from '../../../assets/images/call-end.svg';
import MuteMic from '../../../assets/images/mute-mic.svg';
import MuteVideo from '../../../assets/images/mute-video.svg';
import { updateUser } from '../../../redux/actions/user';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import { getCallToken } from '../../../utils/helpers/cookies.helpers';
import {
    getFullName,
    getUserFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Loader from '../../components/common-component/Loader';
import RulesContent from '../../components/speak-now/RulesContent';

const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY; // the API key can be found in the "Credentials" section

const SpinVideoCall = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null);
    const [seconds, setSeconds] = useState(0);
    const [hasCallStarted, setHasCallStarted] = useState(false);
    const [showRules, setShowRules] = useState(true);

    const [callData, setCallData] = useState();
    const [call, setCall] = useState();
    const [participants, setParticipants] = useState([]);
    const [client, setClient] = useState();

    const location = useLocation();

    const dispatch = useDispatch();

    const { oCallData, isFromHeader } = location.state || {};

    const user = useSelector((state) => state.user);
    const totalCallSeconds = user.totalCallSeconds;

    const intervalRef = useRef(null);

    useEffect(() => {
        if (oCallData) {
            setCallData(oCallData);
            navigate('.', { state: undefined, replace: true });
        }
    }, [oCallData]);

    const { mutate: mutateEndCall } = useMutation({
        mutationFn: () =>
            spinEndCall(i18n.language.split('-')[0], callData?.id),
        onSuccess: (response) => {
            if (response.data.nDuration) {
                const user = {
                    lastCall: {
                        id: response.data.id,
                        name: getFullName(callData.client.oName),
                        image: callData.client.sProfilePicUrl,
                        seconds: response.data.nDuration,
                    },
                    totalCalls: response.data.nTotalCalls,
                    totalCallSeconds:
                        totalCallSeconds + response.data.nDuration,
                };
                dispatch(updateUser(user));
            }

            // navigate('.', { state: undefined, replace: true });
        },
        onError: (error) => handleApiError(error, t, navigate),
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowRules(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!user.id) return;

        // set up the user object
        const oUser = {
            id: user.id,
            name: getUserFullName(user),
            image:
                user.profilePicUrl ||
                'https://getstream.io/random_svg/?' +
                    new URLSearchParams({
                        id: getUserFullName(user),
                        name: getUserFullName(user),
                    }),
        };

        document.title = formatPageTitle(t('call.video_call'));

        const myClient = new StreamVideoClient({
            apiKey,
            user: oUser,
            token: getCallToken(),
            options: {
                logger: (logLevel, msg, extra) => {
                    if (logLevel === 'error') console.log(msg);
                },
            },
        });
        setClient(myClient);

        myClient.on('call.ended', (event) => {
            notify(
                'error',
                t('call.call_ended_by', {
                    name: getFullName(oCallData.client.oName),
                })
            );
            navigate('/speak-now');
            return;
        });

        return () => {
            myClient.off('call.ended');
            myClient.disconnectUser();
            setClient(undefined);
        };
    }, [user]);

    useEffect(() => {
        if (!client || !callData) return;
        const myCall = client.call('1on1', callData.id);

        myCall.join().catch((err) => {
            console.error(`Failed to join the call`, err);
        });

        setCall(myCall);

        return () => {
            if (callData?.id) mutateEndCall();
            if (call)
                call.leave().catch((err) => {
                    console.error(`Failed to leave the call`, err);
                });
            setCall(undefined);
        };
    }, [client]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
            if (callData?.id) mutateEndCall();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!participants?.length) return;

        if (participants?.length >= 2 && !hasCallStarted) {
            setHasCallStarted(true);
        }
        if (participants?.length < 2 && hasCallStarted) {
            setHasCallStarted(false);
        }
    }, [participants]);

    useEffect(() => {
        if (hasCallStarted) {
            setStartTime(Date.now());
            intervalRef.current = setInterval(() => {
                setSeconds(Math.floor((Date.now() - startTime) / 1000));
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
            setStartTime(null);
            setSeconds(0);
        }
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [hasCallStarted, startTime]);

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (showRules)
        return (
            <div className='rules'>
                <BreadcrumbAction
                    lastPage={t('call.video_call')}
                    currentPage={t('speak_now.rules')}
                />
                <RulesContent />
            </div>
        );

    return (
        <>
            <div className='demo-call'>
                <BreadcrumbAction lastPage={t('call.video_call')} />
                <div className='content-wrapper text-center'>
                    {!client || !call ? (
                        <Loader innerLoader={true} />
                    ) : (
                        <StreamVideo client={client}>
                            <StreamTheme>
                                <div className='call-wrapper'>
                                    <div className='boosterChat'>
                                        <div className='timer'>
                                            <p className='sm'>
                                                {' '}
                                                {formatTime(minutes)}:
                                                {formatTime(remainingSeconds)}
                                            </p>
                                        </div>
                                        {/* {user?.accountType === 'S' ? (
                                            <CallToaster
                                                conversationText={
                                                    conversationText
                                                }
                                                setIsCheckToaster={
                                                    setIsCheckToaster
                                                }
                                                isCheckToaster={isCheckToaster}
                                            />
                                        ) : null} */}
                                    </div>

                                    <StreamCall call={call}>
                                        <SpeakerLayout />

                                        <WrappedCallUI
                                            setCall={setCall}
                                            setParticipants={setParticipants}
                                            navigate={navigate}
                                            t={t}
                                            client={client}
                                        />
                                    </StreamCall>
                                </div>
                            </StreamTheme>
                        </StreamVideo>
                    )}
                </div>
            </div>
        </>
    );
};

const WrappedCallUI = ({ setCall, setParticipants, navigate, t, client }) => {
    const call = useCall();

    const {
        useCallCallingState,
        useLocalParticipant,
        useParticipants,
        useCameraState,
        useMicrophoneState,
    } = useCallStateHooks();
    const callingState = useCallCallingState();
    const localParticipant = useLocalParticipant();
    const participants = useParticipants();

    // const {
    //     hasBrowserPermission: hasCameraPermission,
    //     devices: cameraDevices = [],
    // } = useCameraState();

    // const {
    //     hasBrowserPermission: hasMicrophonePermission,
    //     devices: microphoneDevices = [],
    // } = useMicrophoneState();

    const cameraState = useCameraState?.() || {};
    const hasCameraPermission = cameraState.hasBrowserPermission || false;
    const cameraDevices = cameraState.devices || [];

    const microphoneState = useMicrophoneState?.() || {};
    const hasMicrophonePermission =
        microphoneState.hasBrowserPermission || false;
    const microphoneDevices = microphoneState.devices || [];

    useEffect(() => {
        if (!participants.length) return;
        setParticipants(participants);
    }, [participants]);

    const isVideoMute = !localParticipant?.publishedTracks.includes(
        SfuModels.TrackType.VIDEO
    );
    const isAudioMute = !localParticipant?.publishedTracks.includes(
        SfuModels.TrackType.AUDIO
    );

    if (callingState !== CallingState.JOINED) {
        return <Loader innerLoader={true} />;
    }

    const handleVideo = async () => {
        try {
            if (!cameraDevices.length)
                return notify('error', t('call.no_camera'));
            if (!hasCameraPermission)
                return notify('error', t('call.camera_permission'));
            await call.camera.toggle();
        } catch (error) {
            console.log(error);
        }
    };

    const handleAudio = async () => {
        try {
            if (!microphoneDevices.length)
                return notify('error', t('call.no_microphone'));
            if (!hasMicrophonePermission)
                return notify('error', t('call.microphone_permission'));
            await call.microphone.toggle();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='video-controls'>
            <button onClick={handleVideo}>
                {isVideoMute ? (
                    <img src={MuteVideo} alt='' />
                ) : (
                    <img src={UnmuteVideo} alt='' />
                )}
            </button>
            <button
                className='end-call'
                onClick={async () => {
                    client.off('call.ended');
                    client.disconnectUser();
                    await call.endCall();
                    setCall(undefined);
                    navigate(-1);
                }}
            >
                <img src={CallEnd} alt='' />
            </button>

            <button onClick={handleAudio}>
                {isAudioMute ? (
                    <img src={UnmuteMic} alt='' />
                ) : (
                    <img src={MuteMic} alt='' />
                )}
            </button>
        </div>
    );
};

export default SpinVideoCall;
