import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentRewardTarget } from '../../../api/adapters/rewards';
import { handleQueryError } from '../../../utils/helpers/common.helpers';
import { formatTalkedHours } from '../../../utils/helpers/format.helpers';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Loader from '../../components/common-component/Loader';
import Congratulations from '../../components/rewards/Congratulations';
import HoursProgress from '../../components/rewards/HoursProgress';
import NoTarget from '../../components/rewards/NoTarget';
import BalloonsScreen from './BalloonsScreen';

function Rewards() {
    const { t, i18n } = useTranslation();

    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [totalTime, setTotalTime] = useState(0);

    const [redeemableTime, setRedeemableTime] = useState(0);

    const [completedPercentage, setCompletedPercentage] = useState(0);

    useEffect(() => {
        if (user.id && !user.iCurrentRewardTarget) {
            navigate('/rewards/manage-target');
        }
    }, [user]);

    const {
        data: currentTarget,
        isLoading: isLoadingCurrentTarget,
        error: targetError,
    } = useQuery({
        queryKey: [
            'current-reward-target',
            i18n.language.split('-')[0],
            user?.iCurrentRewardTarget,
        ],
        queryFn: () =>
            getCurrentRewardTarget(i18n.language.split('-')[0]).then(
                (res) => res.data
            ),
        enabled: !!user.id,
    });

    useEffect(() => {
        setTotalTime(
            formatTalkedHours(
                i18n.language.split('-')[0],
                currentTarget?.nTotalCallSeconds
            )
        );
        setRedeemableTime(
            formatTalkedHours(
                i18n.language.split('-')[0],
                currentTarget?.nTotalCallSeconds -
                    currentTarget?.nLastRedemptionSeconds
            )
        );
    }, [currentTarget]);

    useEffect(() => {
        if (targetError) handleQueryError(targetError, navigate);
    }, [targetError]);

    useEffect(() => {
        if (completedPercentage === 100) {
            const hitSound = new Audio('/sounds/crowd-cheer.mp3');
            hitSound.play();
        }
    }, [completedPercentage]);
    return (
        <div className='rewards'>
            <BreadcrumbAction lastPage={t('rewards.rewards')} />
            <div
                className={`content-wrapper ${
                    isLoadingCurrentTarget ? 'h-100' : ''
                }`}
            >
                {isLoadingCurrentTarget ? (
                    <Loader innerLoader={true} />
                ) : (
                    <div className='rewards-wrapper'>
                        <div className={`reward-col ${'w-100'}`}>
                            {!user.id ? (
                                <Loader innerLoader={true} />
                            ) : !user.iCurrentRewardTarget ? (
                                <NoTarget />
                            ) : completedPercentage !== 100 ? (
                                <HoursProgress
                                    isLoadingCurrentTarget={
                                        isLoadingCurrentTarget
                                    }
                                    currentTarget={currentTarget}
                                    setCompletedPercentage={
                                        setCompletedPercentage
                                    }
                                    totalTime={totalTime}
                                    completedPercentage={completedPercentage}
                                />
                            ) : (
                                <>
                                    <Congratulations
                                        hours={
                                            currentTarget?.iCurrentRewardTarget
                                                ?.nMinutes
                                        }
                                        id={user.iCurrentRewardTarget}
                                        setCompletedPercentage={
                                            setCompletedPercentage
                                        }
                                    />
                                    <BalloonsScreen />
                                </>
                            )}
                        </div>
                        {/* {true && (
                            <div className='reward-col'>
                                <RightCards
                                    redeemBlue={completedPercentage === 100}
                                    totalTime={totalTime}
                                    redeemableTime={redeemableTime}
                                />
                            </div>
                        )} */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Rewards;
