import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalPopUp(props) {
    const {
        onHide,
        className,
        modalcontent,
        isBackDrop = false,
        isCloseIcon = true,
    } = props;

    const dropdownRef = useRef(null);
    const handleMenuOpen = () => {
        // Scroll the specific div to the bottom when the dropdown opens
        if (dropdownRef.current) {
            dropdownRef.current.scrollTop = dropdownRef.current.scrollHeight;
        }
    };
    return (
        <div className='modal-popup'>
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop={`${isBackDrop ? 'static' : 'dynamic'}`}
                // backdrop={backdrop}
                // keyboard={false}
                scrollable
                dialogClassName={`custom-modal ${className}`}
            >
                <Modal.Body>
                    {isCloseIcon && (
                        <button className='popup-close-btn' onClick={onHide}>
                            <img src='/images/icons/close.svg' alt='' />
                        </button>
                    )}

                    <div className='modal-content-wrapper' ref={dropdownRef}>
                        {modalcontent}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

ModalPopUp.propTypes = {
    onHide: PropTypes.func,
    className: PropTypes.string,
    modalcontent: PropTypes.element.isRequired,
};

export default ModalPopUp;
