import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

function Toaster(props) {
    return (
        <div className='toaster-wrapper'>
            <ToastContainer />
        </div>
    );
}

export default Toaster;
