import React, { useContext, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { CommonContext } from '../../../context/CommonState';
import HomeInstructions from '../../pages/home/HomeInstructions';
import Footer from '../dashboard/Footer';
import Sidebar from '../dashboard/Sidebar';

const UserInstructions = (props) => {
    const { t } = useTranslation();
    const [introStep, setIntroStep] = useState(1);
    const { setAvailabilityInstr, setDisplayTooltip } =
        useContext(CommonContext);

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isDesk = useMediaQuery({ maxWidth: 992 });
    const user = useSelector((state) => state.user);

    return (
        <div className='user-intro-wrap'>
            <div className='dashboard-container'>
                <div className='sidebar'>
                    <Sidebar
                        setIntroStep={setIntroStep}
                        introStep={introStep}
                    />
                </div>
                <div className='screens-wrapper'>
                    <div className='dash-header'>
                        {!isMobile && <h2>Carlos Rodríguez</h2>}
                        {isMobile && (
                            <div className='logo mobile-logo'>
                                <img
                                    src='/images/icons/logo-vector.svg'
                                    alt=''
                                />
                            </div>
                        )}
                        <div className='header-action'>
                            <div className='toggle-wrapper'>
                                {introStep === 3 && (
                                    <div className='profile-intro notification'>
                                        <p>{t('instruction.get_daily')}</p>
                                    </div>
                                )}
                                <button
                                    className={`simple-btn notify-btn ${
                                        introStep === 3 ? 'z-index' : ''
                                    }`}
                                >
                                    <img
                                        src='/images/icons/notify-ic.svg'
                                        alt=''
                                    />
                                    <div className='span'>2</div>
                                </button>
                            </div>

                            <div className='language-wrapper'>
                                <div className='custom-dropdown language-selector'>
                                    <div className=' css-b62m3t-container'>
                                        <span
                                            id='react-select-3-live-region'
                                            className='css-1f43avz-a11yText-A11yText'
                                        ></span>
                                        <span
                                            aria-live='polite'
                                            aria-atomic='false'
                                            aria-relevant='additions text'
                                            className='css-1f43avz-a11yText-A11yText'
                                        ></span>
                                        <div className=' css-13cymwt-control'>
                                            <div className=' css-1fdsijx-ValueContainer'>
                                                <div className=' css-1dimb5e-singleValue'>
                                                    <div
                                                        className='custom-option '
                                                        style={{
                                                            display: 'flex',
                                                            gap: '8px',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src='/images/icons/United-Kingdom-flag.svg'
                                                            alt='English'
                                                            className='custom-icon'
                                                        />
                                                        EN
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=' css-1hb7zxy-IndicatorsContainer'>
                                                <span className=' css-1u9des2-indicatorSeparator'></span>
                                                <div
                                                    className=' css-1xc3v61-indicatorContainer'
                                                    aria-hidden='true'
                                                >
                                                    <img
                                                        className='arrow'
                                                        src='/images/icons/dropdown.svg'
                                                        alt=''
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isMobile && (
                                <div className='toggle-wrapper'>
                                    {introStep === 2 && (
                                        <div className='profile-intro'>
                                            <p>
                                                {t('instruction.access_your')}
                                            </p>
                                        </div>
                                    )}
                                    <Dropdown
                                        className={
                                            introStep === 2 ? 'z-index' : ''
                                        }
                                    >
                                        <Dropdown.Toggle
                                            variant='unset'
                                            id='dropdown-basic'
                                        >
                                            <img
                                                src='/images/icons/profile.png'
                                                alt=''
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                to='/profile'
                                                as={Link}
                                            >
                                                <span>
                                                    <img
                                                        src='/images/icons/profile-ic1.svg'
                                                        alt=''
                                                    />{' '}
                                                    {t('profile_nav.profile')}
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <span>
                                                    <img
                                                        src='/images/icons/profile-ic2.svg'
                                                        alt=''
                                                    />{' '}
                                                    {t(
                                                        'profile_nav.share_with_friends'
                                                    )}
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                            // onClick={() =>
                                            //     setModalShow(true)
                                            // }
                                            >
                                                <span>
                                                    <img
                                                        src='/images/icons/profile-ic3.svg'
                                                        alt=''
                                                    />{' '}
                                                    {t('logout')}
                                                </span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )}
                            {isDesk && (
                                <button
                                    className={`btn-menu`}
                                    type='button'
                                    // onClick={handleButtonClick}
                                >
                                    <i
                                        className='btn-menu__bars'
                                        aria-hidden='true'
                                    ></i>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='screens-container'>
                        {(introStep === 1 ||
                            introStep === 2 ||
                            introStep === 3 ||
                            introStep === 6 ||
                            introStep === 7 ||
                            introStep === 8) && (
                            <div className='content-wrapper h-100'>
                                <div className='full-wrapper'>
                                    <div className='set-slots-from-home'>
                                        <img
                                            src='/images/icons/logo-vector.svg'
                                            alt=''
                                        />
                                        <div>
                                            <h3>{t('home.please_set')}</h3>
                                            <p className='small'>
                                                {t('home.preferred_time')}
                                            </p>
                                        </div>
                                        <div
                                            className={`${
                                                introStep === 1 &&
                                                'active-home-instr'
                                            }`}
                                        >
                                            {introStep === 1 && (
                                                <p>
                                                    {t(
                                                        'instruction.set_availability'
                                                    )}
                                                </p>
                                            )}
                                            <button
                                                to='/availability'
                                                className='primary-btn'
                                            >
                                                {t('home.set_availability')}
                                            </button>
                                        </div>

                                        <HomeInstructions
                                            introStep={introStep}
                                            skipIntro={props.setUserIntro}
                                            setAvailabilityInstr={
                                                setAvailabilityInstr
                                            }
                                            setDisplayTooltip={
                                                setDisplayTooltip
                                            }
                                            setIntroNext={() =>
                                                setIntroStep(
                                                    introStep === 3 &&
                                                        user.accountType === 'R'
                                                        ? introStep + 2
                                                        : introStep === 3 &&
                                                          user.accountType ===
                                                              'S'
                                                        ? introStep + 1
                                                        : introStep + 1
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {introStep === 4 && screen(introStep, setIntroStep, t)}
                        {introStep === 5 && screen(introStep, setIntroStep, t)}
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default UserInstructions;

const screen = (introStep, setIntroStep, t) => {
    return (
        <div className='schedule-call content-wrapper border-wrapper h-100'>
            <div className='title-header'>
                <h3 className='blue-text'>Dialoguer</h3>
                <div className='action'>
                    <button className='primary-btn outline-btn chat_btn red-outline'>
                        {t('home.reject')}
                    </button>

                    <button
                        className={`primary-btn ${
                            introStep === 4 ? 'z-index' : ''
                        }`}
                    >
                        {t('home.schedule_call')}
                    </button>
                    {introStep === 4 && (
                        <div className='tutorial-screen'>
                            <div className='tutorial home-tut'>
                                <p className='lg'>
                                    {t('instruction.call_with')}
                                </p>
                                <button
                                    className='primary-btn'
                                    onClick={() => {
                                        setIntroStep(introStep + 1);
                                    }}
                                >
                                    {t('instruction.next')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='schedule-call-data'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='user-dp'>
                            <img src='/images/user-profile2.jpg' alt='' />
                        </div>
                        <h2 className='name'>Ayoze Santana</h2>
                        <ul className='location'>
                            <li>
                                <img
                                    src='/images/location-ic.svg'
                                    alt='location'
                                />
                                <span>Candelaria</span>
                            </li>
                            <li>
                                <img src='/images/tele-ic.svg' alt='tele' />
                                <span>Talked for 6hr</span>
                            </li>
                        </ul>
                        {/* {bio && ( */}
                        <div className='bio'>
                            <p className='lg'>{t('home.bio')}</p>
                            <p className='bio-content'>
                                I am an introverted person. I love songs and
                                solo trips. I have a limited number of friends
                                and enjoy spending time with them.
                            </p>
                        </div>
                        {/* )} */}
                    </div>
                    <div className='col-md-8'>
                        <div className='unmatched'>
                            <div className='categories'>
                                <p className='categories-name'>
                                    Matched interests
                                </p>

                                <div className='wrapper-categories'>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/video-game-icon.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Video Games</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/musical-note-icon.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Music</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={'/images/icons/dogs.svg'}
                                                alt=''
                                            />
                                            <span>Dogs</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/airplane.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Travelling</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={'/images/icons/coffee.svg'}
                                                alt=''
                                            />
                                            <span>Coffee</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='categories'>
                                <p className='categories-name'>
                                    Conversation topics
                                </p>

                                <div className='wrapper-categories'>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/politics.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Politics</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/Briefcase.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Business</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/holiday.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Holidays</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='categories'>
                                <p className='categories-name'>Activities</p>

                                <div className='wrapper-categories'>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/chatting.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Chatting</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='categories'>
                                <p className='categories-name'>Sports</p>

                                <div className='wrapper-categories'>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={'/images/icons/diving.svg'}
                                                alt=''
                                            />
                                            <span>Scuba Diving</span>
                                        </div>
                                    </div>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={
                                                    '/images/icons/swimming.svg'
                                                }
                                                alt=''
                                            />
                                            <span>Swimming</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='categories'>
                                <p className='categories-name'>Weekend plans</p>

                                <div className='wrapper-categories'>
                                    <div className='cat-item'>
                                        <div className='item'>
                                            <img
                                                src={'/images/icons/family.svg'}
                                                alt=''
                                            />
                                            <span>Family Plan</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
