import React, { createContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

export const CommonContext = createContext();
function CommonState(props) {
    const { children } = props;

    // STATES
    const [space, setSpace] = useState(true);
    const [activeHeader, setActiveHeader] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [user, setUser] = useState(null);
    const [chaHistory, setChaHistory] = useState(null);
    const [mobileLayout, setMobileLayout] = useState(false);
    const [mobileLayoutUser, setMobileLayoutUser] = useState(false);
    const [certificate, setCertificate] = useState(false);
    const [availabilityInstr, setAvailabilityInstr] = useState(false);
    const [inputWidth, setInputWidth] = useState(0);
    const [rewardsInstr, setRewardsInstr] = useState(false);
    const [blob, setBlob] = useState();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [socketCommon, setSocketCommon] = useState(null);
    const [displayTooltip, setDisplayTooltip] = useState(false);
    const [isFormModified, setIsFormModified] = useState(false);
    const [cookiesModal, setCookiesModal] = useState(true);
    const [isCookiesWarnPopup, setIsCookiesWarnPopup] = useState(true);
    const [userData, setUserData] = useState({
        accountType: '',
        country: {
            sTitle: '',
            _id: '',
        },
        city: {
            sTitle: '',
            _id: '',
        },
        firstName: '',
        middleName: '',
        surname: '',
        gender: '',
        dob: '',
        residency: {
            sTitle: '',
            _id: '',
        },
        university: {
            sTitle: '',
            _id: '',
        },
        email: '',
        bio: '',
        interestItems: [],
        contactNumber: '',
        password: '',
        confirmPassword: '',
        profile: '',
    });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalTo, setConfirmModalTo] = useState(null);
    const [directNavigate, setDirectNavigate] = useState(null);
    const [report, setReportPopUp] = useState(false);
    const [reportUserData, setReportUserData] = useState({
        country: {
            name: 'Spain',
            dialCode: '34',
            countryCode: 'es',
            format: '+.. ... ... ...',
        },
        reason: '',
        mobileNumber: '+34',
    });
    const [reportUserErrors, setReportUserErrors] = useState({
        reason: '',
        mobileNumber: '',
    });

    const navigate = useNavigate();

    const confirmNavigation = (to) => {
        const currentRoute = window.location.pathname;
        if (currentRoute === '/profile/edit' && isFormModified) {
            setShowConfirmModal(true);
            setConfirmModalTo(to);
        } else {
            navigate(to);
            setDirectNavigate(to);
            setTimeout(() => {
                setDirectNavigate(null);
            }, 100);
        }
    };

    return (
        <CommonContext.Provider
            value={{
                space,
                setSpace,
                activeHeader,
                setActiveHeader,
                modalShow,
                setModalShow,
                user,
                setUser,
                chaHistory,
                setChaHistory,
                isMobile,
                mobileLayout,
                setMobileLayout,
                mobileLayoutUser,
                setMobileLayoutUser,
                certificate,
                setCertificate,
                availabilityInstr,
                setAvailabilityInstr,
                rewardsInstr,
                setRewardsInstr,
                inputWidth,
                setInputWidth,
                blob,
                setBlob,
                setSocketCommon,
                socketCommon,
                setDisplayTooltip,
                displayTooltip,
                setIsFormModified,
                isFormModified,
                setUserData,
                userData,
                confirmNavigation,
                showConfirmModal,
                setShowConfirmModal,
                confirmModalTo,
                setConfirmModalTo,
                directNavigate,
                cookiesModal,
                setCookiesModal,
                report,
                setReportPopUp,
                reportUserData,
                setReportUserData,
                reportUserErrors,
                setReportUserErrors,
                isCookiesWarnPopup,
                setIsCookiesWarnPopup,
            }}
        >
            {children}
        </CommonContext.Provider>
    );
}

export default CommonState;
