import React from 'react';

function Loader({ innerLoader }) {
    return (
        <div className={`loader ${innerLoader && 'inner-loader'}`}>
            <div className='full-logo-animated'>
                <div className='animate-logo'>
                    <img
                        className='logo'
                        src='/images/icons/symbol-inner.svg'
                        alt=''
                    />
                    <img
                        className='rotate'
                        src='/images/icons/circle-rotate.svg'
                        alt=''
                    />
                </div>
                <img
                    className='logo-title'
                    src='/images/icons/logo-title.svg'
                    alt=''
                />
            </div>
        </div>
    );
}

export default Loader;
