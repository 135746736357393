import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendReward } from '../../../api/adapters/rewards';
import { updateUser } from '../../../redux/actions/user';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import {
    formatTime,
    getExpandedDateString,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import InputField from '../../components/mini-components/InputField';
import LoadingButton from '../../components/mini-components/LoadingButton';
import TextArea from '../../components/mini-components/TextArea';

function Accomplished() {
    const [modalShow, setModalShow] = React.useState(false);
    const { t, i18n } = useTranslation();
    const { state } = useLocation();

    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formErrors, setFormErrors] = useState({});

    const { mutate: sendEmailReward, isLoading: isBtnDisabled } = useMutation({
        mutationFn: () =>
            sendReward(i18n.language.split('-')[0], state?.giftCodeData?.id),
        onSuccess: (response) => {
            notify('success', response.message);
            dispatch(
                updateUser({
                    ...user,
                    iCurrentRewardTarget:
                        response?.data?.oUser?.iCurrentRewardTarget,
                })
            );
            navigate('/rewards/manage-target');
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    return (
        <>
            <div className='accomplished'>
                <BreadcrumbAction
                    lastPage={t('rewards.rewards')}
                    currentPage={t('rewards.accomplished')}
                    lastPageLink='/rewards'
                />
                <div className='content-wrapper'>
                    <div className='content-accom'>
                        <img
                            className='accomp-logo'
                            src='/images/accom-image.svg'
                            alt=''
                        />
                        <h3>
                            {formatTime(state?.giftCodeData?.iRewardMinutes, t)}{' '}
                            {t('rewards.hours_redeem')}
                        </h3>
                        <p className='lg'>
                            {t('rewards.you_can_redeem')}
                            <br />
                            <span className='f-500'>
                                {state?.giftCodeData?.sDescription}
                            </span>
                        </p>
                        <div className='accom-card'>
                            <div>
                                <p>{state?.giftCodeData?.sDescription}</p>
                                <p className='sm' style={{ color: '#808080' }}>
                                    {t('expired')}{' '}
                                    {getExpandedDateString(
                                        state?.giftCodeData?.dExpiryDate,
                                        i18n.language.split('-')[0]
                                    )}
                                </p>
                            </div>
                            <button className='simple-btn'>
                                <img src='/images/icon-button.svg' alt='' />
                            </button>
                        </div>
                    </div>
                    <div className='accom-wrapper'>
                        <img src='/images/logo-accom.svg' alt='' />
                        <div className='action-accom mt-2 '>
                            <button
                                onClick={() => sendEmailReward()}
                                disabled={isBtnDisabled}
                                className='primary-btn'
                            >
                                {isBtnDisabled ? (
                                    <LoadingButton color={true} />
                                ) : (
                                    t('rewards.send_via')
                                )}
                            </button>
                        </div>
                        {
                            <img
                                className='dynamic-img'
                                src={state?.giftCodeData?.sRewardPic}
                                alt=''
                            />
                        }
                    </div>
                </div>
            </div>

            <ModalPopUp
                show={modalShow}
                className='report-modal'
                onHide={() => setModalShow(false)}
                modalcontent={
                    <div className='report-popup'>
                        <img src='/images/accom-popup-img.svg' alt='' />
                        <h4 className='blue-text'>{t('rewards.send_via')}</h4>
                        <p className='sm'>{t('rewards.please_add')}</p>
                        <div className='accom-popup-field'>
                            <InputField label='Email' />
                            <TextArea
                                label={t('rewards.message')}
                                placeholder={t('rewards.enter_message')}
                            />
                        </div>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setModalShow(false)}
                            >
                                {t('rewards.submit')}
                            </button>
                        </div>
                    </div>
                }
            />
        </>
    );
}

export default Accomplished;
