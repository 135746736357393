import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Loader from '../src/jsx/components/common-component/Loader';
import CommonState from './context/CommonState';
import Toaster from './jsx/components/common-component/Toaster';
import store from './redux/store';
import DialogaeRoutes from './routes';
const ProvidersWrapper = ({ children }) => {
    const queryClient = new QueryClient({
        defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 1 } },
    });

    return (
        <BrowserRouter>
            <CommonState>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        {children}
                        {/* <CookiesPopUp /> */}
                        <ReactQueryDevtools initialIsOpen={false} />

                        <Toaster />
                    </QueryClientProvider>
                </Provider>
            </CommonState>
        </BrowserRouter>
    );
};

function App() {
    const { ready: isTranslationReady } = useTranslation();

    if (isTranslationReady) {
        return (
            <ProvidersWrapper>
                <DialogaeRoutes />
            </ProvidersWrapper>
        );
    }
    return <Loader />;
}

export default App;
