import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonContext } from '../../../context/CommonState';
import LoadingButton from '../../components/mini-components/LoadingButton';
import UserInstructions from '../../components/user-instructions/UserInstructions';

function HomeAction(props) {
    const {
        title,
        desc,
        actionBtn,
        actionUrl,
        actionButtonClick,
        isActionButtonDisabled,
    } = props;

    const navigate = useNavigate();

    const [homeInstr, setHomeInstr] = useState(false);
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);
    const { setDisplayTooltip, displayTooltip } = useContext(CommonContext);

    return (
        <div className='content-wrapper h-100'>
            <div className='full-wrapper'>
                <div className='set-slots-from-home'>
                    <img src='/images/icons/logo-vector.svg' alt='' />

                    <div>
                        <h3>{title}</h3>
                        <p className='small'>{desc}</p>
                    </div>

                    {/* add class when instruction is true  */}
                    <div
                        className={`${
                            user?.bDisplayTooltips && 'active-home-instr'
                        }`}
                    >
                        {user?.bDisplayTooltips && (
                            <p>{t('instruction.set_availability')}</p>
                        )}

                        <button
                            to={actionUrl}
                            className='primary-btn'
                            disabled={isActionButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                if (actionUrl) return navigate(actionUrl);
                                actionButtonClick();
                            }}
                        >
                            {isActionButtonDisabled ? (
                                <LoadingButton color={true} />
                            ) : (
                                actionBtn
                            )}
                        </button>
                    </div>
                    {/* show this component when instruction is true  */}
                    {/* {user?.bDisplayTooltips && <HomeInstructions />} */}
                    {displayTooltip === true && (
                        <UserInstructions
                            setUserIntro={() => setDisplayTooltip(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

HomeAction.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    actionBtn: PropTypes.string,
    actionUrl: PropTypes.string,
    actionButtonClick: PropTypes.func,
    isActionButtonDisabled: PropTypes.bool,
};

export default HomeAction;
