import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    verifyRequestAppointment,
    viewRequestCall,
} from '../../../api/adapters/appointment';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import {
    formatTalkedHours,
    getDateStringWithDay,
    getFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Categories from '../../components/common-component/Categories';
import TimeSlots from '../../components/common-component/TimeSlots';
import TitleHeader from '../../components/common-component/TitleHeader';

function RequestCall(props) {
    // const [modalShow, setModalShow] = useState(false);
    const { t, i18n } = useTranslation();
    const { interestHeading = false } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const [formErrors, setFormErrors] = useState({});
    const [modalShow, setModalShow] = useState(false);

    const user = useSelector((state) => state.user);

    const [requestCallDetails, setRequestCallDetails] = useState({
        interestMatched: [],
        city: '',
        country: '',
        scheduleDate: [],
        matchedSlots: [],
        name: '',
        bio: '',
        chatMessage: '',
        profilePic: '',
        status: '',
        appointmentId: '',
        senderId: '',
        nTotalCallSeconds: undefined,
    });

    const [selectedSlots, setSelectedSlots] = useState();

    const { data: requestData, refetch: refetchRequestAppointments } = useQuery(
        {
            queryKey: ['view-appointment-request-detail', id],
            queryFn: () =>
                viewRequestCall(i18n.language.split('-')[0], id).then((res) => {
                    setRequestCallDetails({
                        interestMatched: res?.data?.aMatchedInterests,
                        city: res?.data?.sCity,
                        country: res?.data?.sCountry,
                        scheduleDate: getScheduledDates(
                            res?.data?.matchedSlots
                        ),
                        matchedSlots: res?.data?.matchedSlots,
                        name: res?.data?.oName,
                        bio: res?.data?.sBio,
                        chatMessage: res?.data?.sMessage,
                        profilePic: res?.data?.sProfilePicUrl,
                        status: res?.data?.eStatus,
                        appointmentId: res?.data?._id,
                        senderId: res?.data?.iSenderId,
                        nTotalCallSeconds: res?.data?.nTotalCallSeconds,
                    });

                    return res.data;
                }),
            // enabled: false,
        }
    );

    const getScheduledDates = (slots) => {
        const aDates = slots.map((item) => {
            return moment(item.dStartTime).startOf('day').format();
        });
        const uniqueDates = [...new Set(aDates)];
        return uniqueDates;
    };

    const { mutate: mutateVerifyRequestAppointment } = useMutation({
        mutationFn: (data) =>
            verifyRequestAppointment(i18n.language.split('-')[0], data),
        onSuccess: (response, data) => {
            notify('success', response.message);

            if (data.eStatus === 'ACCEPTED') {
                setModalShow(true);
                setTimeout(() => {
                    setModalShow(false);
                    navigate('/appointments/schedule');
                }, 5000);
            } else {
                navigate(-1);
            }
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    useEffect(() => {
        refetchRequestAppointments();
    }, []);

    const handleBackBtn = async () => {
        navigate(-1);
    };

    const handleClick = () => {
        mutateVerifyRequestAppointment({
            id: requestCallDetails.appointmentId,
            iSelectedSlot: selectedSlots?._id,
            eStatus: 'ACCEPTED',
        });
    };
    const handleRejectClick = () => {
        mutateVerifyRequestAppointment({
            id: requestCallDetails.appointmentId,
            iSelectedSlot: selectedSlots?._id,
            eStatus: 'REJECTED',
        });
    };

    return (
        <>
            <BreadcrumbAction
                // lastPageLink={`/appointments/${params.status}`}
                lastPageLink='/appointments/request'
                lastPage={t('appointments.appointments')}
                currentPage={t('appointments.request')}
            />
            <div className='schedule-call content-wrapper'>
                {requestCallDetails?.senderId !== user.id &&
                (requestCallDetails?.status === 'PENDING' ||
                    requestCallDetails?.status === 'RESCHEDULE_REQUEST') ? (
                    <TitleHeader
                        isBackBtn={handleBackBtn}
                        title={t('appointments.request_call')}
                        rejectBtn={t('appointments.reject')}
                        btn_title={t('appointments.accept')}
                        btnClick={handleClick}
                        handleRejectClick={handleRejectClick}
                    />
                ) : (
                    <div className='title-header'>
                        <button
                            className='simple-btn back-arrow-btn'
                            onClick={handleBackBtn}
                        >
                            <img
                                className='black-arrow'
                                src='/images/blue-back-arrow.svg'
                                alt=''
                            />
                            <h3>{t('appointments.request_call')}</h3>
                        </button>
                    </div>
                )}

                <div className='schedule-call-data'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='user-dp'>
                                <img
                                    src={`${
                                        requestCallDetails?.profilePic ||
                                        '/images/profile-image-placeholder.svg'
                                    }`}
                                    alt=''
                                />
                            </div>
                            <h2 className='name'>
                                {getFullName(requestCallDetails?.name)}
                            </h2>
                            <ul className='location'>
                                <li>
                                    <img
                                        src='/images/location-ic.svg'
                                        alt='location'
                                    />
                                    <span>
                                        {requestCallDetails?.city},{' '}
                                        {requestCallDetails?.country}
                                    </span>
                                </li>
                                {requestCallDetails?.nTotalCallSeconds !==
                                    undefined &&
                                requestCallDetails?.nTotalCallSeconds !==
                                    null ? (
                                    <li>
                                        <img
                                            src='/images/tele-ic.svg'
                                            alt='tele'
                                        />
                                        <span>
                                            {t('talked_for')}{' '}
                                            {formatTalkedHours(
                                                i18n.language.split('-')[0],
                                                requestCallDetails?.nTotalCallSeconds
                                            )}
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                            {requestCallDetails?.bio && (
                                <div className='bio'>
                                    <p className='lg'>{t('home.bio')}</p>
                                    <p className='bio-content'>
                                        {requestCallDetails?.bio}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='col-md-8'>
                            {interestHeading && (
                                <p className='lg interest-heading'>Interests</p>
                            )}
                            {requestCallDetails?.interestMatched?.length ? (
                                <div className='matched'>
                                    <Categories
                                        interestItems={requestCallDetails?.interestMatched?.reduce(
                                            (acc, cur) => {
                                                const categoryInterestItems =
                                                    cur.interestCategory.aInterestItems.map(
                                                        (item) => ({
                                                            sTitle: item.sTitle,
                                                            sIconUrl:
                                                                item.sIconUrl,
                                                        })
                                                    );

                                                acc.push(
                                                    ...categoryInterestItems
                                                );
                                                return acc;
                                            },
                                            []
                                        )}
                                        isSelectable={false}
                                        title={t('home.matched_interests')}
                                    />
                                </div>
                            ) : null}
                            <div className='grey-bg'>
                                <h3 className='pt-24'>
                                    {t('home.book_appointment')}
                                </h3>
                                <div className='time-date white-date-label'>
                                    <div className='date'>
                                        <img src='/images/date-ic.svg' alt='' />
                                        <div>
                                            <p className='small'>
                                                {t('appointments.date')}
                                            </p>
                                            <p>
                                                {requestCallDetails
                                                    ?.scheduleDate?.length > 1
                                                    ? getDateStringWithDay(
                                                          requestCallDetails
                                                              ?.scheduleDate[0],
                                                          i18n.language.split(
                                                              '-'
                                                          )[0]
                                                      ) +
                                                      ' - ' +
                                                      getDateStringWithDay(
                                                          requestCallDetails
                                                              ?.scheduleDate[1],
                                                          i18n.language.split(
                                                              '-'
                                                          )[0]
                                                      )
                                                    : getDateStringWithDay(
                                                          requestCallDetails
                                                              ?.scheduleDate[0],
                                                          i18n.language.split(
                                                              '-'
                                                          )[0]
                                                      )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='time'>
                                    <div>
                                        {(requestCallDetails?.senderId !==
                                            user.id &&
                                            requestCallDetails?.status ===
                                                'PENDING') ||
                                        requestCallDetails?.status ===
                                            'RESCHEDULE_REQUEST' ? (
                                            <>
                                                {/* <p>Wed, 02 Aug</p> */}
                                                <TimeSlots
                                                    slotLabel={t(
                                                        'appointments.select_appointment_time'
                                                    )}
                                                    colorLabel='#666'
                                                    slots={
                                                        requestCallDetails?.matchedSlots
                                                    }
                                                    setSelectedSlots={
                                                        setSelectedSlots
                                                    }
                                                    bIsRadio={true}
                                                />
                                            </>
                                        ) : (
                                            <TimeSlots
                                                slotLabel={t(
                                                    'appointments.past_time'
                                                )}
                                                colorLabel='#666'
                                                slots={
                                                    requestCallDetails?.matchedSlots
                                                }
                                                setSelectedSlots={
                                                    setSelectedSlots
                                                }
                                                isClickable={false}
                                            />
                                        )}
                                    </div>
                                </div>
                                {requestCallDetails?.chatMessage && (
                                    <div className='chat-msg'>
                                        <img
                                            src='/images/chat-ic2.svg'
                                            alt=''
                                        />
                                        <div className='chat'>
                                            <span>
                                                {t('appointments.chat_message')}
                                            </span>
                                            <p>
                                                {
                                                    requestCallDetails?.chatMessage
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <ModalPopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modalcontent={
                        <>
                            <h3 className='popup-title'>
                                {t('appointment_confirmed')}
                            </h3>

                            <img src='/images/icons/popUp-logo.svg' alt='' />

                            <p className='sm'>
                                <b>
                                    {' '}
                                    {t('popup_content.your_appointment')}{' '}
                                    {getFullName(requestCallDetails?.name)}{' '}
                                    {t('popup_content.is_confirmed')}
                                </b>
                                {t('popup_content.reschedule_cancel')}
                                <b>
                                    {t('popup_content.health_of_Dialoguers')}
                                </b>{' '}
                                {t('popup_content.responsibility')}
                            </p>
                        </>
                    }
                />
            </div>
        </>
    );
}

export default RequestCall;
