import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AudioPlayer from '../../../components/common-component/AudioPlayer';
import VideoPlayer from '../../../components/common-component/VideoPlayer';
const EsAboutStory = () => {
    const { search } = useLocation();
    const { i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <div className='content-wrapper'>
            <div className='player-with-heading'>
                <h2 className='blue-text'>Mi historia empieza por el final</h2>
                <AudioPlayer />
            </div>
            <p>
                En 2021, tenía todo lo que un joven recién graduado en finanzas
                podía soñar: me dedicaba a algo que realmente me apasionaba, un
                trabajo en el extranjero en uno de los mayores bancos de Europa,
                tenía una conexión especial con la mujer de mis sueños y la
                oportunidad de viajar por todo el mundo.
            </p>
            <p className='blue-text'>
                Todo era perfecto hasta que dejó de serlo…
            </p>
            <p>
                La vida de mis sueños comenzó a desmoronarse como fichas de
                dominó. Yo no estaba preparado para una relación seria y decidí
                romper el vínculo. Un año después me di cuenta del enorme error
                que había cometido. Intenté recuperarla, pero la puerta ya
                estaba cerrada. Esto me dejó sintiéndome vacío y cuestionándome
                si terminar la relación había sido la decisión correcta. Seguía
                preguntándome: ¿cómo pude dejarla ir…?
            </p>
            <VideoPlayer />
            <p>
                Al mismo tiempo, la carrera de mis sueños resultó no ser tan
                perfecta como imaginaba. Me di cuenta de que el sector
                financiero ya no era mi verdadera vocación, lo que me dejó
                desmotivado y sin una dirección a seguir.
            </p>
            <p>
                A simple vista,{' '}
                <span className='blue-text'>
                    nadie podía ver que todo mi mundo se quebraba. Pero así es
                    la soledad, silenciosa.
                </span>{' '}
                Te hace sentir cansado de intentar cambiar las cosas, sin
                esperanza de que haya alguien o algo para ti.
            </p>
            <p>
                <span className='blue-text'>
                    Tomé una de las decisiones más importantes de mi vida.
                </span>{' '}
                Dejé mi trabajo, hice las maletas y regresé a casa, a Tenerife,
                para reflexionar cuál sería mi siguiente paso. No podía entender
                cómo teniéndolo todo, todas las decisiones que había tomado me
                habían llevado a sentirme más vacío y perdido que nunca.
            </p>
            <p>
                Podrías pensar:{' '}
                <span className='blue-text'>
                    “Estás loco, no hay mejor lugar para vivir que Canarias”.
                </span>
                Pero en mi caso, me sentía más solo que nunca. La gente de mi
                alrededor no comprendía por qué me sentía tan infeliz y tan
                fuera de lugar.{' '}
                <span className='blue-text'>
                    El hecho de estar rodeado de personas y sentirme tan
                    incomprendido acentuó mi soledad.
                </span>
            </p>
            <p>
                Sin embargo, descubrí que{' '}
                <span className='blue-text'>el salvavidas para mi soledad</span>{' '}
                mpre había estado ahí. Encontré aliento en mi antiguo jefe, en
                mis padres, en desconocidos y amigos en el extranjero, que viven
                a miles de kilómetros, pero que aún así, se preocupaban por mí.{' '}
                <span className='blue-text'>
                    Estuvieron ahí para mí, creando un espacio seguro
                </span>{' '}
                Y yo, poco a poco me fui abriendo y sintiéndome mejor.
            </p>
            <p>
                Muchos podrían decir:{' '}
                <span className='blue-text'>
                    “Oye, pero eres tan joven; simplemente sal y conoce gente”.{' '}
                </span>{' '}
                Esta falta de empatía lleva a las personas a sentirse aún más
                solas, ya que la gente no se siente legitimada para pedir ayuda.
            </p>
            <p>
                <span className='blue-text'>La soledad no deseada</span> es un
                problema serio e invisible que{' '}
                <span className='blue-text'>
                    afecta al 13,4 % de la población española
                </span>{' '}
                y a 30 millones de personas en la UE. Este sentimiento tiene
                graves consecuencias en la salud y en el bienestar de las
                personas, llevando a muchas a desarrollar depresión, ansiedad y
                problemas de salud física.
            </p>
            <p>
                Sentía que tenía que hacer algo.{' '}
                <span className='blue-text'>
                    Así que creé un espacio seguro online - Dialogæ.
                </span>{' '}
                El mismo lugar seguro que encontré con mi gente,{' '}
                <span className='blue-text'>
                    ahora está disponible para ti.
                </span>
            </p>
            <p>
                Dialogæ es la primera plataforma de voluntariado digital de este
                tipo, accesible de forma online a través de la página web y la
                aplicación para móviles y tablets.{' '}
                <span className='blue-text'>
                    Dialogæ conecta a estudiantes universitarios con personas en
                    residencias de mayores mediante videollamadas
                </span>{' '}
                y mensajes, de la misma manera que yo conecté con mi gente en
                momentos de necesidad.
            </p>
            <p>
                <strong
                    style={{
                        fontStyle: 'italic',
                        textDecoration: 'underline',
                    }}
                >
                    Mahesh Daswani
                </strong>
            </p>
        </div>
    );
};

export default EsAboutStory;
