import { useQuery } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getFaqs } from '../../../api/adapters/cms-content';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';

function Faq() {
    const { i18n, t } = useTranslation();
    const accordionBodyRefs = useRef([]);

    const { data } = useQuery({
        queryKey: ['faqs', i18n.language.split('-')[0]],
        queryFn: () => getFaqs().then((res) => res.data),
    });

    const handleEntered = (index) => {
        if (accordionBodyRefs.current[index]) {
            const height = accordionBodyRefs.current[index].clientHeight;

            // calculate distance from accordionBodyRefs.current[index] to top of screen
            const distance =
                accordionBodyRefs.current[index].getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            if (height + 20 <= viewportHeight - distance.top) {
                console.log('Accordion.Body is visible');
            } else {
                const screenWrapper =
                    document.querySelector('.screens-wrapper');
                // get screenWrapper how much scroll from top of screen
                const scrollTop =
                    screenWrapper.scrollTop ||
                    document.documentElement.scrollTop;

                // smooth scroll to the top of the screen
                screenWrapper.scrollTo({
                    top: scrollTop + height + 40,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <div className='cms-page'>
            <BreadcrumbAction lastPage={t('footer_links.faqs')} />

            <div className='content-wrapper'>
                <div className='custom-accordion'>
                    <Accordion>
                        {data?.map((item, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                    {item.sQuestion}
                                </Accordion.Header>
                                <Accordion.Body
                                    onEntered={() => handleEntered(index)}
                                >
                                    <p
                                        ref={(ref) =>
                                            (accordionBodyRefs.current[index] =
                                                ref)
                                        }
                                    >
                                        {item.sAnswer}
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default Faq;
