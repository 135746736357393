import React from 'react';
import AboutUs from './AboutUs';

function GlobalAbout() {
    return (
        <div className='global-about-us'>
            <div className='header-about'>
                <div className='container'>
                    <img src='images/icons/sidebar-logo.svg' alt='' />
                </div>
            </div>
            <div className='container'>
                <AboutUs />
            </div>
        </div>
    );
}

export default GlobalAbout;
