import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
    resetPassword,
    verifyResetPasswordToken,
} from '../../../api/adapters/auth';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';
import Loader from '../../components/common-component/Loader';
import InputField from '../../components/mini-components/InputField';
import LangSelector from '../../components/mini-components/LangSelector';
import LoadingButton from '../../components/mini-components/LoadingButton';

function RightContent() {
    const { i18n, t } = useTranslation();

    const { token } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });
    const [formErrors, setFormErrors] = useState({
        sPassword: '',
        sConfirmPassword: '',
    });

    const { isLoading: isVerifyingToken, error: tokenVerificationError } =
        useQuery({
            queryKey: [
                'token-verification',
                token,
                i18n.language?.split('-')[0],
            ],
            queryFn: () =>
                verifyResetPasswordToken(
                    i18n.language?.split('-')[0],
                    token
                ).then((res) => res.data),
            retry: false,
        });

    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (data) =>
            resetPassword(i18n.language?.split('-')[0], data, token),
        onSuccess: (response) => {
            notify('success', response.message);

            navigate('/sign-in', { replace: true });
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const handleInputChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));

        if (e.target.name === 'password') {
            const passwordErrors = validatePassword(e.target.value);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                ...passwordErrors,
                // password: Object.values(passwordErrors).join(', '),
                // password: Object.values(passwordErrors)
                //     .filter((error) => error !== '')
                //     .join(', '),
                sPassword: Object.values(passwordErrors)
                    .filter((error) => error !== '')
                    .map((error, index, array) => (
                        <React.Fragment key={index}>
                            {error}
                            {index < array.length - 1 && <br />}
                        </React.Fragment>
                    )),
            }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = formErrors;

        // Validate Password
        if (!formData.password?.trim()) {
            errors.sPassword = t('sign_up.error_enter_password');
        }
        const passwordErrors = validatePassword(formData.password.trim());
        if (Object.values(passwordErrors).some((error) => error !== '')) {
            errors.sPassword = Object.values(passwordErrors)
                .filter((error) => error !== '')
                .join(', ');
        } else {
            errors.sPassword = '';
        }
        // else if (!isValidPassword(formData.password.trim())) {
        //     errors.sPassword = t('sign_up.error_invalid_password');
        // } else {
        //     errors.sPassword = '';
        // }

        // Validate confirm Password
        if (!formData.confirmPassword?.trim()) {
            errors.sConfirmPassword = t('sign_up.error_enter_confirm_password');
        }
        // else if (!isValidPassword(formData.confirmPassword.trim())) {
        //     errors.sConfirmPassword = t('sign_up.error_invalid_password');
        // }
        else if (
            formData.password?.trim() &&
            formData.password !== formData.confirmPassword
        ) {
            errors.sConfirmPassword = t('sign_up.error_passwords_do_not_match');
        } else {
            errors.sConfirmPassword = '';
        }

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;

        mutate({
            sPassword: formData.password,
            sConfirmPassword: formData.confirmPassword,
        });
    };

    const validatePassword = (password) => {
        const errors = {};

        if (password.length < 8) {
            errors.passwordLength = t('sign_up.error_password_length');
        } else errors.passwordLength = '';

        if (!/(?=.*[A-Z])/.test(password)) {
            errors.passwordUppercase = t('sign_up.error_password_uppercase');
        } else errors.passwordUppercase = '';

        if (!/(?=.*[a-z])/.test(password)) {
            errors.passwordLowercase = t('sign_up.error_password_lowercase');
        } else errors.passwordLowercase = '';

        if (!/(?=.*[0-9])/.test(password)) {
            errors.passwordDigit = t('sign_up.error_password_digit');
        } else errors.passwordDigit = '';

        if (!/(?=.*[!@#$%^&*?:{}'"<>.;,_\-()[_+/\]])/.test(password)) {
            errors.passwordSpecialChar = t(
                'sign_up.error_password_special_char'
            );
        } else errors.passwordSpecialChar = '';

        return errors;
    };

    return isVerifyingToken ? (
        <Loader innerLoader={false} />
    ) : (
        <div className='right-screen'>
            <div className='language-selector'>
                <LangSelector i18n={i18n} />

                <div className='account-setup-wrapper'>
                    {tokenVerificationError ? (
                        <>
                            <h1 className='text-center'>
                                {t('auth.title_link_expired')}
                            </h1>

                            <p className='text-center'>
                                {t('auth.text_link_expired')}
                            </p>

                            <div
                                className='action-btn'
                                style={{ textAlign: 'right' }}
                            >
                                <Link to='/forgot-password' replace>
                                    Reset Password
                                </Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className='text-center'>
                                {t('auth.title_reset_password')}
                            </h1>
                            <Form className='login-form'>
                                <p className='text-center'>
                                    {t('auth.text_reset_password')}
                                </p>
                                <div className='row form-space'>
                                    <div className='col-12'>
                                        <InputField
                                            label={t('field_name.password')}
                                            placeholder={t(
                                                'field_name.password_placeholder'
                                            )}
                                            type='password'
                                            name='password'
                                            value={formData.password}
                                            errorMsg={formErrors.sPassword}
                                            handleOnChange={handleInputChange}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <InputField
                                            label={t(
                                                'field_name.confirm_password'
                                            )}
                                            placeholder={t(
                                                'field_name.confirm_password_placeholder'
                                            )}
                                            type='password'
                                            name='confirmPassword'
                                            value={formData.confirmPassword}
                                            errorMsg={
                                                formErrors.sConfirmPassword
                                            }
                                            handleOnChange={handleInputChange}
                                        />
                                    </div>
                                    <div className='action-btn'>
                                        <button
                                            className='primary-btn w-100'
                                            onClick={handleSubmit}
                                            disabled={isMutating}
                                        >
                                            {isMutating ? (
                                                <LoadingButton color='White' />
                                            ) : (
                                                t('submit_btn')
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

const ResetPassword = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = formatPageTitle(t('auth.title_reset_password'));
    }, [i18n.language]);

    return (
        <div className='account-setup'>
            <LeftTextAnimation />

            <RightContent />
        </div>
    );
};

export default ResetPassword;
