import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCities } from '../../../api/adapters/city';
import { getCountries } from '../../../api/adapters/country';
import { CommonContext } from '../../../context/CommonState';
import DropDownSelector from '../mini-components/DropDownSelector';
import FormErrorLabel from '../mini-components/FormErrorLabel';
import AccountTitle from './AccountTitle';

function StepOne(props) {
    const { updateUserDate, formErrors, fieldValidators } = props;
    const { userData } = useContext(CommonContext);

    const { t, i18n } = useTranslation();

    const [selectedCountry, setSelectedCountry] = useState({
        label: '',
        value: '',
    });

    const {
        data: countries,
        isLoading: isCountriesLoading,
        isError: isCountriesError,
        error: countriesError,
    } = useQuery({
        queryKey: ['countries', i18n.language?.split('-')[0]],
        queryFn: () =>
            getCountries(i18n.language?.split('-')[0]).then((res) => res.data),
        placeholderData: [],
    });

    const {
        data: cities,
        isLoading: isCitiesLoading,
        isError: isCitiesError,
        error: citiesError,
    } = useQuery({
        queryKey: [
            'cities',
            selectedCountry.value,
            i18n.language?.split('-')[0],
        ],
        queryFn: () =>
            getCities(i18n.language?.split('-')[0], selectedCountry.value).then(
                (res) => res.data
            ),
        enabled: !!selectedCountry.value,
        placeholderData: [],
    });

    const handleAccountTypeChange = (e) => {
        updateUserDate('accountType', e.target.dataset.userType);
        fieldValidators.accountType();
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        // updateUserDate('country', selectedOption.value);
        updateUserDate('country', {
            sTitle: selectedOption.label,
            _id: selectedOption.value,
        });
        fieldValidators.country();
    };

    const handleCityChange = (selectedOption) => {
        // updateUserDate('city', selectedOption.value);
        updateUserDate('city', {
            sTitle: selectedOption.label,
            _id: selectedOption.value,
        });
        fieldValidators.city();
    };

    return (
        <>
            <AccountTitle
                title={t('account_setup.title_type_of_account')}
                desc={t('account_setup.description_type_of_account')}
            />

            <div className='account-type'>
                <div className='typ-card'>
                    <input
                        onChange={handleAccountTypeChange}
                        type='radio'
                        name='account-type'
                        data-user-type='R'
                        checked={userData?.accountType === 'R'}
                    />
                    <div className='type'>
                        <img src='/images/icons/resident.svg' alt='' />
                        <p className='lg'>
                            {t('account_setup.title_resident')}
                        </p>
                    </div>
                </div>
                <div className='typ-card'>
                    <input
                        onChange={handleAccountTypeChange}
                        type='radio'
                        name='account-type'
                        data-user-type='S'
                        checked={userData?.accountType === 'S'}
                    />
                    <div className='type'>
                        <img src='/images/icons/student.svg' alt='' />
                        <p className='lg'>{t('account_setup.title_student')}</p>
                    </div>
                </div>
            </div>
            {formErrors.accountType ? (
                <FormErrorLabel message={formErrors.accountType} />
            ) : null}

            <div className='address'>
                <div className='row form-space'>
                    <div className='col-12'>
                        <DropDownSelector
                            dropDownLabel={`${t(
                                'account_setup.title_country'
                            )}*`}
                            placeholder={
                                userData.country?.sTitle
                                    ? userData?.country?.sTitle
                                    : t('account_setup.placeholder_country')
                            }
                            options={
                                countries?.map((country) => {
                                    return {
                                        label: country.sTitle,
                                        value: country._id,
                                    };
                                }) || []
                            }
                            isDisabled={isCountriesLoading || isCountriesError}
                            handleOnChange={handleCountryChange}
                        />
                        {isCountriesError || formErrors.country ? (
                            <FormErrorLabel
                                message={
                                    countriesError?.message ||
                                    formErrors.country
                                }
                            />
                        ) : null}
                    </div>
                    <div className='col-12'>
                        <DropDownSelector
                            dropDownLabel={`${t('account_setup.title_city')}*`}
                            placeholder={
                                userData.city?.sTitle
                                    ? userData?.city?.sTitle
                                    : t('account_setup.placeholder_city')
                            }
                            options={
                                // cities?.map((city) => ({
                                //     label: city.sTitle,
                                //     value: city._id,
                                // })) || []
                                cities?.map((city) => {
                                    return {
                                        label: city.sTitle,
                                        value: city._id,
                                    };
                                }) || []
                            }
                            isDisabled={
                                !selectedCountry.value ||
                                isCitiesLoading ||
                                isCitiesError
                            }
                            handleOnChange={handleCityChange}
                        />
                        {isCitiesError || formErrors.city ? (
                            <FormErrorLabel
                                message={
                                    citiesError?.message || formErrors.city
                                }
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
}

StepOne.propTypes = {
    updateUserDate: PropTypes.func.isRequired,
    formErrors: PropTypes.shape({
        accountType: PropTypes.string,
        country: PropTypes.string,
        city: PropTypes.string,
    }).isRequired,
    fieldValidators: PropTypes.shape({
        accountType: PropTypes.func.isRequired,
        country: PropTypes.func.isRequired,
        city: PropTypes.func.isRequired,
    }).isRequired,
};

export default StepOne;
