import React from 'react';
import EsCookiesPage from './CookiesPageLangComponents/EsCookiesPage';

function CookiesPage() {
    return (
        <div className='cms-page'>
            <EsCookiesPage />
        </div>
    );
}

export default CookiesPage;
