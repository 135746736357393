import ReactLoading from 'react-loading';

export function ChatLoader() {
    return (
        <div>
            <ReactLoading
                type={'bars'}
                color={'#0080ff'}
                height={60}
                width={60}
            />
        </div>
    );
}
