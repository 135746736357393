import apiClient from '../client/api-client';

export const getTextAnimations = (language) => {
    const url = 'user/animation/list';

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};
