import React, { useEffect, useRef, useState } from 'react';
import Video from '../../../assets/videos/Whiteboard_video_3.mp4';

const VideoPlayer = ({ src }) => {
    const videoRef = useRef(null);
    const btnRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayBtn, setIsPlayBtn] = useState(true);

    useEffect(() => {
        const video = videoRef.current;

        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);
        const handleEnded = () => setIsPlayBtn(true); // Setting isPlayBtn to true when video ends

        video.addEventListener('play', handlePlay);
        video.addEventListener('pause', handlePause);
        video.addEventListener('ended', handleEnded);

        return () => {
            video.removeEventListener('play', handlePlay);
            video.removeEventListener('pause', handlePause);
            video.removeEventListener('ended', handleEnded);
        };
    }, []);

    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video.paused || video.ended) {
            video.play();
        } else {
            video.pause();
        }
    };

    return (
        <div className={`video-player ${isPlaying && ''}`}>
            <video
                ref={videoRef}
                src={Video}
                onMouseEnter={() => {
                    setIsPlayBtn(true);
                }}
            />
            {isPlayBtn && (
                <div
                    className='overlay'
                    onMouseLeave={() => {
                        if (isPlaying) {
                            setIsPlayBtn(false);
                        }
                    }}
                >
                    <button
                        ref={btnRef}
                        className='simple-btn'
                        onClick={togglePlayPause}
                    >
                        {isPlaying ? (
                            <img src='/images/pausvideo.svg' alt='' />
                        ) : (
                            <img src='/images/playvideo.svg' alt='' />
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
