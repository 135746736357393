import React, { useState } from 'react';

function BalloonsScreen() {
    const [animate, setAnimate] = useState(false);

    return (
        <div className={`balloons-screen ${animate ? 'animate' : ''}`}>
            <img className='balloon1' src='/images/balloon1.svg' alt='' />
            <img className='balloon2' src='/images/balloon2.svg' alt='' />
            <img className='balloon3' src='/images/balloon3.svg' alt='' />
            <img className='balloon4' src='/images/balloon4.svg' alt='' />
            <img className='balloon5' src='/images/balloon5.svg' alt='' />
            <img className='balloon6' src='/images/balloon6.svg' alt='' />
        </div>
    );
}

export default BalloonsScreen;
