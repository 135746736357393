import apiClient from '../client/api-client';

export const reportUser = async (language, data) => {
    const url = 'user/reported-user/report';

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};
