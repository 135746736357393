import apiClient from '../client/api-client';

export const feedback = async (language, data) => {
    const url = 'user/feedback/add';
    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language },
    });
};
