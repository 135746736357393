import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from '../../../utils/helpers/notification.helpers';

function TimeSlots(props) {
    const { t } = useTranslation();

    const {
        slots,
        slotLabel,
        setSlots,
        isClickable = true,
        colorLabel,
        setSelectedSlots,
        bIsRadio,
    } = props;

    const handleCheckboxChange = (e, index) => {
        const updatedSlots =
            e.target.type === 'radio' ? slots[index] : [...slots];
        if (e.target.type === 'radio') {
            setSelectedSlots(updatedSlots);
        } else {
            const nSelectedSlots = updatedSlots.filter(
                (item) => item.isSelected
            );

            if (nSelectedSlots?.length < 3 || updatedSlots[index].isSelected) {
                updatedSlots[index].isSelected =
                    !updatedSlots[index].isSelected;
                setSlots(updatedSlots);
            } else
                notify('error', t('appointments.errors.slots_not_available'));
        }
    };

    const style = {
        color: ` ${!colorLabel ? '#0080FF' : colorLabel} `,
        display: 'block',
        paddingBottom: '8px',
    };
    return (
        <>
            {slotLabel && (
                <p style={style} className='sm'>
                    {slotLabel}
                </p>
            )}
            <ul className='slots-container'>
                {slots ? (
                    slots.map((item, index) => {
                        return (
                            <li key={index}>
                                {isClickable && (
                                    <input
                                        type={bIsRadio ? 'radio' : 'checkbox'}
                                        name='timeSlot'
                                        checked={
                                            !bIsRadio
                                                ? item.isSelected || false
                                                : null
                                        }
                                        onChange={(e) => {
                                            handleCheckboxChange(e, index);
                                        }}
                                    />
                                )}
                                <div className='time-slot-box'>
                                    <img
                                        src={`${
                                            Number(item.from?.split(':')[0]) >=
                                                6 &&
                                            Number(item.from?.split(':')[0]) <=
                                                17
                                                ? '/images/icons/day.svg'
                                                : '/images/icons/night.svg'
                                        }`}
                                        alt=''
                                    />
                                    <p className='sm'>
                                        <span>
                                            {item?.from || item?.dStartTime
                                                ? item?.from ||
                                                  moment(
                                                      item?.dStartTime
                                                  ).format('H:mm')
                                                : '18:00'}
                                        </span>{' '}
                                        -{' '}
                                        <span>
                                            {item?.to || item?.dEndTime
                                                ? item?.to ||
                                                  moment(item?.dEndTime).format(
                                                      'H:mm'
                                                  )
                                                : '18:30'}
                                        </span>
                                    </p>
                                </div>
                            </li>
                        );
                    })
                ) : (
                    <li>
                        <input type='checkbox' name='timeSlot' id='' />
                        <div className='time-slot-box'>
                            <img src={`${'/images/icons/night.svg'}`} alt='' />
                            <p className='sm'>
                                <span>{'18:00'}</span> - <span>{'18:30'}</span>
                            </p>
                        </div>
                    </li>
                )}
            </ul>
        </>
    );
}

export default TimeSlots;
