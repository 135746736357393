import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatTalkedHours,
    getFullName,
} from '../../../utils/helpers/format.helpers';

const UserDetails = (props) => {
    const { dialoguerData } = props;
    const { t, i18n } = useTranslation();

    return (
        <div className='col-md-4'>
            <div className='user-dp'>
                <img
                    src={
                        dialoguerData?.sProfilePicUrl ||
                        '/images/profile-image-placeholder.svg'
                    }
                    alt=''
                />
            </div>

            <h2 className='name'>{getFullName(dialoguerData?.oName)}</h2>

            <ul className='location'>
                <li>
                    <img src='/images/location-ic.svg' alt='location' />
                    <span>
                        {dialoguerData?.oCity?.sTitle},{' '}
                        {dialoguerData?.oCountry?.sTitle}
                    </span>
                </li>
                {dialoguerData?.nTotalCallSeconds !== undefined &&
                dialoguerData?.nTotalCallSeconds !== null ? (
                    <li>
                        <img src='/images/tele-ic.svg' alt='tele' />
                        <span>
                            {t('talked_for')}{' '}
                            {formatTalkedHours(
                                i18n.language.split('-')[0],
                                dialoguerData?.nTotalCallSeconds
                            )}
                        </span>
                    </li>
                ) : null}
            </ul>

            {dialoguerData?.sBio ? (
                <div className='bio'>
                    <p className='lg'>{t('home.bio')}</p>
                    <p className='bio-content'>{dialoguerData?.sBio}</p>
                </div>
            ) : null}
        </div>
    );
};

UserDetails.propTypes = {
    dialoguerData: PropTypes.shape({
        oName: PropTypes.shape({
            sFirstName: PropTypes.string,
            sMiddleName: PropTypes.string,
            sLastName: PropTypes.string,
        }).isRequired,
        sBio: PropTypes.string,
        sProfilePicUrl: PropTypes.string,
        oCity: PropTypes.shape({
            sTitle: PropTypes.string,
        }).isRequired,
        oCountry: PropTypes.shape({
            sTitle: PropTypes.string,
        }).isRequired,
        nTotalCallSeconds: PropTypes.number,
    }),
};

export default UserDetails;
