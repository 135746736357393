import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {
    return (
        <div className='page-not-found'>
            <div>
                <img src='/images/404.svg' alt='' />
                <p className='lg'>Page not found</p>
                <Link to='/' className='primary-btn'>
                    Go Home
                </Link>
            </div>
        </div>
    );
}

export default PageNotFound;
