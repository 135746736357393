import {
    CLEAR_NOTIFICATIONS,
    DELETE_MESSAGE_NOTIFICATION,
    DELETE_NOTIFICATION,
    DELETE_REQUEST_NOTIFICATION,
    DELETE_SCHEDULED_NOTIFICATIONS,
    UPDATE_NOTIFICATION,
} from '../constants/redux-events';

export const updateNotification = (payload) => ({
    type: UPDATE_NOTIFICATION,
    payload,
});

export const deleteNotification = (payload) => ({
    type: DELETE_NOTIFICATION,
    payload,
});

export const deleteAppointmentNotification = (payload) => ({
    type: DELETE_REQUEST_NOTIFICATION,
    payload,
});

export const deleteScheduledNotification = (payload) => ({
    type: DELETE_SCHEDULED_NOTIFICATIONS,
    payload,
});

export const deleteMessageNotification = (payload) => ({
    type: DELETE_MESSAGE_NOTIFICATION,
    payload,
});

export const clearNotification = (payload) => ({
    type: CLEAR_NOTIFICATIONS,
    payload,
});
