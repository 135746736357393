import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadcrumbAction from '../../../components/common-component/BreadcrumbAction';
const EsLegalNotice = () => {
    const { search } = useLocation();
    const { t, i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <>
            <BreadcrumbAction lastPage={t('footer_links.terms_conditions')} />
            <div className='content-wrapper'>
                <h5 className='mb-2'>1. Objeto y aceptación</h5>
                <p>El presente aviso legal regula el uso del sitio Web: </p>
                <p>
                    El sitio web presta servicios cuyo objetivo primordial es
                    permitir el contacto entre los usuarios y la entidad, bien
                    para ponerse en contacto con nosotros, responder a su
                    solicitud, gestionar su reclamación o ejercitar sus derechos
                    en protección de datos.
                </p>
                <p>
                    La navegación por la página atribuye la condición de usuario
                    de la misma e implica la aceptación plena y sin reservas de
                    todas y cada una de las disposiciones incluidas en este
                    Aviso Legal, que pueden sufrir modificaciones.
                </p>
                <p>
                    El usuario se obliga a hacer un uso correcto del sitio Web
                    de conformidad con las leyes, la buena fe, el orden público,
                    los usos del tráfico y el presente Aviso Legal. El Usuario
                    responderá frente a DIALOGAE, S.L.U. y frente a terceros, de
                    los daños y perjuicios que pudieran causarse como
                    consecuencia del incumplimiento de dicha obligación.
                </p>
                <h5 className='mb-2'>
                    2. Titularidad de la web e identificación del Prestador de
                    Servicios de la Sociedad de la Información
                </h5>
                <p>
                    Con la finalidad de dar cumplimiento al principio de
                    Información general exigido por el Artículo 10 de la Ley
                    34/2002, de 11 de julio, de servicios de la sociedad de la
                    información y de comercio electrónico informamos a nuestros
                    usuarios que el presente sitio web pertenece a DIALOGAE,
                    S.L.U. con NIF16372849.
                </p>
                <table className='mb-3'>
                    <tbody>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Dirección
                                </span>
                            </td>
                            <td>
                                Calle Bethencourt Afonso 17, 38002 - Santa Cruz
                                de Tenerife
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Correo-e
                                </span>
                            </td>
                            <td>info@dialogae.com</td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Teléfono
                                </span>
                            </td>
                            <td>649645874</td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Web alojada por:
                                </span>
                            </td>
                            <td>Loading</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className='mb-2'>
                    3. Condiciones generales de acceso y utilización
                </h5>
                <p>
                    El sitio Web y sus servicios son de acceso libre y gratuito,
                    si bien la utilización de determinadas áreas o servicios
                    está condicionada a cumplimentar formularios de recogida de
                    datos. Sólo los mayores de 18 años podrán facilitar datos a
                    través de nuestra web, y los menores de 14 años no pueden
                    facilitarlos sin el consentimiento de los padres o tutores.
                </p>
                <p>
                    El Usuario garantiza la autenticidad y actualidad de todos
                    aquellos datos que comunique a DIALOGAE, S.L.U. y será el
                    único responsable de las manifestaciones falsas o inexactas
                    que realice.
                </p>
                <p>tiene los siguientes tipos de usuarios:</p>
                <ul>
                    <li>
                        Usuarios que aportan datos en cualquiera de los
                        formularios disponibles: Los datos personales que
                        DIALOGAE, S.L.U. solicita incluye el nombre y dirección
                        electrónica y los proporcionados por el propio usuario
                        al rellenar los formularios.
                    </li>
                    <li>
                        Usuarios que no han contactado con DIALOGAE, S.L.U. pero
                        de los que se recogen datos: Datos proporcionados por
                        las cookies
                    </li>
                    <li>
                        Usuarios de nuestra área privada: Incluye el usuario y
                        contraseña.
                    </li>
                </ul>
                <h5 className='mb-2'>
                    3.1. Reglas y prohibiciones de uso para usuarios de nuestros
                    servicios
                </h5>
                <p>
                    El Usuario se compromete expresamente a hacer un uso
                    adecuado de los contenidos y servicios ofrecidos y a no
                    emplearlos para:
                </p>
                <ul>
                    <li>
                        Difundir contenidos delictivos, violentos,
                        pornográficos, racistas, xenófobo, ofensivos, de
                        apología del terrorismo o, en general, contrarios a la
                        ley o al orden público.
                    </li>
                    <li>
                        Introducir en la red virus informáticos o realizar
                        actuaciones susceptibles de alterar, estropear,
                        interrumpir o generar errores o daños en los documentos
                        electrónicos, datos o sistemas físicos y lógicos de
                        DIALOGAE, S.L.U. o de terceras personas; así como
                        obstaculizar el acceso de otros Usuarios al sitio Web y
                        a sus servicios.
                    </li>
                    <li>
                        Intentar acceder a áreas restringidas de los sistemas
                        informáticos de DIALOGAE, S.L.U. o de terceros y, en su
                        caso, extraer información.
                    </li>
                    <li>
                        Vulnerar los derechos de propiedad intelectual o
                        industrial, así como violar la confidencialidad de la
                        información de DIALOGAE, S.L.U. o de terceros.
                    </li>
                    <li>
                        Suplantar la identidad de otro Usuario, de las
                        administraciones públicas o de un tercero.
                    </li>
                    <li>
                        Reproducir, copiar, distribuir, poner a disposición o de
                        cualquier otra forma comunicar públicamente, transformar
                        o modificar los Contenidos, a menos que se cuente con la
                        autorización del titular de los correspondientes
                        derechos o ello resulte legalmente permitido.
                    </li>
                    <li>
                        Recabar datos con finalidad publicitaria y de remitir
                        publicidad de cualquier clase y comunicaciones con fines
                        de venta u otras de naturaleza comercial sin que medie
                        su previa solicitud o consentimiento.
                    </li>
                </ul>
                <h5 className='mb-2'>
                    3.2. Procedimiento en caso de realización de actividades de
                    carácter ilícito
                </h5>
                <p>
                    En el caso de que cualquier usuario o un tercero considere
                    que existen hechos o circunstancias que revelen el carácter
                    ilícito de la utilización de cualquier contenido y/o de la
                    realización de cualquier actividad en las páginas Web
                    incluidas o accesibles a través del sitio Web, deberá enviar
                    una notificación a DIALOGAE, S.L.U. identificándose
                    debidamente, especificando las supuestas infracciones y
                    declarando expresamente y bajo su responsabilidad que la
                    información proporcionada en la notificación es exacta.
                </p>
                <h5 className='mb-2'>4. Política de privacidad</h5>
                <p>
                    El usuario puede consultar cómo DIALOGAE, S.L.U. utiliza sus
                    datos y las medidas de seguridad implantadas en el enlace:
                    "Política de privacidad”
                </p>
                <h5 className='mb-2'>5. Política de cookies</h5>
                <p>
                    DIALOGAE, S.L.U. instala cookies en el ordenador del
                    usuario. Para conocer más las cookies de nuestro sitio web
                    acuda al siguiente enlace: "Política de cookies"
                </p>
                <h5 className='mb-2'>6. Notificaciones</h5>
                <p>
                    Todas las notificaciones y comunicaciones entre los usuarios
                    y DIALOGAE, S.L.U. se considerarán eficaces, a todos los
                    efectos, cuando se realicen a través de correo postal o
                    correo electrónico o comunicación telefónica. Los usuarios
                    deberán dirigirse a DIALOGAE, S.L.U. mediante:
                </p>
                <ul>
                    <li>
                        Envío por correo postal a la siguiente dirección:
                        DIALOGAE, S.L.U. – Calle Bethencourt Afonso 17, 38002 -
                        Santa Cruz de Tenerife.
                    </li>
                    <li>
                        Envío por correo electrónico a la siguiente dirección:
                        info@dialogae.com
                    </li>
                </ul>
                <h5 className='mb-2'>
                    7. Propiedad Industrial y propiedad intelectual
                </h5>
                <p>
                    Todos los contenidos del sitio Web, como textos,
                    fotografías, gráficos, imágenes, iconos, tecnología,
                    software, así como su diseño gráfico y códigos fuente,
                    constituyen una obra cuya propiedad pertenece a DIALOGAE,
                    S.L.U., sin que puedan entenderse cedidos al Usuario ninguno
                    de los derechos de explotación sobre los mismos más allá de
                    lo estrictamente necesario para el correcto uso de la Web.
                </p>
                <p>
                    En definitiva, los usuarios que accedan a este sitio Web
                    pueden visualizar los contenidos y efectuar, en su caso,
                    copias privadas autorizadas siempre que los elementos
                    reproducidos no sean cedidos posteriormente a terceros, ni
                    se instalen a servidores conectados a redes, ni sean objeto
                    de ningún tipo de explotación y única y exclusivamente
                    mientras se encuentre en vigor el servicio.
                </p>
                <p>
                    Asimismo, todas las marcas, nombres comerciales o signos
                    distintivos de cualquier clase que aparecen en el sitio Web
                    son propiedad de DIALOGAE, S.L.U., sin que pueda entenderse
                    que el uso o acceso al mismo atribuya al Usuario derecho
                    alguno sobre los mismos.
                </p>
                <p>
                    Quedan prohibidas la distribución, modificación, cesión o
                    comunicación pública de los contenidos y cualquier otro acto
                    que no haya sido expresamente autorizado por DIALOGAE,
                    S.L.U. En caso de incumplimiento DIALOGAE, S.L.U. procederá
                    a adoptar las medidas legales oportunas.
                </p>
                <h5 className='mb-2'>
                    8. Exención de responsabilidad y modificación del presente
                    aviso legal
                </h5>
                <p>
                    La web utiliza técnicas de seguridad de la información tales
                    como firewalls, procedimientos de control de acceso y
                    mecanismos criptográficos, todo ello con el objetivo de
                    evitar el acceso no autorizado a los datos y garantizar la
                    confidencialidad de los mismos.
                </p>
                <p>
                    La información que se difunde en esta web se hace única y
                    exclusivamente a título informativo, reservándose DIALOGAE,
                    S.L.U. el derecho de eliminar o suspender su difusión, total
                    o parcialmente, y a modificar la estructura y contenido de
                    este sitio Web sin aviso previo, pudiendo incluso limitar o
                    no permitir el acceso a dicha información. DIALOGAE, S.L.U.
                    intenta mantener la calidad y actualización de esta
                    información y evitar y minimizar posibles errores pero
                    DIALOGAE, S.L.U. no responderá de los daños o perjuicios
                    causados por decisiones tomadas en base a la información
                    difundida; ni de inexactitudes, omisiones o errores
                    contenidos en el mismo, ni de los problemas que se originen
                    por el uso de este sitio Web u otro de conexión externa; ni
                    de los daños y/o perjuicios en el software o hardware del
                    usuario que se deriven del acceso a este sitio Web.
                </p>
                <p>
                    Los Usuarios aceptan expresamente que DIALOGAE, S.L.U. no
                    será responsable del acceso no autorizado o alteración de
                    sus transmisiones o datos, de cualquier material o datos
                    enviados o recibidos o no enviados o recibidos, ni de
                    ninguna transacción realizada a través de su web.
                </p>
                <p>
                    DIALOGAE, S.L.U. se reserva el derecho a modificar el
                    presente aviso legal unilateralmente y sin preaviso en los
                    términos y condiciones que estime conveniente con la única
                    obligación de informar al usuario de las modificaciones
                    efectuadas.
                </p>
                <h5 className='mb-2'>9. Nulidad parcial</h5>
                <p>
                    Si alguna de las presentes cláusulas fuese declarada nula y
                    sin efecto por resolución firme dictada por autoridad
                    competente, los restantes términos y condiciones
                    permanecerán en vigor, sin que queden afectados por dicha
                    declaración de nulidad.
                </p>
                <h5 className='mb-2'>
                    10. Ley aplicable y tribunales competentes
                </h5>
                <p>
                    El presente sitio web queda sometido a lo establecido por la
                    legislación española y normativa europea que resulte de
                    aplicación
                </p>
                <p>
                    Cualquier controversia que surja o guarde relación con el
                    uso de la página web o con dichos productos será sometida a
                    la jurisdicción no exclusiva de los juzgados y tribunales
                    españoles de la provincia de Santa Cruz de Tenerife.
                </p>
                <p>
                    Si se trata de un consumidor final nada en la presente
                    cláusula afectará a los derechos que como tal le reconoce la
                    legislación vigente, pudiendo elegir presentar una
                    reclamación para hacer valer sus derechos en relación con
                    las presentes Condiciones ante el Juez o Tribunales que
                    correspondan a su domicilio.
                </p>
                <div className='fw-bold text-black'>Fecha: 30 abril 2024</div>
                <div className='fw-bold text-black'>Versión: V.1.0</div>
            </div>
        </>
    );
};

export default EsLegalNotice;
