import PropTypes from 'prop-types';
import React from 'react';

import Categories from '../common-component/Categories';

const InterestsWrapper = (props) => {
    const { interests, isSelectable, selectedItems, handleSelect } = props;

    return (
        <div className='interest-wrapper'>
            {interests.map((interestCategory) => (
                <Categories
                    key={interestCategory.sTitle}
                    title={interestCategory.sTitle}
                    interestItems={interestCategory.aInterestItems}
                    isSelectable={isSelectable}
                    selectedItems={selectedItems}
                    handleSelect={handleSelect}
                />
            ))}
        </div>
    );
};

InterestsWrapper.propTypes = {
    interests: PropTypes.array.isRequired,
    handleSelect: PropTypes.func,
    selectedItems: PropTypes.array,
    isSelectable: PropTypes.bool,
};

export default InterestsWrapper;
