import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SpeakNowCountdown = (props) => {
    const {
        speakNowStartTime,
        refetchDialogaeNowTime,
        isHours = true,
        countDownTitle: CountDownTitle,
        speakNowEndTime,
        isSeconds = true,
    } = props;

    const { t } = useTranslation();

    const [countdown, setCountdown] = useState({
        hours: '',
        minutes: '',
        seconds: '',
    });

    const [endTime, setEndTime] = useState({
        hours: '',
        minutes: '',
        seconds: '',
    });

    const padNumber = (num) => (num < 10 ? `0${num}` : num);

    useEffect(() => {
        const currentTime = new Date();
        const timeDifference =
            new Date(speakNowStartTime).getTime() - currentTime.getTime();

        if (timeDifference <= 0) {
            setCountdown(null);
        } else {
            const seconds = padNumber(Math.floor((timeDifference / 1000) % 60));
            const minutes = padNumber(
                Math.floor((timeDifference / 1000 / 60) % 60)
            );
            const hours = padNumber(
                Math.floor(timeDifference / 1000 / 60 / 60)
            );

            setCountdown({ hours, minutes, seconds });
        }

        const interval = setInterval(() => {
            const currentTime = new Date();
            const timeDifference =
                new Date(speakNowStartTime).getTime() - currentTime.getTime();

            if (timeDifference <= 0) {
                clearInterval(interval);
                setCountdown(null);
                refetchDialogaeNowTime();
            } else {
                const seconds = padNumber(
                    Math.floor((timeDifference / 1000) % 60)
                );
                const minutes = padNumber(
                    Math.floor((timeDifference / 1000 / 60) % 60)
                );
                const hours = padNumber(
                    Math.floor(timeDifference / 1000 / 60 / 60)
                );

                setCountdown({ hours, minutes, seconds });
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [speakNowStartTime]);

    useEffect(() => {
        if (speakNowEndTime) {
            const date = new Date(speakNowEndTime);

            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            setEndTime({ hours, minutes, seconds });
        }
    }, [speakNowEndTime]);

    return (
        <div className='count-down'>
            <p className='sm f-600 pb-2'>
                {countdown ? (
                    <> {CountDownTitle} </>
                ) : (
                    <>{t('dialog_now.start_in')}</>
                )}
            </p>
            <div className='counter'>
                {isHours && (
                    <>
                        <div>
                            <h1 className='lg f-700 blue-text'>
                                {countdown?.hours || endTime?.hours}
                            </h1>
                            {isSeconds && <p className='sm'>{t('hour')}</p>}
                        </div>
                        <p className='sm'>:</p>
                    </>
                )}
                <div>
                    <h1 className='lg f-700 blue-text'>
                        {countdown?.minutes || endTime?.minutes}
                    </h1>
                    {isSeconds && <p className='sm'>{t('min')}</p>}
                </div>
                {isSeconds && (
                    <>
                        <p className='sm'>:</p>
                        <div>
                            <h1 className='lg f-700 blue-text'>
                                {countdown?.seconds || endTime?.seconds}
                            </h1>
                            <p className='sm'>{t('sec')}</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
SpeakNowCountdown.propTypes = {
    speakNowStartTime: PropTypes.string.isRequired,
    refetchDialogaeNowTime: PropTypes.func.isRequired,
};

export default SpeakNowCountdown;
