import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';

function RequestAccept() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='account-setup'>
            <LeftTextAnimation GifShow={true} />
            <div className='right-screen'>
                <div className='request-content'>
                    <div>
                        <h1>
                            {t('req_pages.waoh')}{' '}
                            {user.firstName +
                                (user.middleName ? ' ' + user.middleName : '') +
                                (user.lastName ? ' ' + user.lastName : '')}
                        </h1>
                        <img src='/images/request-accept.svg' alt='' />
                        <p className='lg'>
                            <span style={{ fontWeight: '600' }}>
                                {t('req_pages.congratulations')}
                            </span>{' '}
                            {t('req_pages.has_been_accepted')}
                        </p>
                        <button
                            className='primary-btn w-100'
                            onClick={() => navigate('/', { replace: true })}
                        >
                            {t('req_pages.lets_start')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequestAccept;
