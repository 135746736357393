import React from 'react';
import { useTranslation } from 'react-i18next';

function RulesContent() {
    const { t } = useTranslation();

    return (
        <div className='content-wrapper'>
            <div className='rules-content'>
                <img
                    className='rules-icon'
                    src='/images/rules-icon.svg'
                    alt=''
                />
                <h1>{t('speak_now.forget')}</h1>
                <ul className='rules-points'>
                    <li>{t('speak_now.be_kind')}</li>
                    <li>{t('speak_now.sensitive')}</li>
                    <li>{t('speak_now.enjoy')}</li>
                </ul>
            </div>
            <div className='rule-logo'>
                <img src='/images/rule-logo.svg' alt='' />
            </div>
        </div>
    );
}

export default RulesContent;
