import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInterestCategories } from '../../../api/adapters/interest-category';
import { CommonContext } from '../../../context/CommonState';
import Categories from '../common-component/Categories';
import InterestHeader from '../common-component/InterestHeader';
import AccountTitle from './AccountTitle';

function StepFive(props) {
    const { updateUserDate, formErrors } = props;

    const { t, i18n } = useTranslation();

    const [selectedItems, setSelectedItems] = useState([]);
    const { userData } = useContext(CommonContext);

    const { data: interestCategories, isLoading: isInterestCategoriesLoading } =
        useQuery({
            queryKey: ['interest-categories', i18n.language?.split('-')[0]],
            queryFn: () =>
                getInterestCategories(i18n.language?.split('-')[0]).then(
                    (res) => res.data
                ),
            placeholderData: [],
        });

    const handleSelect = (e) => {
        if (e.target.checked) {
            // Add New
            setSelectedItems((prevItems) => [...prevItems, e.target.name]);
        } else {
            // Remove Existing
            setSelectedItems((prevItems) =>
                prevItems.filter(
                    (interestItem) => interestItem !== e.target.name
                )
            );
        }
    };

    const handleClear = (e) => {
        e.preventDefault();

        setSelectedItems([]);
    };

    useEffect(() => {
        updateUserDate('interestItems', selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        if (userData.interestItems) {
            setSelectedItems(userData.interestItems);
        }
    }, []);

    if (isInterestCategoriesLoading) return <h1>Loading...!!!</h1>;

    return (
        <>
            <AccountTitle
                title={t('auth.set_interest')}
                desc={t('auth.between')}
            />

            <InterestHeader
                selectedItemsCount={selectedItems.length}
                handleClear={handleClear}
            />

            <div className='categories-container'>
                {interestCategories?.map((interestCategory) => (
                    <Categories
                        key={interestCategory.sTitle}
                        categories={interestCategory.sTitle}
                        interestItems={interestCategory.aInterestItems}
                        handleSelect={handleSelect}
                        selectedItems={selectedItems}
                        title={interestCategory.sTitle}
                        isSelectable={true}
                    />
                ))}
                {/* {formErrors?.interestItems ? (
                    <FormErrorLabel message={formErrors.interestItems} />
                ) : null} */}
            </div>
        </>
    );
}

export default StepFive;
