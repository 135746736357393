import apiClient from '../client/api-client';

export const getConversations = async (language) => {
    const url = 'user/chat/conversation/list';

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const getMessages = async (language, conversationId) => {
    const url = `user/chat/conversation/${conversationId}/message/list`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const clearMessages = async (language, conversationId) => {
    const url = `user/chat/conversation/${conversationId}/message/clear`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const deleteConversation = async (language, conversationId) => {
    const url = `user/chat/conversation/${conversationId}/delete`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};

export const unMatchUser = async (language, conversationId) => {
    const url = `user/chat/conversation/${conversationId}/unmatch`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};
