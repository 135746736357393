import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadcrumbActiom from '../../../components/common-component/BreadcrumbAction';

const EsPrivacyPolicy = () => {
    const { search } = useLocation();
    const { t, i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);

    return (
        <>
            <BreadcrumbActiom lastPage={t('footer_links.privacy_policy')} />
            <div className='content-wrapper'>
                <h4 className='mb-2'>
                    NUESTRA POLÍTICA DE PRIVACIDAD DE UN VISTAZO
                </h4>
                <p>
                    <span className='fw-bold text-black'>¿Quiénes somos?</span>{' '}
                    Somos DIALOGAE, S.L.U. y tratamos tus datos como Responsable
                    del Tratamiento. Eso significa que nos hacemos cargo de cómo
                    usar y proteger tus datos personales.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        ¿Para qué usamos tus datos?
                    </span>{' '}
                    Usamos los datos que nos has facilitado online o a través de
                    cualquier otro medio para atender tus consultas y
                    sugerencias, prestarte los servicios o suministrarte los
                    productos solicitados, registrarte como usuario, así como
                    remitirte las comunicaciones personalizadas a las que te
                    hayas suscrito.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        ¿Por qué los tenemos?{' '}
                    </span>
                    Estamos legitimados a tratar tus datos por diferentes
                    motivos. Al rellenar un formulario o mandar un correo-e en
                    el que nos planteas una consulta o sugerencia tenemos un
                    interés legítimo en atenderla. Cuando te registras en
                    nuestra web debemos ejecutar el contrato que aceptas con
                    nosotros. También estamos legitimados por tu consentimiento
                    cuando nos prestas autorización para enviarte las ofertas
                    personalizadas.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        ¿Con quién compartimos tus datos?
                    </span>{' '}
                    Solo compartiremos los datos con aquellas empresas
                    colaboradoras para atender las solicitudes recibidas y
                    prestar nuestros servicios o facilitarte los productos,
                    entidades bancarias y organismos obligados por ley. En
                    ningún caso vendemos tus datos a terceros.
                </p>
                <p>
                    <span className=' fw-bold text-black '>
                        ¿Qué derechos tienes?
                    </span>{' '}
                    Tienes derecho a acceder, rectificar o suprimir tus datos
                    personales. También tienes derecho a oponerte a que usemos
                    datos que nos hayas autorizado a usar previamente, a
                    portarlos y a no ser objeto de decisiones individualizadas
                    automatizadas. En el tratamiento de tus datos personales,
                    aplicamos los siguientes principios para garantizar la mayor
                    protección para ti:
                </p>
                <ul>
                    <li>
                        <span className='fw-bold text-black'>
                            Principio de licitud, lealtad y transparencia:
                        </span>{' '}
                        Siempre vamos a requerir tu consentimiento para el
                        tratamiento de tus datos personales para uno o varios
                        fines específicos que te informaremos previamente con
                        absoluta transparencia.
                    </li>
                    <li>
                        <span className='fw-bold text-black'>
                            Principio de minimización de datos:{' '}
                        </span>{' '}
                        Solo vamos a solicitar datos estrictamente necesarios en
                        relación con los fines para los que los requerimos. Los
                        mínimos posibles.
                    </li>
                    <li>
                        <span className='fw-bold text-black'>
                            Principio de limitación del plazo de conservación:
                        </span>{' '}
                        los datos serán mantenidos durante no más tiempo del
                        necesario para los fines del tratamiento, en función a
                        la finalidad, te informaremos del plazo de conservación
                        correspondiente, en el caso de suscripciones,
                        periódicamente revisaremos nuestras listas y
                        eliminaremos aquellos registros inactivos durante un
                        tiempo considerable.
                    </li>
                    <li>
                        <span className='fw-bold text-black'>
                            Principio de integridad y confidencialidad:
                        </span>{' '}
                        Tus datos serán tratados de tal manera que se garantice
                        una seguridad adecuada de los datos personales y se
                        garantice confidencialidad. Debes saber que tomamos
                        todas las precauciones necesarias para evitar el acceso
                        no autorizado o uso indebido de los datos de nuestros
                        usuarios por parte de terceros.
                    </li>
                </ul>
                <p>
                    En esta política de privacidad encontrarás más información
                    detallada sobre el uso que hacemos de los datos personales
                    de nuestros clientes, proveedores y usuarios, y en general
                    de todas las personas que mantienen contactos con nosotros,
                    con independencia del medio que hayan utilizado para
                    comunicarse (formulario online, por teléfono o por correo-e,
                    de forma presencial).
                </p>
                <p>
                    Nos gusta ser transparentes sobre lo que hacemos con tus
                    datos personales, que entiendas las implicaciones de los
                    usos que llevamos a cabo, o los derechos que dispones en
                    relación con tus datos. Por ese motivo mantenemos
                    actualizada nuestra Política de Privacidad, el Aviso legal y
                    la Política de cookies, información que puedes consultar en
                    esta misma página web.
                </p>
                <p>
                    En caso de que tengas alguna duda o quieras obtener más
                    información sobre la política de privacidad puedes seguir
                    leyendo el documento.
                </p>
                <p>
                    DIALOGAE, S.L.U., se compromete a cumplir la legislación
                    vigente en cada momento en materia de tratamiento de datos
                    de carácter personal. Mediante la siguiente política de
                    privacidad DIALOGAE, S.L.U., de conformidad con las
                    exigencias previstas en el RGPD y en la LOPDGDD, informa a
                    los usuarios y a las personas de las que trata datos de:
                </p>
                <h4 className='mb-2'>
                    1. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE TUS DATOS?
                </h4>
                <p>
                    El responsable del tratamiento de tus datos es DIALOGAE,
                    S.L.U.:
                </p>
                <table className='mb-3'>
                    <tbody>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Identidad
                                </span>
                            </td>
                            <td>DIALOGAE, S.L.U. – B16372849</td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Dirección
                                </span>
                            </td>
                            <td>
                                Calle Bethencourt Afonso 17, 38002 - Santa Cruz
                                de Tenerife
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Teléfono
                                </span>
                            </td>
                            <td>649645874</td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>Web</span>
                            </td>
                            <td>https://www.dialogae.com/</td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Correo-e
                                </span>
                            </td>
                            <td>info@dialogae.com</td>
                        </tr>
                        <tr>
                            <td>
                                <span className='fw-bold text-black'>
                                    Delegado de Protección de Datos
                                </span>
                            </td>
                            <td>AIXA CORPORE S.L.</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    DIALOGAE, S.L.U. informa al usuario que los datos que nos
                    facilite a través de la navegación por nuestra web, los
                    diferentes formularios de recogida de datos o en el envío de
                    correos electrónicos, van a ser tratados por DIALOGAE,
                    S.L.U. y que dicho tratamiento se encuentra recogido en el
                    Registro de Actividades de tratamiento gestionado por
                    DIALOGAE, S.L.U. conforme a lo establecido en el RGPD.
                </p>
                <h4 className='mb-2'>
                    2. ¿PARA QUÉ SE UTILIZAN TUS DATOS PERSONALES?
                </h4>
                <p>
                    En nuestra web tratamos datos tanto de los usuarios de
                    nuestra web, como de terceros que mantienen relaciones con
                    nosotros. Con la finalidad de facilitar que conozcas el
                    tratamiento que realizamos de tus datos, hemos diferenciado
                    la finalidad a la que destinamos los datos, los tipos de
                    datos y la base de legitimación en función de la categoría
                    de personas de las que tratamos datos. A continuación,
                    clasificamos las personas de las que tratamos datos en
                    función del tipo de relación que mantienen con DIALOGAE,
                    S.L.U.
                </p>
                <h5 className='mb-2'>2.1. Usuarios web</h5>
                <h5 className='mb-2'>
                    ¿Qué datos recopilamos de nuestros usuarios del sitio web?
                </h5>
                <ul className='alphabet-list mb-2'>
                    <li>
                        <span className='fw-bold text-black'>
                            Datos de los usuarios que rellenan cualquiera de los
                            formularios disponibles:
                        </span>{' '}
                        Los datos personales que DIALOGAE, S.L.U. solicita
                        incluyen:
                        <ul className='dots-list'>
                            <li>
                                <span className='fw-bold text-black'>
                                    Datos identificativos:
                                </span>{' '}
                                nombre y apellidos, teléfono, dirección postal y
                                electrónica
                            </li>
                            <li className='fw-bold text-black'>
                                Datos de empleo y profesionales
                            </li>
                            <li className='fw-bold text-black'>
                                Actividades y negocios
                            </li>
                        </ul>
                    </li>
                </ul>
                <h5 className='mb-2'>¿Cómo obtenemos tus datos?</h5>
                <p>Obtenemos tus datos mediante:</p>
                <ul>
                    <li>
                        La firma del contrato que nos vincula, la aceptación del
                        presupuesto presentado, la recepción de facturas del
                        proveedor y la firma del contrato de Encargado de
                        Tratamiento en caso de que proceda por el servicio
                        prestado.
                    </li>
                </ul>
                <h5 className='mb-2 mt-2'>
                    ¿Con qué finalidades vamos a tratar sus datos personales?
                </h5>
                <p>
                    Gestionar la información relativa a los datos de nuestras
                    empresas proveedoras de bienes y servicios, así como de sus
                    representantes.
                </p>
                <p>
                    Mantenimiento de la relación contractual con nuestros
                    proveedores.
                </p>
                <p>
                    Gestión de los pagos y cobros relativos a los contratos que
                    nos vinculan.
                </p>
                <h5 className='mb-2'>
                    ¿Cuál es la legitimación para el tratamiento de sus datos?
                </h5>
                <p>
                    En la{' '}
                    <span className='fw-bold text-black'>
                        ejecución de un precontrato/contrato
                    </span>{' '}
                    para remitirle la información y hacer la gestión propia del
                    servicio prestado, no siendo posible su realización en caso
                    contrario.
                </p>
                <p>
                    En el{' '}
                    <span className='fw-bold text-black'>interés legítimo</span>{' '}
                    para la gestión de los datos relativos a los representantes
                    de los proveedores de DIALOGAE, S.L.U..
                </p>
                <h4 className='mb-2'>
                    3. ¿POR CUÁNTO TIEMPO CONSERVAMOS SUS DATOS?
                </h4>
                <h5 className='mb-2'>3.1. Usuarios web</h5>
                <p>
                    Aquellos datos que son tratados en base al consentimiento
                    del usuario web se mantendrán mientras resulten relevantes
                    para el fin que fueron recogidos y el consentimiento no sea
                    revocado.
                </p>
                <p>
                    Aquellos datos que son tratados en base al interés legítimo
                    el tiempo de conservación será el vinculado al que motivó la
                    recogida del dato asegurando siempre las garantías adecuadas
                    y el respeto a los derechos y libertades de los interesados.
                </p>
                <p>
                    Aquellos datos que son tratados en base a la ejecución de un
                    contrato o la adopción de medidas precontractuales el plazo
                    de conservación será aquel en que la relación contractual se
                    mantenga vigente así como el tiempo posterior que por
                    obligación legal se deban conservar.
                </p>
                <p>
                    La dirección IP obtenida a través de las cookies tendrá un
                    plazo de conservación de un año, con la finalidad de
                    demostrar el consentimiento del usuario.
                </p>
                <p>
                    En cuanto al plazo de conservación de las cookies, puede
                    obtener más información en nuestra web en el apartado
                    “Política de cookies”
                </p>
                <h5 className='mb-2'>3.2. Proveedores</h5>
                <p>
                    Aquellos datos que son tratados en base al interés legítimo
                    el tiempo de conservación será el vinculado al que motivó la
                    recogida del dato asegurando siempre las garantías adecuadas
                    y el respeto a los derechos y libertades de los interesados.
                </p>
                <p>
                    Aquellos datos que son tratados en base a un contrato, así
                    como si parte del tratamiento se legitima en una obligación
                    legal, los datos se mantendrán durante el tiempo en que la
                    relación contractual se encuentre vigente. El tiempo de
                    conservación de los datos una vez finalizada esta, o en el
                    caso de que el tratamiento esté vinculado a una obligación
                    legal será el que legalmente esté previsto no pudiendo
                    revocarse el tratamiento del dato por parte del interesado
                    salvo en las previsiones de bloqueo de los mismos previstos
                    legalmente.
                </p>
                <h4 className='mb-2'>
                    4. INFORMACIÓN ADICIONAL SOBRE EL TRATAMIENTO DE DATOS
                </h4>
                <p>
                    <span className='fw-bold text-black '>
                        Sólo los mayores de 14 años
                    </span>{' '}
                    pueden facilitar datos a través de nuestra web y correo-e.
                    Si eres menor de esta edad deberás contar con el
                    consentimiento de tus padres o tutores legales.
                </p>
                <p>
                    El Usuario garantiza la autenticidad y actualidad de todos
                    aquellos datos que comunique a DIALOGAE, S.L.U. y declara
                    que es mayor de 14 años, y será el único responsable de las
                    manifestaciones falsas o inexactas que realice.
                </p>
                <p>
                    Los datos de carácter personal tratados por DIALOGAE, S.L.U.
                    pueden ser obligatorios o voluntarios. Mediante los
                    formularios web no se le solicitarán datos de categorías
                    especiales para garantizar su privacidad. En caso de que
                    para prestarle un servicio sea necesario recoger datos de
                    categorías especiales se le solicitarán por otra vía. Los
                    datos que se van a solicitar en los formularios son:
                </p>
                <ul className='alphabet-list'>
                    <li>
                        <span className='fw-bold text-black'>
                            Datos identificativos:
                        </span>{' '}
                        nombre y apellidos, teléfono, dirección postal y
                        electrónica.
                    </li>
                    <li className='fw-bold text-black'>
                        Datos relativos a la solicitud.
                    </li>
                    <li className='fw-bold text-black'>
                        Datos de los usuarios que se registran en nuestra
                        plataforma.
                    </li>
                </ul>
                <p>
                    La información voluntaria es aquella que el usuario no está
                    obligado a facilitar para contactar con nosotros.
                </p>
                <p>
                    Los campos marcados con un asterisco (*) o con la palabra
                    (requerido) en nuestros formularios son de cumplimentación
                    obligatoria, la negativa a suministrarlos supondrá la
                    imposibilidad de prestarle el servicio o atender su
                    solicitud.
                </p>
                <p>
                    En el caso que nos facilites datos de terceros, manifiestas
                    contar con el consentimiento de los mismos y te comprometes
                    a trasladarle la información que te facilitamos en esta
                    cláusula, eximiendo a DIALOGAE, S.L.U. de cualquier tipo de
                    obligación. No obstante DIALOGAE, S.L.U. podrá llevar a cabo
                    verificaciones para constatar que el tercero ha sido
                    informado adoptando las medidas de diligencia establecidas
                    en la normativa de protección de datos.
                </p>
                <h5 className='mb-2'>
                    5. ¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS?
                </h5>
                <p>
                    No se cederán datos a terceros salvo obligación legal o en
                    el supuesto que sea necesario para atender su solicitud.
                </p>
                <p>
                    Entre los destinatarios a los que pueden ser comunicados sus
                    datos se encuentran:
                </p>
                <ul>
                    <li>
                        Administraciones y Organismos públicos cuando así lo
                        exija la normativa fiscal, laboral, de Seguridad Social
                        o cualquier otra aplicable. Empresas encargadas del
                        tratamiento de datos (proveedores de detección y
                        prevención de fraude, servicios tecnológicos, servicios
                        de atención al cliente, servicios publicitarios y
                        marketing, y, en general, terceros proveedores que sean
                        necesarios para la prestación de los servicios ofrecidos
                        por DIALOGAE, S.L.U. como proveedores de servicios de
                        mensajería y transporte, etc…
                    </li>
                </ul>
                <h5 className='mb-2'>
                    6. ¿SE PRODUCEN TRANSFERENCIAS INTERNACIONALES DE DATOS?
                </h5>
                <p>
                    Nuestra web está alojada en España, país que se encuentra en
                    la Unión Europea, en el territorio del Espacio Económico
                    Europeo, o que cumple con las directrices establecidas en
                    los artículos 46 y 47 del RGPD, por ello, no se darán
                    transferencias internacionales de los datos aportados por
                    los usuarios en la web.
                </p>

                <p>
                    Nuestra web solo accede a los datos de los usuarios
                    utilizando las siguientes vías:
                </p>
                <ul>
                    <li>
                        A través de los formularios de contacto a disposición
                        del usuario en la web.
                    </li>
                    <li>
                        A través de las cookies que el usuario ha aceptado
                        previamente.
                    </li>
                </ul>
                <p>
                    También pueden producirse transferencias internacionales de
                    datos en sede de las anteriores cesiones/comunicaciones para
                    las cuales DIALOGAE, S.L.U. utilizará las herramientas
                    facilitadas en el artículo 46 y el 49 del RGPD como garantía
                    de aquellas transferencias realizadas a países que no
                    cuentan con una decisión de adecuación de la Comisión
                    Europea.
                </p>
                <p>
                    En todo caso, los terceros con los cuales se compartan
                    determinados datos de carácter personal habrán acreditado
                    con carácter previo la adopción de medidas técnicas y
                    organizativas adecuadas para la correcta protección de estos
                    y se realizarán solo con terceros países cuando exista un
                    instrumento jurídico vinculante conforme a los artículos 46
                    y siguientes del RGPD. Con la aceptación de esta política de
                    privacidad se acepta que se pueda producir una transferencia
                    internacional de datos de acuerdo con las garantías
                    indicadas.
                </p>
                <p>
                    En caso de que no se cumplan los requisitos establecidos en
                    el RGPD, el interesado podrá otorgar su consentimiento a la
                    transferencia internacional o ejecutarse un contrato en
                    interés del interesado. En caso contrario se deberá
                    solicitar una autorización a la Autoridad de Control.
                    DIALOGAE, S.L.U. no vende en ningún caso datos de sus
                    usuarios a terceros.
                </p>
                <h5 className='mb-2'>
                    7. ¿QUÉ DERECHOS LE ASISTEN Y CÓMO PUEDE EJERCITARLOS?
                </h5>
                <p>
                    El usuario puede ejercer los siguientes derechos ante
                    DIALOGAE, S.L.U.:
                </p>
                <ol type='a'>
                    <li>Acceder a sus datos personales</li>
                    <li>Rectificarlos cuando son inexactos o incompletos</li>
                    <li>
                        Solicitar la supresión o cancelación de los datos
                        cuando, entre otros motivos hayan dejado de ser
                        necesarios para los fines para los que fueron recogidos
                    </li>
                    <li>Oponerse a su tratamiento</li>
                    <li>
                        Pedir la limitación del tratamiento de sus datos cuando
                        se den alguna de las condiciones previstas en la
                        normativa
                    </li>
                    <li>
                        Solicitar la portabilidad de sus datos en los casos
                        previstos en la normativa
                    </li>
                    <li>Impedir ser objeto de decisiones automatizadas</li>
                    <li>Revocar los consentimientos otorgados</li>
                </ol>
                <p>
                    El usuario, para ejercer sus derechos, deberá enviar un
                    escrito en el que indique el derecho que desea ejercitar y
                    acompañar documento acreditativo de su identidad y dirección
                    postal o electrónica a efectos de notificación. Esta
                    solicitud puede realizarla, bien mediante correo postal a:
                    DIALOGAE, S.L.U. Calle Bethencourt Afonso 17, 38002 - Santa
                    Cruz de Tenerife, o bien en la siguiente dirección de
                    correo-e: info@dialogae.com.
                </p>
                <p>
                    Se informa al interesado que DIALOGAE, S.L.U. ha nombrado un
                    Delegado de Protección de Datos ante el cual podrá poner de
                    manifiesto cualquier cuestión relativa al tratamiento de sus
                    datos personales. El interesado podrá contactar con el
                    Delegado de Protección de Datos mediante el siguiente correo
                    electrónico aixa4436@dpocanarias.com o en la siguiente
                    dirección postal: Calle Bethencourt Afonso 17, 38002 - Santa
                    Cruz de Tenerife.
                </p>
                <p>
                    DIALOGAE, S.L.U. deberá contestar la solicitud del ejercicio
                    de derechos en el plazo de un mes a partir de la recepción
                    de la solicitud. Dicho plazo podrá prorrogarse otros dos
                    meses en caso necesario, teniendo en cuenta la complejidad y
                    el número de solicitudes. Le informaremos de cualquier
                    prórroga en el plazo de un mes a partir de la recepción de
                    la solicitud indicando los motivos de la dilación.
                </p>
                <p>
                    Asimismo, los interesados tienen derecho a reclamar ante la
                    Autoridad de Control competente (Agencia Española de
                    Protección de Datos) cuando consideren que DIALOGAE, S.L.U.
                    ha vulnerado los derechos que la normativa de protección de
                    datos les reconoce en{' '}
                    <a href='https://www.aepd.es/' target='_blank'>
                        www.aepd.es{' '}
                    </a>
                </p>
                <h5 className='mb-2'>8. ¿ESTÁN SEGUROS MIS DATOS?</h5>
                <p>
                    DIALOGAE, S.L.U. tiene vigente un contrato con la empresa de
                    Hosting Loading para el alojamiento de su página web. Es una
                    empresa europea y dispone de los últimos sistemas en medidas
                    de seguridad.
                </p>
                <p>
                    En todo caso, DIALOGAE, S.L.U. garantiza la adopción de las
                    medidas oportunas para asegurar el tratamiento confidencial
                    de sus datos habida cuenta del carácter reservado de los
                    mismos y se compromete a que dichos datos permanezcan
                    secretos, tratándolos con la máxima reserva, y declara tener
                    implantadas en su sistema de información, las políticas de
                    seguridad correspondientes al tipo de datos manejados
                    conforme a lo dispuesto en el REGLAMENTO (UE) 2016/679 DEL
                    PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016
                    relativo a la protección de las personas físicas en lo que
                    respecta al tratamiento de datos personales y a la libre
                    circulación de estos datos (RGPD).
                </p>
                <p>
                    Todos los datos facilitados a través de nuestra plataforma
                    se recopilan mediante un protocolo seguro. Nuestra web posee
                    un certificado SSL que permite que cuando algún usuario
                    visita nuestra web e intercambia información con nosotros se
                    establece una conexión cifrada.
                </p>
                <h5 className='mb-2'>
                    9. ¿NECESITA UNA CUENTA Y/O CONTRASEÑA?
                </h5>
                <p>
                    El usuario puede registrarse en nuestra web, para ello
                    resulta indispensable la creación de una cuenta. Es
                    responsabilidad del usuario mantener la confidencialidad de
                    la información de la cuenta, incluyendo la contraseña, así
                    como todas y cada una de las actividades que en ella
                    sucedan.
                </p>
                <p>
                    El usuario tiene la obligación de informar a DIALOGAE,
                    S.L.U. de forma inmediata en caso de que se haga un uso
                    ilegítimo de su cuenta o contraseña, o si ocurriera
                    cualquier problema relativo a la seguridad, mediante el
                    envío de un correo electrónico a info@dialogae.com.
                </p>
                <h5 className='mb-2'>10. ENLACES A OTROS SITIOS WEB</h5>
                <p>
                    Si opta por abandonar nuestro sitio Web a través de enlaces
                    a otros sitios Web no pertenecientes a nuestra entidad,
                    DIALOGAE, S.L.U. no se hará responsable de las políticas de
                    privacidad de dichos sitios Web ni de las cookies que estos
                    puedan almacenar en el ordenador del usuario.
                </p>
                <div className='fw-bold text-black'>Fecha: 30 abril 2024</div>
                <div className='fw-bold text-black'>Versión: V.1.0</div>
            </div>
        </>
    );
};

export default EsPrivacyPolicy;
