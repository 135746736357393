import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const EsVolunteering = () => {
    const { search } = useLocation();
    const { i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <div className='content-wrapper'>
            <h2 className='blue-text'>
                Voluntariado en Tenerife, es una oportunidad para estudiantes y
                personas mayores.
            </h2>
            <br />
            <p>
                Invito a todas aquellas personas que se sientan solas o que
                deseen cambiar las cosas a probar Dialogæ.{' '}
                <span className='blue-text'>
                    Es accessible y fácil de usar:
                </span>{' '}
                al hablar y conectar con otras personas, te conviertes en
                voluntario activo y puedes mejorar tu experiencia universitaria.
                El sistema europeo de transferencia y acumulación de créditos{' '}
                <span className='blue-text'>
                    reconoce las actividades de voluntariado de Dialogæ,
                    permitiéndote intercambiarlas por créditos en tu
                    universidad.
                </span>
                .
            </p>
            <p>
                Mi misión es que Dialogæ llegue a tantas personas como sea
                posible y{' '}
                <span className='blue-text'>
                    onecte a las poblaciones que más sufren soledad no deseada:
                </span>{' '}
                universitarios y personas mayores. Es una oportunidad única para
                ser voluntario en Tenerife y ser uno de los primeros en probar
                este concepto revolucionario.
            </p>
            <p>
                <span className='blu-text'>¿Quieres dejar huella?</span>
                ¿Alegrarle el día a alguien o incluso todo el año? Date a ti
                mismo y a otras personas el mejor regalo: una conexión real.{' '}
                <span className='blue-text'>
                    ¡Regístrate para ser uno de los pioneros de Dialogæ!
                </span>
            </p>
            <div className='volunteers-logo'>
                <div>
                    <img src='/images/ayuntamiento.png' alt='' />
                </div>
                <div>
                    <img src='/images/logo-accom.svg' alt='' />
                </div>
            </div>
        </div>
    );
};

export default EsVolunteering;
