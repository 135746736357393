import apiClient from '../client/api-client';

export const getRewardTargets = async (language) => {
    const url = 'user/reward/list';
    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const setRewardTarget = async (language, data) => {
    const url = 'user/reward/set';
    return apiClient({
        url,
        method: 'POST',
        data,
        headers: { 'Accept-Language': language },
    });
};

export const getCurrentRewardTarget = async (language) => {
    const url = 'user/reward/get';
    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const claimReward = async (language, id) => {
    const url = `user/reward/${id}/claim`;
    return apiClient({
        url,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const getRewardHistory = async (language) => {
    const url = 'user/reward/history/list';
    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const sendReward = async (language, id) => {
    const url = `user/reward/gift-code/${id}/send`;
    return apiClient({
        url,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};
