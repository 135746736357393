import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    getRewardHistory,
    getRewardTargets,
    setRewardTarget,
} from '../../../api/adapters/rewards';
import { updateUser } from '../../../redux/actions/user';
import {
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import {
    formatTime,
    getExpandedDateString,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Loader from '../../components/common-component/Loader';

function ManageTarget() {
    const [actionBtn, setActionBtn] = useState('targets_rewards');
    const { t, i18n } = useTranslation();
    const [isButtonActivated, setIsButtonActivated] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [selectedTarget, setSelectedTarget] = useState('');
    const [errors, setErrors] = useState({ iReward: '' });

    const {
        data: rewardTargets,
        isLoading: isLoadingRewardTargets,
        error: targetError,
    } = useQuery({
        queryKey: ['reward-targets', i18n.language.split('-')[0]],
        queryFn: () => getRewardTargets().then((res) => res.data),
    });

    const { mutate: mutateSetTarget } = useMutation({
        mutationFn: () =>
            setRewardTarget(i18n.language.split('-')[0], {
                iReward: selectedTarget,
            }),
        onSuccess: (response) => {
            notify('success', response.message);

            dispatch(
                updateUser({ ...user, iCurrentRewardTarget: selectedTarget })
            );

            navigate('/rewards');
        },
        onError: (error) => handleApiError(error, t, navigate, setErrors),
    });

    const {
        data: rewardHistory,
        isLoading: isLoadingRewardHistory,
        error: rewardHistoryError,
    } = useQuery({
        queryKey: ['reward-history', i18n.language.split('-')[0]],
        queryFn: () => getRewardHistory().then((res) => res.data),
    });

    useEffect(() => {
        if (targetError || rewardHistoryError)
            handleQueryError(targetError || rewardHistoryError, navigate);
    }, [targetError, rewardHistoryError]);

    useEffect(() => {
        if (errors.iReward) notify('error', errors.iReward);
    }, [errors]);

    useEffect(() => {
        if (user.id) setSelectedTarget(user.iCurrentRewardTarget);
    }, [user]);

    return (
        <div className='manage-target'>
            <BreadcrumbAction
                lastPage={t('rewards.rewards')}
                currentPage={t('rewards.manage')}
                lastPageLink='/rewards'
            />

            <div className='content-wrapper'>
                <div className='custom-tabs'>
                    {actionBtn === 'targets_rewards' ? (
                        <div className='abs-btn'>
                            <button
                                className={`primary-btn  ${
                                    isButtonActivated ? 'disable-btn' : ''
                                }`}
                                disabled={!selectedTarget}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!selectedTarget) return;
                                    mutateSetTarget();
                                }}
                            >
                                {t('rewards.set_target')}
                            </button>
                        </div>
                    ) : null}
                    <Tabs
                        defaultActiveKey='targets_rewards'
                        id='uncontrolled-tab-example'
                        className='tabs-wrapper'
                        onSelect={(e) => {
                            setActionBtn(e);
                        }}
                    >
                        <Tab
                            eventKey='targets_rewards'
                            title={t('rewards.targets_rewards')}
                        >
                            <div className='row'>
                                {isLoadingRewardTargets ? (
                                    <Loader innerLoader={true} />
                                ) : (
                                    rewardTargets?.map((target) => (
                                        <CardHours
                                            setIsButtonActivated={
                                                setIsButtonActivated
                                            }
                                            key={target._id}
                                            title={formatTime(
                                                target?.nMinutes,
                                                t
                                            )}
                                            description={target?.sDescription}
                                            imageUrl={target?.sRewardPic}
                                            id={target._id}
                                            setSelectedTarget={
                                                setSelectedTarget
                                            }
                                            checked={
                                                selectedTarget === target?._id
                                            }
                                        />
                                    ))
                                )}
                            </div>
                        </Tab>
                        <Tab
                            eventKey='rewards_history'
                            title={t('rewards.rewards_history')}
                        >
                            <div className='row'>
                                {isLoadingRewardHistory ? (
                                    <Loader innerLoader={true} />
                                ) : (
                                    rewardHistory?.map((target) => (
                                        <CardHours
                                            historyCard={true}
                                            title={formatTime(
                                                target?.iReward?.nMinutes,
                                                t
                                            )}
                                            description={target?.sDescription}
                                            imageUrl={target?.sRewardPic}
                                            redeemDate={getExpandedDateString(
                                                target?.dRedeemedAt,
                                                i18n.language.split('-')[0]
                                            )}
                                        />
                                    ))
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default ManageTarget;

const CardHours = (props) => {
    const {
        historyCard,
        completed,
        checked,
        setIsButtonActivated,
        title,
        description,
        imageUrl,
        id,
        setSelectedTarget,
        redeemDate,
    } = props;

    return (
        <div className='col-sm-6 col-lg-4 col-xl-3'>
            <div className='hours-card'>
                {!historyCard && (
                    <input
                        type='radio'
                        name='hours'
                        id=''
                        checked={checked}
                        onChange={(e) => {
                            setSelectedTarget(id);
                            setIsButtonActivated(false);
                        }}
                    />
                )}
                <div className={`box ${completed ? 'completed' : ''}`}>
                    <div className='status'>
                        {!historyCard ? (
                            <div className='circle'></div>
                        ) : (
                            <img src='/images/radio-button.svg' alt='' />
                        )}
                        {!historyCard ? (
                            <div className='hr-card-icon'>
                                <img src={imageUrl} alt='' />
                            </div>
                        ) : (
                            <div className='hr-card-icon'>
                                <img src={imageUrl} alt='' />
                            </div>
                        )}
                    </div>
                    <h4>{title}</h4>
                    <p>{description}</p>

                    {historyCard ? <p className='sm'>{redeemDate}</p> : null}
                </div>
            </div>
        </div>
    );
};

CardHours.propTypes = {
    historyCard: PropTypes.bool,
    completed: PropTypes.bool,
    checked: PropTypes.bool,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    id: PropTypes.string.isRequired,
    setSelectedTarget: PropTypes.func,
};
