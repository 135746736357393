import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadcrumbAction from '../../../components/common-component/BreadcrumbAction';
const EsCookiesPage = () => {
    const { search } = useLocation();
    const { t, i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <>
            <BreadcrumbAction lastPage={t('footer_links.cookies_title')} />
            <div className='content-wrapper'>
                <h5 className='mbv-2'>1. ¿QUÉ SON LAS COOKIES?</h5>
                <p>
                    Una cookie es un archivo de texto que un servidor web puede
                    guardar en el disco duro de tu equipo para almacenar algún
                    tipo de información sobre ti como usuario. Las cookies se
                    utilizan con diversas finalidades tales como almacenar datos
                    para próximas visitas, para reconocer al usuario y evitar
                    pedir de nuevo la autentificación, para saber que paginas
                    visitas, o para guardar tus preferencias en áreas
                    personalizables... Normalmente los sitios web utilizan las
                    cookies para obtener información estadística sobre sus
                    páginas web, y analizar el comportamiento de sus
                    clientes/usuarios.
                </p>
                <p>
                    Quedan exceptuadas del cumplimiento de las obligaciones
                    establecidas en el artículo 22.2 de la LSSI las cookies
                    utilizadas para alguna de las siguientes finalidades:
                </p>
                <ul>
                    <li>
                        Permitir únicamente la comunicación entre el equipo del
                        usuario y la red.
                    </li>
                    <li>
                        Estrictamente prestar un servicio expresamente
                        solicitado por el usuario.
                    </li>
                </ul>
                <p>
                    Así, quedan entre las cookies exceptuadas estarían aquellas
                    que tienen por finalidad:
                </p>
                <ul>
                    <li>
                        Cookies de “entrada del usuario”. Son aquellas que se
                        almacenan en el equipo cuando el usuario introduce
                        información en un sitio web. Por ejemplo, al rellenar un
                        formulario web.
                    </li>
                    <li>
                        Cookies de autenticación o identificación de usuario
                        (únicamente de sesión). Su función es identificar a un
                        usuario cuando éste introduzca sus credenciales al
                        iniciar sesión.
                    </li>
                    <li>
                        Cookies de seguridad del usuario. Son las cookies que
                        detectan problemas o riesgos de seguridad. Por ejemplo,
                        aquellas que detectan intentos erróneos y reiterados de
                        conexión.
                    </li>
                    <li>
                        Cookies de sesión de reproductor multimedia. Permiten
                        reproducir contenido multimedia en las webs que visita
                        el usuario. Por ejemplo, los vídeos que se reproducen
                        automáticamente al entrar en una web están autorizados
                        por este tipo de cookies.
                    </li>
                    <li>
                        Cookies de sesión para equilibrar la carga. Conectan el
                        servidor del sitio web y el del usuario para que la
                        información se pueda transmitir entre ambos.
                    </li>
                    <li>
                        Cookies de personalización de la interfaz de usuario.
                        Son las cookies que recuerdan las preferencias de los
                        usuarios durante la navegación por una web. Por ejemplo,
                        el idioma; el navegador elegido; etc.
                    </li>
                    <li>
                        Determinadas cookies de complemento (plug-in) para
                        intercambiar contenidos sociales. Se utilizan cuando el
                        usuario visita redes sociales a través de una web. Por
                        ejemplo, al compartir en redes sociales el artículo de
                        una web.
                    </li>
                </ul>
                <p>
                    Los usos concretos que hacemos de estas tecnologías se
                    describen en los siguientes apartados.
                </p>
                <h5 className='mb-2'>2. TIPOS DE COOKIES</h5>
                <p>
                    Las cookies pueden clasificarse según los siguientes
                    criterios:
                </p>
                <p>
                    Según{' '}
                    <span className='fw-bold text-black'>quien instala</span> la
                    cookie, éstas pueden ser:
                </p>
                <p>
                    <span className='fw-bold text-black'>Cookies propias:</span>{' '}
                    Son aquéllas que se envían al equipo terminal del usuario
                    desde un equipo o dominio gestionado por el propio editor y
                    desde el que se presta el servicio solicitado por el
                    usuario.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies de terceros:
                    </span>{' '}
                    Son aquéllas que se envían al equipo terminal del usuario
                    bien desde un desde un equipo o dominio gestionado por
                    nosotros o por un tercero, pero la información que se recoja
                    de las cookies es gestionada por un tercero distinto del
                    titular de la web.
                </p>
                <p>
                    Según su <span className='fw-bold text-black'>plazo</span>{' '}
                    de conservación, las cookies pueden ser:
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies de sesión:
                    </span>{' '}
                    Son aquellas que duran el tiempo que el usuario está
                    navegando por la página Web y se borran cuando finaliza la
                    navegación.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies persistentes:
                    </span>{' '}
                    Quedan almacenadas en el terminal del usuario, por un tiempo
                    más largo, facilitando así el control de las preferencias
                    elegidas sin tener que repetir ciertos parámetros cada vez
                    que se visite el sitio Web.
                </p>
                <p>
                    Según su{' '}
                    <span className='fw-bold text-black'>finalidad</span>, las
                    cookies pueden ser:
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies técnicas:
                    </span>{' '}
                    Las cookies técnicas son aquellas imprescindibles y
                    estrictamente necesarias para el correcto funcionamiento de
                    un sitio Web y el uso de las diversas opciones y servicios
                    que ofrece. Por ejemplo, las de mantenimiento de sesión, las
                    que permiten utilizar elementos de seguridad, compartir
                    contenido con redes sociales, etc.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies de personalización:
                    </span>{' '}
                    Permiten al usuario escoger o personalizar características
                    de la página Web como el idioma, configuración regional o
                    tipo de navegador.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies analíticas:
                    </span>{' '}
                    Son las utilizadas por los portales Web, para elaborar
                    perfiles de navegación y poder conocer las preferencias de
                    los usuarios con el fin de mejorar la oferta de productos y
                    servicios. Permiten controlar áreas geográficas de mayor
                    interés de un usuario, la información de la web de más
                    aceptación, etc.
                </p>
                <p>
                    <span className='fw-bold text-black'>
                        Cookies publicitarias / de publicidad:
                    </span>{' '}
                    son aquellas que almacenan información del comportamiento de
                    los usuarios obtenida a través de la observación continuada
                    de sus hábitos de navegación, lo que permite desarrollar un
                    perfil específico para mostrar publicidad en función de
                    este.
                </p>
                <h5 className='mb-2'>3. COOKIES UTILIZADAS EN NUESTRA WEB</h5>
                <p>utiliza los siguientes tipos de cookies en la web:</p>
                <p>
                    <span className='fw-bold text-black'>Cookies técnicas</span>
                </p>
                <p>
                    Permiten al usuario navegar por la Web y recordar sus
                    contraseñas.
                </p>
                <h5 className='mb-2'>
                    Cookies instaladas en nuestro dominio web:
                </h5>
                <table className='mb-3'>
                    <thead>
                        <tr>
                            <th>Nombre de la cookie</th>
                            <th>Finalidad</th>
                            <th>Propia/ de terceros</th>
                            <th>Duración</th>
                            <th>¿Cuándo se instala?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>__callToken</td>
                            <td>
                                Este token se utiliza para la realización de
                                llamadas a través del la aplicación o sitio web
                            </td>
                            <td>Terceros</td>
                            <td>De sesión</td>
                            <td>Cuando se accede al sitio web</td>
                        </tr>
                        <tr>
                            <td>__token</td>
                            <td>
                                Este token se utiliza para rastrear e
                                identificar al usuario en varias sesiones o
                                visitas a un sitio web
                            </td>
                            <td>Terceros</td>
                            <td>De sesión</td>
                            <td>Cuando se accede al sitio web</td>
                        </tr>
                        <tr>
                            <td>__userId</td>
                            <td>
                                Esta cookie identifica una sesión de usuario
                                única
                            </td>
                            <td>Terceros</td>
                            <td>De sesión</td>
                            <td>Cuando se accede al sitio web</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className='mb-2'>
                    4. INFORMACIÓN Y OBTENCIÓN DEL CONSENTIMIENTO PARA LA
                    INSTALACIÓN
                </h5>
                <p>
                    ha procurado el establecimiento de mecanismos adecuados para
                    la obtención del consentimiento del usuario en relación con
                    las cookies no estrictamente necesarias. Esto incluye a las
                    cookies analíticas o de medición, que no se instalarán salvo
                    aceptación expresa del usuario.
                </p>
                <p>
                    Con el acceso a nuestra web, aparece un pop-up en el que se
                    informa de todos los detalles acerca de las cookies;
                    ofreciendo una aceptación granular de éstas.
                </p>
                <p>
                    Los usuarios no tendrán que dar su consentimiento cada vez
                    que visiten la web. Conservaremos su consentimiento y
                    preferencias de cookies durante el plazo máximo de 24 meses.
                    Antes de que transcurra dicho plazo le solicitaremos la
                    renovación de su consentimiento en relación con las cookies.
                </p>
                <h5 className='mb-2'>
                    5. ¿CÓMO PUEDO IMPEDIR LA INSTALACIÓN DE COOKIES?
                </h5>
                <p>
                    El usuario puede configurar su navegador para aceptar, o no,
                    las cookies que recibe o para que el navegador le avise
                    cuando un servidor quiera guardar una cookie. Si se
                    deshabilitan algunas cookies técnicas no se garantiza el
                    correcto funcionamiento de algunas de las utilidades de la
                    web.
                </p>
                <p>
                    A continuación, también le facilitamos algunos ejemplos de
                    cómo deshabilitar las cookies:
                </p>
                <ul className='alphabet-list'>
                    <li className='fw-bold text-black'>
                        Mediante la configuración del propio navegador:
                        <ul className='dots-list'>
                            <li>
                                Internet Explorer:{' '}
                                <a
                                    href='http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies'
                                    target='_blank'
                                >
                                    http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies
                                </a>
                            </li>
                            <li>
                                Firefox:{' '}
                                <a
                                    href='https://support.mozilla.org/es/kb/Borrar%20cookies'
                                    target='_blank'
                                >
                                    https://support.mozilla.org/es/kb/Borrar%20cookies
                                </a>
                            </li>
                            <li>
                                Safari:{' '}
                                <a
                                    href='http://support.apple.com/kb/HT1677?viewlocale=es_ES'
                                    target='_blank'
                                >
                                    http://support.apple.com/kb/HT1677?viewlocale=es_ES
                                </a>
                            </li>
                            <li>
                                Chrome:{' '}
                                <a
                                    href='https://support.google.com/accounts/answer/61416?hl=es'
                                    target='_blank'
                                >
                                    https://support.google.com/accounts/answer/61416?hl=es
                                </a>
                            </li>
                            <li>
                                Opera:{' '}
                                <a
                                    href='http://help.opera.com/Windows/11.50/es-ES/cookies.html'
                                    target='_blank'
                                >
                                    http://help.opera.com/Windows/11.50/es-ES/cookies.html
                                </a>
                            </li>
                        </ul>
                        <p>
                            Para ampliar esta información acuda a la página de
                            la Agencia Española de Protección de Datos que ayuda
                            a configurar la privacidad en redes sociales,
                            navegadores y sistemas operativos móviles. Más{' '}
                            <a
                                target='_blank'
                                href='http://www.agpd.es/portalwebAGPD/CanalDelCiudadano/protegetuprivacidad/index-ides-idphp.php'
                            >
                                información
                            </a>
                            .
                        </p>
                    </li>
                    <li className='fw-bold text-black'>
                        Mediante herramientas de terceros:
                        <p>
                            Existen herramientas de terceros, disponibles
                            online, que permiten a los usuarios detectar las
                            cookies en cada sitio web que visita y gestionar su
                            desactivación, por ejemplo:
                        </p>
                        <div>
                            <a
                                href='http://www.ghostery.com
'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                http://www.ghostery.com
                            </a>
                        </div>
                        <a
                            href='http://www.youronlinechoices.com/es/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            http://www.youronlinechoices.com/es/
                        </a>
                        <p>
                            En todo caso, el usuario puede permitir o bloquear
                            las cookies, así como borrar sus datos de navegación
                            (incluidas las cookies) desde el navegador que
                            utiliza. Consulte las opciones e instrucciones que
                            ofrece su navegador para ello. Tenga en cuenta que
                            si acepta las cookies de terceros, deberá
                            eliminarlas desde las opciones del navegador.
                        </p>
                    </li>
                </ul>
                <h5 className='mb-2'>6. ENLACES A OTROS SITIOS WEB</h5>
                <p>
                    Si opta por abandonar nuestro sitio Web a través de enlaces
                    a otros sitios Web no pertenecientes a nuestra entidad, no
                    nos haremos responsables de las políticas de privacidad de
                    dichos sitios Web ni de las cookies que estos puedan
                    almacenar en el ordenador del usuario.
                </p>
                <h5 className='mb-2'>
                    7. ¿CÓMO RECOPILAMOS Y USAMOS LAS DIRECCIONES IP?
                </h5>
                <p>
                    Los servidores de la Web podrán detectar de manera
                    automática la dirección IP y el nombre de dominio utilizados
                    por los usuarios.
                </p>
                <p>
                    Una dirección IP es un número asignado automáticamente a un
                    ordenador cuando éste se conecta a Internet. Esta
                    información permite el posterior procesamiento de los datos
                    con el fin de saber si ha prestado su consentimiento para la
                    instalación de cookies, realizar mediciones únicamente
                    estadísticas que permitan conocer el número de visitas
                    realizadas a la Web, el orden de visitas, el punto de
                    acceso, etc.
                </p>
                <h5 className='mb-2'>
                    8. TRANSFERENCIA INTERNACIONAL DE DATOS
                </h5>
                <p>
                    Las cookies instaladas en nuestro dominio web se instalan en
                    nuestro servidor por lo que los datos en ellas recogidos no
                    son sometidos a transferencias internacionales de datos.
                </p>
                <h5 className='mb-2'>
                    9. ACTUALIZACIÓN DE NUESTRA POLÍTICA DE COOKIES
                </h5>
                <p>
                    Esta política se revisa periódicamente para asegurar su
                    vigencia, por lo que puede ser modificada. Le recomendamos
                    que visite la página con regularidad donde le informaremos
                    de cualquier actualización al respecto.
                </p>
                <div className='fw-bold text-black'>Fecha: 30 abril 2024</div>
                <div className='fw-bold text-black'>Versión: V.1.0</div>
            </div>
        </>
    );
};

export default EsCookiesPage;
