import React from 'react';
import EsLegalNotice from './LegalNoticeLangComponents/EsLegalNotice';

function LegalNotice() {
    return (
        <div className='cms-page'>
            <EsLegalNotice />
        </div>
    );
}

export default LegalNotice;
