import React from 'react';
import { Link } from 'react-router-dom';

function NoAppointment({ desc }) {
    return (
        <div className='no-appointments'>
            <Link to='/'>
                <div className='no-data'>
                    <img
                        src='/images/no-appointments-img.svg'
                        alt='appointments-img'
                    />
                    <p className='lg'>{desc}</p>
                </div>
            </Link>
        </div>
    );
}

export default NoAppointment;
