import PropTypes from 'prop-types';
import React from 'react';
import BlueUserIcon from '../../../assets/images/BlueUser-icon.svg';
import Stand from '../../../assets/images/stand.svg';
import LightUserIcon from '../../../assets/images/userLucky.svg';
import WheelerArrow from '../../../assets/images/wheeler-arrow.svg';

function CallWheeler(props) {
    const { wheelRotate } = props;

    const wheelerTriangle = [
        { userIcon: LightUserIcon },
        { userIcon: LightUserIcon },
        { userIcon: LightUserIcon },
        { userIcon: BlueUserIcon },
        { userIcon: LightUserIcon },
        { userIcon: LightUserIcon },
        { userIcon: LightUserIcon },
        { userIcon: BlueUserIcon },
    ];

    return (
        <div className='wheeler-wrapper'>
            <div style={{ position: 'relative' }}>
                <div
                    className={`wheel-container ${
                        wheelRotate ? 'isRotate' : ''
                    }`}
                    style={{ transform: `rotate(${wheelRotate}deg)` }}
                >
                    <div className='circle-wrapper'>
                        {wheelerTriangle.map((triangleItem, index) => {
                            return (
                                <div className='triangle' key={index}>
                                    <div className='dp'>
                                        <img
                                            src={triangleItem?.userIcon}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='wheel-arrow'>
                    <img src={WheelerArrow} alt='' />
                </div>
            </div>
            <div className='stand'>
                <img src={Stand} alt='' />
            </div>
        </div>
    );
}

CallWheeler.propTypes = { wheelRotate: PropTypes.number };

export default CallWheeler;
