import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { setAvailability } from '../../../api/adapters/availability';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import TimeSlots from '../../components/common-component/TimeSlots';
import TitleHeader from '../../components/common-component/TitleHeader';
import DateSelector from '../../components/mini-components/DateSelector';
import LoadingButton from '../../components/mini-components/LoadingButton';

function EditAvailability() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const daySlotsPlain = [
        { from: '06:00', to: '06:30' },
        { from: '06:30', to: '07:00' },
        { from: '07:00', to: '07:30' },
        { from: '07:30', to: '08:00' },
        { from: '08:00', to: '08:30' },
        { from: '08:30', to: '09:00' },
        { from: '09:00', to: '09:30' },
        { from: '09:30', to: '10:00' },
        { from: '10:00', to: '10:30' },
        { from: '10:30', to: '11:00' },
        { from: '11:00', to: '11:30' },
        { from: '11:30', to: '12:00' },
        { from: '12:00', to: '12:30' },
        { from: '12:30', to: '13:00' },
        { from: '13:00', to: '13:30' },
        { from: '13:30', to: '14:00' },
        { from: '14:00', to: '14:30' },
        { from: '14:30', to: '15:00' },
        { from: '15:00', to: '15:30' },
        { from: '15:30', to: '16:00' },
        { from: '16:00', to: '16:30' },
        { from: '16:30', to: '17:00' },
        { from: '17:00', to: '17:30' },
        { from: '17:30', to: '18:00' },
    ];
    const nightSlotsPlain = [
        { from: '18:00', to: '18:30' },
        { from: '18:30', to: '19:00' },
        { from: '19:00', to: '19:30' },
        { from: '19:30', to: '20:00' },
        { from: '20:00', to: '20:30' },
        { from: '20:30', to: '21:00' },
        { from: '21:00', to: '21:30' },
        { from: '21:30', to: '22:00' },
        { from: '22:00', to: '22:30' },
        { from: '22:30', to: '23:00' },
        { from: '23:00', to: '23:30' },
        { from: '23:30', to: '00:00' },
        { from: '00:00', to: '00:30' },
        { from: '00:30', to: '01:00' },
        { from: '01:00', to: '01:30' },
        { from: '01:30', to: '02:00' },
        { from: '02:00', to: '02:30' },
        { from: '02:30', to: '03:00' },
        { from: '03:00', to: '03:30' },
        { from: '03:30', to: '04:00' },
        { from: '04:00', to: '04:30' },
        { from: '04:30', to: '05:00' },
        { from: '05:00', to: '05:30' },
        { from: '05:30', to: '06:00' },
    ];

    const [daySlots, setDaySlots] = useState(daySlotsPlain);
    const [nightSlots, setNightSlots] = useState(nightSlotsPlain);
    const [formErrors, setFormErrors] = useState({});

    const { mutate: mutateSetAvailability, isLoading: isMutating } =
        useMutation({
            mutationFn: (data) =>
                setAvailability(
                    i18n.language.split('-')[0],
                    data,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            onSuccess: (response) => {
                notify('success', response.message);

                navigate('/availability', { replace: true });
            },
            onError: (error) =>
                handleApiError(error, t, navigate, setFormErrors),
        });

    const updateSelectedSlots = (type, updatedSlots) => {
        if (type === 'day') setDaySlots([...updatedSlots]);
        else if (type === 'night') setNightSlots([...updatedSlots]);
    };

    const handleSave = () => {
        const getSelectedDaySlots = () =>
            daySlots
                .filter((slot) => slot.isSelected)
                .map(({ from, to }) => ({
                    sStartTime: from,
                    sEndTime: to,
                }));

        const getSelectedNightSlots = () =>
            nightSlots
                .filter((slot) => slot.isSelected)
                .map(({ from, to }) => ({
                    sStartTime: from,
                    sEndTime: to,
                }));

        const allSlots = [...getSelectedDaySlots(), ...getSelectedNightSlots()];

        const payload = {
            dStartDate: searchParams.get('date'),
            dEndDate: searchParams.get('date'),
            aSlots: allSlots,
        };

        mutateSetAvailability(payload);
    };

    useEffect(() => {
        document.title = formatPageTitle(t('availability.edit_availability'));
    }, [i18n.language]);

    useEffect(() => {
        const slotsParam = searchParams.get('slots');

        if (slotsParam) {
            const selectedSlots = JSON.parse(slotsParam);

            const daySelectedSlots = selectedSlots.filter((slot) => {
                return (
                    Number(slot.from?.split(':')[0]) >= 6 &&
                    Number(slot.from?.split(':')[0]) <= 17
                );
            });

            const nightSelectedSlots = selectedSlots.filter((slot) => {
                return (
                    Number(slot.from?.split(':')[0]) >= 18 ||
                    Number(slot.from?.split(':')[0]) < 6
                );
            });

            const isSlotSelected = (allSlots, slotToCheck) => {
                for (const slot of allSlots) {
                    if (JSON.stringify(slot) === JSON.stringify(slotToCheck))
                        return true;
                }

                return false;
            };

            const updatedDaySlots = [];
            for (const slot of daySlots) {
                const temp = {
                    ...slot,
                    isSelected: isSlotSelected(daySelectedSlots, slot),
                };
                updatedDaySlots.push(temp);
            }

            const updatedNightSlots = [];
            for (const slot of nightSlots) {
                const temp = {
                    ...slot,
                    isSelected: isSlotSelected(nightSelectedSlots, slot),
                };
                updatedNightSlots.push(temp);
            }

            setDaySlots([...updatedDaySlots]);
            setNightSlots([...updatedNightSlots]);
        }
    }, []);

    return (
        <div className='set-availability '>
            <BreadcrumbAction
                lastPage={t('availability.availability')}
                currentPage={t('availability.edit_availability')}
                lastPageLink='/availability'
            />
            <div className='content-wrapper'>
                <TitleHeader
                    title={t('availability.edit_availability')}
                    btn_title={isMutating ? <LoadingButton /> : t('save')}
                    onClick={handleSave}
                    rejectBtn={t('cancel')}
                    isBtnDisabled={isMutating}
                    handleRejectClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                />
                <div className='row form-space'>
                    <div className='col-sm-4 col-sm-6 col-xl-4'>
                        <DateSelector
                            label={t('date')}
                            value={searchParams.get('date')}
                            isEnabled={false}
                        />
                    </div>
                </div>
                <div className='time-slots-wrapper'>
                    <p className='sm slot-title'>
                        {t('availability.select_time')}
                    </p>
                    <div className='slots-scrollbar'>
                        <div className='day-slots'>
                            <TimeSlots
                                slotLabel={t('availability.day_slots')}
                                slots={daySlots}
                                updateSelectedSlots={updateSelectedSlots}
                            />
                        </div>
                        <div className='night-slots'>
                            <TimeSlots
                                slotLabel={t('availability.night_slots')}
                                slots={nightSlots}
                                updateSelectedSlots={updateSelectedSlots}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditAvailability;
