import { getDate, getMonth, getYear } from 'date-fns';
import deLocale from 'date-fns/locale/de'; // German Locale
import esLocale from 'date-fns/locale/es'; // Spanish Locale
import range from 'lodash/range';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import FormErrorLabel from './FormErrorLabel';
registerLocale('es', esLocale); // Register Spanish
registerLocale('de', deLocale); // Register German

function DateSelector(props) {
    const {
        label,
        startYear,
        endYear,
        handleDateChange,
        placeholder,
        value,
        errorMessage,
        includeDates,
        dateFormat = 'dd MMMM yyyy',
        isEnabled = true,
    } = props;

    const { t, i18n } = useTranslation();

    const [selectedDate, setSelectedDate] = useState(
        value ? new Date(value) : null
    );

    useEffect(() => {
        setSelectedDate(value ? new Date(value) : null);
    }, [value]);

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='date-input'>
            <input
                type='text'
                value={value.trim()}
                onClick={onClick}
                className={`${errorMessage ? 'error-input' : ''}`}
                placeholder={placeholder}
                readOnly
                ref={ref} // Forward the ref to the input element
            />
            <span className='date-icon' onClick={onClick}>
                <img src='/images/icons/date-picker-icon.svg' alt='' />
            </span>
        </div>
    ));

    const years = range(startYear, endYear, 1);
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const customDayName = (dayOfWeek) => {
        return dayOfWeek.charAt(0); // Display only the first character of the day name
    };

    const renderDayContents = (day, date) => {
        const tooltipText = `Tooltip for date: ${date}`;
        const isPast =
            new Date(date).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0);
        const className = isPast ? 'this-is-past' : '';

        return (
            <span className={className} title={tooltipText}>
                {getDate(date)}
            </span>
        );
    };

    return (
        <div className='date-picker'>
            <label>{label}</label>
            <DatePicker
                calendarStartDay={1}
                renderDayContents={renderDayContents}
                // minDate={new Date()}
                includeDates={includeDates}
                readOnly={!isEnabled}
                locale={i18n.language.split('-')[0]}
                selected={selectedDate}
                formatWeekDay={customDayName}
                onChange={(date) => {
                    try {
                        handleDateChange(date);
                        setSelectedDate(date);
                    } catch (error) {}
                }}
                dateFormat={dateFormat}
                placeholderText={placeholder}
                customInput={<CustomInput />}
                onCalendarOpen={() => {
                    const bodymodal = document.querySelector(
                        '.modal-dialog-scrollable .modal-body'
                    );
                    // if open calender scroll top 100px
                    if (bodymodal) {
                        bodymodal.scrollTop = 130;
                    }
                }}
                onCalendarClose={() => {
                    const bodymodal = document.querySelector(
                        '.modal-dialog-scrollable .modal-body'
                    );
                    // if close calender scroll top 100px
                    if (bodymodal) {
                        bodymodal.scrollTop = 0;
                    }
                }}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    decreaseYear,
                    increaseMonth,
                    increaseYear,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    nextYearButtonDisabled,
                    prevYearButtonDisabled,
                }) => (
                    <div className='cal-header'>
                        <button
                            className='simple-btn'
                            onClick={decreaseYear}
                            disabled={prevYearButtonDisabled}
                        >
                            <img
                                src='/images/icons/cal-prev-arrow.svg'
                                alt=''
                            />
                        </button>
                        <div className='cal-dropdown'>
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                    changeYear(value)
                                }
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button
                            className='simple-btn'
                            onClick={increaseYear}
                            disabled={nextYearButtonDisabled}
                        >
                            <img
                                src='/images/icons/cal-next-arrow.svg'
                                alt=''
                            />
                        </button>
                        <button
                            className='simple-btn'
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                        >
                            <img
                                src='/images/icons/cal-prev-arrow.svg'
                                alt=''
                            />
                        </button>
                        <div className='cal-dropdown'>
                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {t(`availability.months.${option}`)}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button
                            className='simple-btn'
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                        >
                            <img
                                src='/images/icons/cal-next-arrow.svg'
                                alt=''
                            />
                        </button>
                    </div>
                )}
            />
            {errorMessage ? <FormErrorLabel message={errorMessage} /> : null}
        </div>
    );
}

DateSelector.propTypes = {
    label: PropTypes.string,
    startYear: PropTypes.number.isRequired,
    endYear: PropTypes.number.isRequired,
    handleDateChange: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    errorMessage: PropTypes.string,
    isEnabled: PropTypes.bool,
    dateFormat: PropTypes.string,
    includeDates: PropTypes.array,
};

DateSelector.defaultProps = {
    handleDateChange: () => {},
};

export default DateSelector;
