import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    cancelAppointment,
    createCall,
    viewScheduleCall,
} from '../../../api/adapters/appointment';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import {
    formatTalkedHours,
    getDateStringWithDay,
    getFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Categories from '../../components/common-component/Categories';
import Loader from '../../components/common-component/Loader';
import TitleHeader from '../../components/common-component/TitleHeader';
function ScheduleCall(props) {
    const [modalShow, setModalShow] = useState(false);
    const { t, i18n } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});

    const { interestHeading = false } = props;

    const { id } = useParams();

    const location = useLocation();
    const bViewFromHome = location?.state?.bViewFromHome || false;

    const [scheduleCallDetails, setScheduleCallDetails] = useState({
        interestMatched: [],
        city: '',
        country: '',
        scheduleDate: '',
        matchedSlots: '',
        name: '',
        bio: '',
        chatMessage: '',
        profilePic: '',
        appointmentId: '',
        conversationId: '',
    });

    const {
        data: viewschedulecall,
        refetch: refetchScheduledAppointments,
        isFetching,
    } = useQuery({
        queryKey: ['view-appointment-schedule-detail', id],
        queryFn: () =>
            viewScheduleCall(i18n.language.split('-')[0], id).then((res) => {
                setScheduleCallDetails({
                    interestMatched: res?.data?.aMatchedInterests,
                    city: res?.data?.sCity,
                    country: res?.data?.sCountry,
                    scheduleDate: moment(res?.data?.matchedSlots?.dStartTime)
                        .startOf('day')
                        .format(),
                    matchedSlots: res?.data?.matchedSlots,
                    name: res?.data?.oName,
                    bio: res?.data?.sBio,
                    chatMessage: res?.data?.sMessage,
                    profilePic: res?.data?.sProfilePicUrl,
                    appointmentId: res?.data?._id,
                    totalCallSeconds: res?.data?.nTotalCallSeconds,
                    conversationId: res?.data?.iConversationId,
                });

                return res.data;
            }),
        // enabled: false,
    });

    useEffect(() => {
        refetchScheduledAppointments();
    }, []);

    const { mutate: mutateCreateCall } = useMutation({
        mutationFn: (id) => createCall(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/call', { state: { oCallData: response.data } });
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const { mutate: mutateCancelAppointment } = useMutation({
        mutationFn: (id) => cancelAppointment(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            refetchScheduledAppointments();
            setModalShow(false);
            navigate(-1);
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const matchedSlot = scheduleCallDetails?.matchedSlots;

    const timeSlots = matchedSlot
        ? [
              {
                  value: matchedSlot?._id || '',
                  label:
                      `${moment(matchedSlot?.dStartTime).format('HH:mm')}` +
                      '-' +
                      `${moment(matchedSlot?.dEndTime).format('HH:mm')}`,
              },
          ]
        : [];

    const handleBackBtn = async () => {
        // navigate(`/appointments/${params.status}`);
        // navigate('/appointments/schedule');
        navigate(-1);
    };

    const handleClick = () => {
        mutateCancelAppointment({
            id: scheduleCallDetails.appointmentId || id,
        });
    };

    const handleReschedule = () => {
        navigate(`/appointments/${id}/reschedule`);
    };

    const handleRedirectChat = () => {
        navigate('/chat', {
            state: {
                conversationId: scheduleCallDetails?.conversationId,
            },
        });
    };

    const handleCreateCall = () => {
        mutateCreateCall(scheduleCallDetails?.appointmentId);
    };

    const currentTime = moment();
    const isDisabled =
        !scheduleCallDetails?.matchedSlots ||
        currentTime.isBefore(
            moment(scheduleCallDetails?.matchedSlots?.dStartTime).subtract(
                2,
                'minutes'
            )
        ) ||
        currentTime.isAfter(
            moment(scheduleCallDetails?.matchedSlots?.dEndTime)
        );

    return (
        <div className='d-flex h-100 flex-column'>
            <BreadcrumbAction
                lastPageLink={`/appointments/${params.status}`}
                lastPage={t('appointments.appointments')}
                currentPage={t('appointments.schedule')}
            />

            {isFetching ? (
                <div className='content-wrapper h-100'>
                    <Loader innerLoader={true} />
                </div>
            ) : (
                <div className='schedule-call content-wrapper'>
                    <>
                        <TitleHeader
                            isBackBtn={handleBackBtn}
                            title={t('appointments.schedule_call')}
                            rejectBtn={
                                !bViewFromHome
                                    ? t('appointments.cancel')
                                    : t('chat_btn')
                            }
                            btn_title={
                                !bViewFromHome
                                    ? t('appointments.reschedule')
                                    : t('call_btn')
                            }
                            bViewFromHome={bViewFromHome}
                            handleRedirectChat={handleRedirectChat}
                            handleRejectClick={() => {
                                setModalShow(true);
                            }}
                            handleCreateCall={handleCreateCall}
                            btnClick={handleReschedule}
                            callDisabled={
                                moment(
                                    scheduleCallDetails?.matchedSlots
                                        ?.dStartTime
                                )
                                    .subtract(2, 'minutes')
                                    .isAfter(moment()) ||
                                moment(
                                    scheduleCallDetails?.matchedSlots?.dEndTime
                                ).isBefore(moment())
                            }
                            disabled={moment(
                                scheduleCallDetails?.matchedSlots?.dStartTime
                            )
                                .subtract(1, 'hour')
                                .isBefore(moment())}
                            rescheduleBtnDisabled={moment(
                                scheduleCallDetails?.matchedSlots?.dStartTime
                            ).isBefore(moment())}
                        />

                        <div className='schedule-call-data'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='user-dp'>
                                        <img
                                            src={`${
                                                scheduleCallDetails?.profilePic ||
                                                '/images/profile-image-placeholder.svg'
                                            }`}
                                            alt=''
                                        />
                                    </div>
                                    <h2 className='name'>
                                        {getFullName(scheduleCallDetails?.name)}
                                    </h2>
                                    <ul className='location'>
                                        <li>
                                            <img
                                                src='/images/location-ic.svg'
                                                alt='location'
                                            />
                                            <span>
                                                {' '}
                                                {
                                                    scheduleCallDetails?.city
                                                },{' '}
                                                {scheduleCallDetails?.country}
                                            </span>
                                        </li>

                                        {scheduleCallDetails?.totalCallSeconds !==
                                            undefined &&
                                        scheduleCallDetails?.totalCallSeconds !==
                                            null ? (
                                            <li>
                                                <img
                                                    src='/images/tele-ic.svg'
                                                    alt='tele'
                                                />
                                                <span>
                                                    {t('talked_for')}{' '}
                                                    {formatTalkedHours(
                                                        i18n.language.split(
                                                            '-'
                                                        )[0],
                                                        scheduleCallDetails?.totalCallSeconds
                                                    )}
                                                </span>
                                            </li>
                                        ) : null}
                                    </ul>
                                    {scheduleCallDetails?.bio && (
                                        <div className='bio'>
                                            <p className='lg'>
                                                {t('appointments.bio')}
                                            </p>
                                            <p className='bio-content'>
                                                {scheduleCallDetails?.bio}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-8'>
                                    {interestHeading && (
                                        <p className='lg interest-heading'>
                                            Interests
                                        </p>
                                    )}
                                    {scheduleCallDetails?.interestMatched
                                        ?.length ? (
                                        <div className='matched'>
                                            <Categories
                                                // conversation={[...Array(4).keys()]}
                                                interestItems={scheduleCallDetails?.interestMatched?.reduce(
                                                    (acc, cur) => {
                                                        const categoryInterestItems =
                                                            cur.interestCategory.aInterestItems.map(
                                                                (item) => ({
                                                                    sTitle: item.sTitle,
                                                                    sIconUrl:
                                                                        item.sIconUrl,
                                                                })
                                                            );

                                                        acc.push(
                                                            ...categoryInterestItems
                                                        );
                                                        return acc;
                                                    },
                                                    []
                                                )}
                                                isSelectable={false}
                                                title={t(
                                                    'home.matched_interests'
                                                )}
                                            />
                                        </div>
                                    ) : null}
                                    <div className='grey-bg'>
                                        <div className='call-btn-wrapper'>
                                            <h3 className='pt-24'>
                                                {t(
                                                    'appointments.appointment_details'
                                                )}
                                            </h3>
                                            <button
                                                className={`simple-btn schedule-btn ${
                                                    isDisabled
                                                        ? 'deactivated'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleCreateCall()
                                                }
                                                disabled={isDisabled}
                                            >
                                                {isDisabled ? (
                                                    <img
                                                        src='/images/call.svg'
                                                        alt=''
                                                    />
                                                ) : (
                                                    <img
                                                        src='/images/schedule-btn.svg'
                                                        alt=''
                                                    />
                                                )}
                                            </button>
                                        </div>
                                        <div className='time-date'>
                                            <div className='date'>
                                                <img
                                                    src='/images/date-ic.svg'
                                                    alt=''
                                                />
                                                <div>
                                                    <p className='small'>
                                                        {t('appointments.date')}
                                                    </p>
                                                    <p>
                                                        {getDateStringWithDay(
                                                            scheduleCallDetails?.scheduleDate,
                                                            i18n.language.split(
                                                                '-'
                                                            )[0]
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='time'>
                                                <img
                                                    src='/images/time-ic.svg'
                                                    alt=''
                                                />
                                                <div>
                                                    <p className='small'>
                                                        {t('appointments.time')}
                                                    </p>
                                                    <p>
                                                        {' '}
                                                        {timeSlots?.map(
                                                            (slot, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {slot.label}
                                                                </div>
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {scheduleCallDetails?.chatMessage && (
                                            <div className='chat-msg'>
                                                <img
                                                    src='/images/chat-ic2.svg'
                                                    alt=''
                                                />
                                                <div className='chat'>
                                                    <span>
                                                        {t(
                                                            'appointments.chat_message'
                                                        )}
                                                    </span>
                                                    <p>
                                                        {
                                                            scheduleCallDetails?.chatMessage
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            )}
            <ModalPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {t('appointments.cancel_appointment')}
                        </h3>

                        <p className='sm'>{t('appointments.are_you')}</p>
                        <div className='error-msg edit-profile-error justify-content-start'>
                            <img
                                style={{
                                    maxWidth: '16px',
                                    marginTop: '4px',
                                    lineHeight: '20px',
                                }}
                                src='/images/icons/error-ic.svg'
                                alt='error-ic'
                            />
                            <p
                                className='error small'
                                style={{ fontSize: '14px' }}
                            >
                                {t('appointments.if_you')}
                            </p>
                        </div>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setModalShow(false)}
                            >
                                {t('appointments.no_dont')}
                            </button>
                            <button
                                className='simple-btn'
                                onClick={() => {
                                    handleClick();
                                }}
                            >
                                {t('appointments.yes_cancel')}
                            </button>
                        </div>
                    </>
                }
            />
        </div>
    );
}

export default ScheduleCall;
