import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRelativeDateString } from '../../../utils/helpers/format.helpers';
import SpeakNowCountdown from './SpeakNowCountdown';

const SpeakNowNotActive = (props) => {
    const { startTime, endTime, wheelerHandle, refetchDialogaeNowTime } = props;

    const { t, i18n } = useTranslation();
    // console.log(i18n);

    return (
        <div className='speak-content'>
            <h1>
                {t('dialog_now.you_are')}{' '}
                {i18n?.language !== 'es' && t('dialog_now.dialog_now')}
            </h1>
            <p className='lg pt-2'>
                {!startTime ? (
                    i18n?.language !== 'es' && t('dialog_now.coming_soon')
                ) : (
                    <>
                        <>{t('dialog_now.it_starts')} </>
                        <strong>
                            {getRelativeDateString(
                                startTime,
                                t,
                                i18n.language.split('-')[0]
                            )}
                            {' at  '}
                            {new Date(startTime).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                            {' - '}
                            {new Date(endTime).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                        </strong>

                        <div className='wheeler-action'>
                            <SpeakNowCountdown
                                wheelerHandle={wheelerHandle}
                                speakNowStartTime={startTime || ''}
                                refetchDialogaeNowTime={refetchDialogaeNowTime}
                            />
                        </div>
                    </>
                )}
            </p>
        </div>
    );
};

SpeakNowNotActive.propTypes = {
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
};

export default SpeakNowNotActive;
