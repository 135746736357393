import axios from 'axios';

import { clearCookies, getToken } from '../../utils/helpers/cookies.helpers';

const apiClient = async ({ ...options }) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const client = axios.create({ baseURL });

    let token = getToken();

    if (token) client.defaults.headers.common.Authorization = `Bearer ${token}`;

    try {
        const response = await client(options);

        if (!response.data) throw new Error('API Request Was Not Successful');

        return response.data;
    } catch (error) {
        if (error.response?.status === 401) clearCookies();

        const errorObj = {
            status: error.response?.status,
            message:
                error.response?.data?.message ||
                error.message?.message ||
                error,
        };

        throw errorObj;

        // throw new Error(JSON.stringify(errorObj));
    }
};

export default apiClient;
