import apiClient from '../client/api-client';

export const sendOtp = async (language, data) => {
    const url = 'user/auth/otp/send';

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const verifyOtp = async (language, data) => {
    const url = 'user/auth/otp/verify';

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const register = async (language, data) => {
    const url = 'user/auth/register';

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const signIn = async (language, data) => {
    const url = 'user/auth/login';

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const logout = async (language) => {
    const url = 'user/auth/logout';

    return apiClient({
        url,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const forgotPassword = async (language, data) => {
    const url = 'user/auth/password/reset';

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const verifyResetPasswordToken = async (language, token) => {
    const url = `user/auth/password/${token}/reset`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const resetPassword = async (language, data, token) => {
    const url = `user/auth/password/${token}/reset`;

    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { 'Accept-Language': language },
    });
};

export const deleteAccount = async (language) => {
    const url = 'user/auth/account/delete';

    return apiClient({
        url,
        method: 'DELETE',
        headers: { 'Accept-Language': language },
    });
};

export const updateLanguage = async (language) => {
    const url = 'user/home/language/update';

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
    });
};
