import { useWavesurfer } from '@wavesurfer/react';
import * as React from 'react';
const { useState, useCallback, useRef, useEffect } = React;
const formatTime = (seconds) =>
    [seconds / 60, seconds % 60]
        .map((v) => `0${Math.floor(v)}`.slice(-2))
        .join(':');

const VoiceNote = (props) => {
    const { voiceData } = props;
    const containerRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [audioUrl, setAudioUrl] = useState(null);

    useEffect(() => {
        if (voiceData instanceof Blob) {
            const url = URL.createObjectURL(voiceData);
            setAudioUrl(url);
        } else {
            setAudioUrl(voiceData);
        }

        return () => {
            if (voiceData instanceof Blob) {
                URL.revokeObjectURL(audioUrl);
            }
        };
    }, [voiceData]);

    const { wavesurfer, isPlaying } = useWavesurfer({
        container: containerRef,
        height: 50,
        width: 200,
        cursorWidth: 0,
        barWidth: 2,
        waveColor: '#fff',
        progressColor: '#036',
        url: audioUrl,
    });

    const onPlayPause = useCallback(() => {
        if (wavesurfer) wavesurfer && wavesurfer.playPause();
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.on('ready', () => {
                setDuration(wavesurfer.getDuration());
            });

            wavesurfer.on('audioprocess', () => {
                setCurrentTime(wavesurfer.getCurrentTime());
            });
        }
    }, [wavesurfer]);

    return (
        <>
            <div className='voice-note'>
                <div>
                    <button onClick={onPlayPause}>
                        {isPlaying ? (
                            <img src='/images/pause_ic.svg' alt='' />
                        ) : (
                            <img src='/images/play_ic.svg' alt='' />
                        )}
                    </button>
                </div>
                <div ref={containerRef} />
                <p>{formatTime(duration - currentTime)}</p>
            </div>
        </>
    );
};

export default VoiceNote;
