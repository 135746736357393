import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getScheduledList } from '../../../api/adapters/appointment';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import CallCard from '../../components/common-component/CallCard';
import NoAppointment from '../appointments/NoAppointment';

function SeniorHome() {
    const { t, i18n } = useTranslation();

    const user = useSelector((state) => state.user);
    const {
        data: scheduledListData,
        isLoading: isLoadingScheduledListData,
        error: scheduledListDataError,
    } = useQuery({
        queryKey: [
            'scheduled-list-data',
            getUserId(),
            i18n.language.split('-')[0],
        ],
        queryFn: () =>
            getScheduledList(
                i18n.language.split('-')[0],
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
        enabled: user?.accountType === 'R',
    });
    const formattedDate = moment()
        .locale(i18n.language.split('-')[0])
        .format('dddd, D MMM');

    return (
        <div className='senior-home content-wrapper border-wrapper h-100'>
            <h2 className='mb-3'>{formattedDate}</h2>
            {scheduledListData?.length ? (
                <div className='row call-cards-row'>
                    {scheduledListData?.map((item, i) => (
                        <div className='col-md-6 col-xl-4 '>
                            <CallCard
                                key={i}
                                oUser={item?.oUser}
                                slot={item?.Slot}
                                conversationId={item?.iConversationId}
                                callId={item?._id}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <NoAppointment desc={t('you_have')} />
                </>
            )}
        </div>
    );
}

export default SeniorHome;
