import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';

function RequestSent() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='account-setup'>
            <LeftTextAnimation GifShow={true} />
            <div className='right-screen'>
                <div className='request-content'>
                    <div>
                        <img src='/images/request-sent.svg' alt='' />
                        <h1>{t('req_pages.awaiting')}</h1>
                        <p className='lg'>{t('req_pages.req_was_sent')}</p>
                        <Link to={'/sign-in'} className='primary-btn'>
                            {t('field_name.sign_in')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequestSent;
