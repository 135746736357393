// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Prevent scroll restoration
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        // scroll top whenever route is changed
        window.scrollTo(0, 0);
    }, [pathname, navigate]); // include navigate in the dependency array

    return null;
}

export default ScrollToTop;
