import React, { useEffect, useState } from 'react';

function StepsIndicator(props) {
    const { step } = props;

    const [level, setLevel] = useState(1);

    useEffect(() => setLevel(step ? step : 1), [step]);

    return (
        <div className={`steps-indicators step-${level}`}>
            <div
                className={`lavel ${
                    level === 1 ? 'active' : level >= 2 && 'filled'
                }`}
            >
                {level === 1 && level}
            </div>
            <div className='steps-length'>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div
                className={`lavel ${
                    level === 2 ? 'active' : level >= 3 && 'filled'
                }`}
            >
                {level === 2 && level}
            </div>
            <div className='steps-length'>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div
                className={`lavel ${
                    level === 3 ? 'active' : level >= 4 && 'filled'
                }`}
            >
                {level === 3 && level}
            </div>
            <div className='steps-length'>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div
                className={`lavel ${
                    level === 4 ? 'active' : level >= 5 && 'filled'
                }`}
            >
                {level === 4 && level}
            </div>
            {/* <div className='steps-length'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={`lavel ${level === 5 && 'active'}`}>
                {level === 5 && level}
            </div> */}
        </div>
    );
}

export default StepsIndicator;
